import { tr } from 'pmt-modules/i18n'
import { notEmpty } from 'pmt-modules/form/validation'

import createForm from 'pmt-modules/form/createForm'

import FormType from 'pmt-modules/form/FormType'
import { ScheduledActionStatus, ScheduledActionFrequency } from '../constants'

// in DEV environment, prefill form with the following data
const devData = {}

const defaultData = {
  actionType: null,
  comment: null,
  frequencySettings: {
    frequency: ScheduledActionFrequency.DAILY,
    hour: 0,
    minute: 0,
  },
  props: {},
  restaurantsGroupId: null,
  status: ScheduledActionStatus.ENABLED,
  title: null,
}

const validationRules = () => ({
  title: [[notEmpty, tr('global.scheduled_action.form.title.required')]],
})

export default createForm(FormType.SCHEDULED_ACTION, validationRules, defaultData, devData)
