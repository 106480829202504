import { notEmpty, isValidEmail } from 'pmt-modules/form/validation'

import createForm from 'pmt-modules/form/createForm'

import FormType from 'pmt-modules/form/FormType'

export ForgotPasswordFormView from './ForgotPasswordFormView'

// in DEV environment, prefill form with the following data
const devData = {
  username: null,
}

const defaultData = {
  username: null,
}

// TODO: I18n
const validationRules = {
  username: [[notEmpty, 'global.forgot_password.email_required'], [isValidEmail, 'global.forgot_password.invalid_email']],
}

export default createForm(FormType.PRO_FORGOT_PASSWORD, validationRules, defaultData, devData)
