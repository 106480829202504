import map from 'lodash/map'

import { getOrderedArray } from 'pmt-utils/array'
import { rowKeys, rowKeysColumn, rowKeysLabel } from '../constants'

const formatImporterDataForTable = (importerData) => {
  // transform object to array of objects.
  const unsortedItems = map(importerData, (value, key) => ({
    key,
    value,
  }))

  // sort the array to be in the order of a row on the excel
  let items = getOrderedArray(rowKeys, unsortedItems, (key, object) => key === object.key)

  // now that it is sorted, we add the columnName (begin to 'A')
  items = items.map(item => (
    {
      columnName: rowKeysColumn[item.key] || null,
      columnLabel: rowKeysLabel[item.key] || null,
      ...item,
    }
  ))

  return items
}

const formatImporterData = (importerData) => (
  {
    origin: importerData,
    tableData: formatImporterDataForTable(importerData),
  }
)

export default formatImporterData
