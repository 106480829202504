import { createApiCallAction, createApiEnumAction } from '../api/utils'
import createAction from '../redux/createAction'
import ProRoleApi from '../api/calls/ProRoleApi'
import formatProRoleForApi from './formatProRoleForApi'

export const GetProRoleAction = createApiEnumAction('PRO_ROLE::GET')

export const fetchProRole = roleId =>
  createApiCallAction(GetProRoleAction, ProRoleApi.getProRole(roleId), {
    roleId,
  })

//
//
//

export const GetProRoleListAction = createApiEnumAction('PRO_ROLE_LIST::GET')

export const fetchProRoleList = (rGroupId, cursor = '', limit = 10) =>
  createApiCallAction(GetProRoleListAction, ProRoleApi.getProRoleList(rGroupId, cursor, limit), {
    rGroupId,
  })

//
//
//

export const PostProRoleAction = createApiEnumAction('PRO_ROLE::POST')

export const postProRole = proRole => {
  const proRoleForApi = formatProRoleForApi(proRole)

  return createApiCallAction(PostProRoleAction, ProRoleApi.postProRole(proRoleForApi), {
    proRole,
    proRoleForApi,
  })
}

//
//
//

export const PutProRoleAction = createApiEnumAction('PRO_ROLE::PUT')

export const putProRole = (roleId, proRole) => {
  const proRoleForApi = formatProRoleForApi(proRole)

  return createApiCallAction(PutProRoleAction, ProRoleApi.putProRole(proRole.id, proRoleForApi), {
    roleId,
    proRole,
    proRoleForApi,
  })
}

//
//
//

export const RESET_DATA_ACTION = 'PRO_ROLE::RESET_DATA_ACTION'

// reset Put / Post reducer data
export const resetProRoleData = () => createAction(RESET_DATA_ACTION)
