import { createSelector } from 'reselect'

import { formatRestaurantsGroupWithChildren } from '../restaurantsGroup'
import { formatDomain } from '../domain'
import { formatBoData } from './format'

const getData = state => state.entities.bo

export const getSelectedRestaurantsGroup = createSelector([getData], data => {
  const selectedRestaurantsGroupData = data.get('selectedRestaurantsGroup')
  if (!selectedRestaurantsGroupData) {
    return null
  }
  return formatRestaurantsGroupWithChildren(selectedRestaurantsGroupData.toJS())
})

export const getSelectedDomain = createSelector([getData], data => {
  const selectedDomainData = data.get('selectedDomain')
  if (!selectedDomainData) {
    return null
  }
  return formatDomain(selectedDomainData.toJS())
})

export const getBoData = createSelector([], () => {
  // empty for now since we do not have data on store.
  return formatBoData({})
})
