import { tr } from 'pmt-modules/i18n'
import { ScheduledActionFrequency } from './constants'

export const getFrequencyLabel = frequency => {
  switch (frequency) {
    case ScheduledActionFrequency.DAILY:
      return tr('global.scheduled_action.frequency.daily')
    default:
      return ''
  }
}
