import { notEmpty, isGreaterThan, isValidEmail } from 'pmt-modules/form/validation'

import createForm from 'pmt-modules/form/createForm'

import FormType from 'pmt-modules/form/FormType'

export LoginFormView from './LoginFormView'

// in DEV environment, prefill form with the following data
const devData = {
  username: '',
  password: '',
}

const defaultData = {
  username: null,
  password: null,
}

// TODO: I18n
const validationRules = {
  username: [[notEmpty, 'global.login.form.validation.email_mandatory'], [isValidEmail, 'global.form.invalid_mail']],
  password: [[isGreaterThan(5), 'global.login.form.validation.password_length']],
}

export default createForm(FormType.PRO_LOGIN, validationRules, defaultData, devData)
