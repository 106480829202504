import { createSelector } from 'reselect'
import { createSimpleSelector } from '../redux'

import { formatPart } from './format'

//
//
//

const updateUserSelectors = createSimpleSelector(
  state => state.entities.userUpdate.user
)

export const isFetchingPostUser = updateUserSelectors.isFetching
export const getDataPostUser = updateUserSelectors.getData
export const getErrorPostUser = updateUserSelectors.getError


//
//
//

const getData = state => state.entities.proUserUpdate

export const getUpdateUserFormParts = createSelector(
  [ getData ],
  (data) => formatPart(data.toJS().updateUserFormPart)
)
