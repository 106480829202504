export default {
  // for key we use the `ENV` from our config
  DEV: {
    waitForAppConfig: false,
    identifiers: [
      // UA (old)
      /*{
        trackingId: 'UA-105853425-4',
        gaOptions: {
          name: 'pmt_tracker_dev',
          cookieDomain: 'auto',
        },
      },
       */
      // GA4
      {
        trackingId: 'G-V2LC3FVRRR',
        gaOptions: {
          name: 'pmt_tracker_dev',
          cookieDomain: 'auto',
        },
      },
    ],
    /*
      options.nonce	string Optional Used for Content Security Policy (CSP) more
      options.testMode	boolean Default false
      options.gaOptions	object Optional Reference
      options.gtagOptions	object Optional
      options.legacyDimensionMetric	boolean Default true
      */
    options: {
      gtagOptions: { debug_mode: true },
    },
  },
  PREPROD: {
    waitForAppConfig: false,
    identifiers: [
      // UA (old)
      /*
      {
        trackingId: 'UA-105853425-5',
        gaOptions: {
          name: 'pmt_tracker_preprod',
          cookieDomain: 'auto',
        },
      },
         */
      // GA4
      {
        trackingId: 'G-KC9V0Q2QCS',
        gaOptions: {
          name: 'pmt_tracker_preprod',
          cookieDomain: 'auto',
        },
      },
    ],
    options: {},
  },
  PROD: {
    waitForAppConfig: false,
    identifiers: [
      // UA (old)
      /*{
        trackingId: 'UA-105853425-6',
        gaOptions: {
          name: 'pmt_tracker_prod',
          cookieDomain: 'auto',
        },
      },
       */
      // GA4
      {
        trackingId: 'G-EGH6G5LCC9',
        gaOptions: {
          name: 'pmt_tracker_prod',
          cookieDomain: 'auto',
        },
      },
    ],
    options: {},
  },
}
