import { createSimpleSelector } from 'pmt-modules/redux'
import { formatLogsPerRequestId } from './format'

const getLogsSelectors = createSimpleSelector(
  state => state.entities.audit.logs,
  formatLogsPerRequestId
)

export const isFetchingLogs = getLogsSelectors.isFetching
// export const getLogsData = () => formatLogsPerRequestId(exampleLogs)
export const getLogsData = getLogsSelectors.getData
export const getLogsError = getLogsSelectors.getError
