import clone from 'lodash/clone'
import forEach from 'lodash/forEach'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import keys from 'lodash/keys'
import moment from 'moment'

import { convertMillionPrice, formatMillionPriceWithCurrency } from 'pmt-utils/currency'
import { createFormatter } from 'pmt-utils/format'
import { nbWeekdaysBetween } from 'pmt-utils/date'

import { getSalesFilter, getSalesFilterKeys } from 'pmt-modules/statistics/constants'

const isFiltered = (statisticsOptions, { identificationMethod, source, type }) => {
  if (
    (isEmpty(statisticsOptions.identificationMethods) ||
      includes(statisticsOptions.identificationMethods, identificationMethod)) &&
    (isEmpty(statisticsOptions.sources) || includes(statisticsOptions.sources, source)) &&
    (isEmpty(statisticsOptions.types) || includes(statisticsOptions.types, type))
  ) {
    return true
  }

  return false
}

/**
 * Format statistics for every sums displayed (keyStats + statsTable)
 */
const formatTopUpStatisticsSums = (topUpStatistics, statisticsOptions) => {
  const defaultValues = {
    nbDeposits: 0,
    averageTTC: 0,
    turnoverTTC: 0,
  }

  topUpStatistics.sums = {
    types: {},
    typeTurnoverPercent: {},
    identificationMethods: {},
    identificationTurnoverPercent: {},
    sources: {},
    sourceTurnoverPercent: {},
    total: clone(defaultValues),
  }

  forEach(topUpStatistics.items, item => {
    // item.hour is a string formatted, on a UTC date.
    // We need to update it to be a current timezone date
    const format = 'DD/MM/YYYY HH:mm'
    item.hour = moment
      .utc(item.hour, format)
      .local()
      .format(format)

    // item has been filtered by view (or no filter at all)
    if (isFiltered(statisticsOptions, item)) {
      // init sums percent valuess (displayed for doughnuts)
      if (isEmpty(topUpStatistics.sums.typeTurnoverPercent[item.type])) {
        topUpStatistics.sums.typeTurnoverPercent[item.type] = 0
      }
      if (isEmpty(topUpStatistics.sums.identificationTurnoverPercent[item.identificationMethod])) {
        topUpStatistics.sums.identificationTurnoverPercent[item.identificationMethod] = 0
      }
      if (isEmpty(topUpStatistics.sums.sourceTurnoverPercent[item.identificationMethod])) {
        topUpStatistics.sums.sourceTurnoverPercent[item.identificationMethod] = 0
      }

      // init sums values (displayed in tables)
      if (isEmpty(topUpStatistics.sums.types[item.type])) {
        topUpStatistics.sums.types[item.type] = clone(defaultValues)
      }
      if (isEmpty(topUpStatistics.sums.sources[item.source])) {
        topUpStatistics.sums.sources[item.source] = clone(defaultValues)
      }
      if (isEmpty(topUpStatistics.sums.identificationMethods[item.identificationMethod])) {
        topUpStatistics.sums.identificationMethods[item.identificationMethod] = clone(defaultValues)
      }

      // fill for different types of data
      topUpStatistics.sums.types[item.type].nbDeposits += item.nbDeposits
      topUpStatistics.sums.types[item.type].turnoverTTC += item.turnoverTTC
      topUpStatistics.sums.types[item.type].averageTTC =
        topUpStatistics.sums.types[item.type].turnoverTTC /
        topUpStatistics.sums.types[item.type].nbDeposits

      topUpStatistics.sums.sources[item.source].nbDeposits += item.nbDeposits
      topUpStatistics.sums.sources[item.source].turnoverTTC += item.turnoverTTC
      topUpStatistics.sums.sources[item.source].averageTTC =
        topUpStatistics.sums.sources[item.source].turnoverTTC /
        topUpStatistics.sums.sources[item.source].nbDeposits

      topUpStatistics.sums.identificationMethods[item.identificationMethod].nbDeposits +=
        item.nbDeposits
      topUpStatistics.sums.identificationMethods[item.identificationMethod].turnoverTTC +=
        item.turnoverTTC
      topUpStatistics.sums.identificationMethods[item.identificationMethod].averageTTC =
        topUpStatistics.sums.identificationMethods[item.identificationMethod].turnoverTTC /
        topUpStatistics.sums.identificationMethods[item.identificationMethod].nbDeposits

      // fill for total sums for every types of data
      topUpStatistics.sums.total.nbDeposits += item.nbDeposits
      topUpStatistics.sums.total.turnoverTTC += item.turnoverTTC
      topUpStatistics.sums.total.averageTTC =
        topUpStatistics.sums.total.turnoverTTC / topUpStatistics.sums.total.nbDeposits
    }
  })

  // calculations have been made, we now format the data
  keys(topUpStatistics.sums.types).forEach(type => {
    topUpStatistics.sums.typeTurnoverPercent[type] = parseFloat(
      (topUpStatistics.sums.types[type].turnoverTTC * 100) / topUpStatistics.sums.total.turnoverTTC
    ).toFixed(2)

    topUpStatistics.sums.types[type].turnoverTTC = formatMillionPriceWithCurrency(
      topUpStatistics.sums.types[type].turnoverTTC
    )
    topUpStatistics.sums.types[type].averageTTC = formatMillionPriceWithCurrency(
      topUpStatistics.sums.types[type].averageTTC
    )
  })

  keys(topUpStatistics.sums.sources).forEach(source => {
    topUpStatistics.sums.sourceTurnoverPercent[source] = parseFloat(
      (topUpStatistics.sums.sources[source].turnoverTTC * 100) /
        topUpStatistics.sums.total.turnoverTTC
    ).toFixed(2)

    topUpStatistics.sums.sources[source].turnoverTTC = formatMillionPriceWithCurrency(
      topUpStatistics.sums.sources[source].turnoverTTC
    )
    topUpStatistics.sums.sources[source].averageTTC = formatMillionPriceWithCurrency(
      topUpStatistics.sums.sources[source].averageTTC
    )
  })

  keys(topUpStatistics.sums.identificationMethods).forEach(identification => {
    topUpStatistics.sums.identificationTurnoverPercent[identification] = parseFloat(
      (topUpStatistics.sums.identificationMethods[identification].turnoverTTC * 100) /
        topUpStatistics.sums.total.turnoverTTC
    ).toFixed(2)

    topUpStatistics.sums.identificationMethods[
      identification
    ].turnoverTTC = formatMillionPriceWithCurrency(
      topUpStatistics.sums.identificationMethods[identification].turnoverTTC
    )
    topUpStatistics.sums.identificationMethods[
      identification
    ].averageTTC = formatMillionPriceWithCurrency(
      topUpStatistics.sums.identificationMethods[identification].averageTTC
    )
  })

  // finally, format total sums
  topUpStatistics.sums.total.turnoverTTC = formatMillionPriceWithCurrency(
    topUpStatistics.sums.total.turnoverTTC
  )
  topUpStatistics.sums.total.averageTTC = formatMillionPriceWithCurrency(
    topUpStatistics.sums.total.averageTTC
  )

  return topUpStatistics
}

/**
 * Format statistics specifically for GraphLine component
 */
const formatTopUpStatisticsSalesGraph = (topUpStatistics, statisticsOptions) => {
  const { graphLineFilter, fromTime, toTime } = statisticsOptions

  const format = getSalesFilter()[graphLineFilter].filter

  let graphDataByFilterView = {}
  forEach(
    topUpStatistics.items,
    ({ type, identificationMethod, source, hour, turnoverTTC, nbDeposits }) => {
      // item has been filtered by view (or no filter at all)
      if (isFiltered(statisticsOptions, { identificationMethod, source, type })) {
        let period = moment(hour).format(format)
        if (graphLineFilter === getSalesFilterKeys().WEEK) {
          period = moment(moment(hour).format('YYYY'))
            .add(moment(hour).isoWeek() - 1, 'weeks')
            .add(1, 'day')
            .format('DD/MM/YYYY')
        }

        // init data
        if (isEmpty(graphDataByFilterView[period])) {
          graphDataByFilterView[period] = {
            averageTTC: 0,
            turnoverTTC: 0,
            nbDeposits: 0,
          }
        }

        graphDataByFilterView[period].turnoverTTC += turnoverTTC
        graphDataByFilterView[period].nbDeposits += nbDeposits
        graphDataByFilterView[period].averageTTC =
          graphDataByFilterView[period].turnoverTTC / graphDataByFilterView[period].nbDeposits
      }
    }
  )

  const graphData = keys(graphDataByFilterView).map(moment => {
    return {
      moment,
      turnoverTTC: graphDataByFilterView[moment].turnoverTTC,
      averageTTC: graphDataByFilterView[moment].averageTTC,
    }
  })

  topUpStatistics.salesGraphData = {
    labels: [],
    turnover: [],
    average: [],
  }

  let m = moment(fromTime)
  for (let i = 0; i < graphData.length; i++) {
    // fill graph with empty data for date from `fromTime` to next date received
    while (!m.isSame(graphData[i].moment, graphLineFilter)) {
      topUpStatistics.salesGraphData.labels.push(m.format(format))
      topUpStatistics.salesGraphData.turnover.push(0)
      topUpStatistics.salesGraphData.average.push(0)
      m = m.add(1, graphLineFilter)
    }

    // fill graph with data received
    topUpStatistics.salesGraphData.labels.push(graphData[i].moment)
    topUpStatistics.salesGraphData.turnover.push(convertMillionPrice(graphData[i].turnoverTTC))
    topUpStatistics.salesGraphData.average.push(convertMillionPrice(graphData[i].averageTTC))

    m = m.add(1, graphLineFilter)
  }

  // fill graph with empty data for every date until `toTime` is reached
  while (m.diff(toTime) < 0) {
    topUpStatistics.salesGraphData.labels.push(m.format(format))
    topUpStatistics.salesGraphData.turnover.push(0)
    topUpStatistics.salesGraphData.average.push(0)

    m = m.add(1, graphLineFilter)
  }

  return topUpStatistics
}

/**
 * Format statistics specifically for WeekdayGraph component
 */
const formatTopUpStatisticsGraphByWeekday = (topUpStatistics, statisticsOptions) => {
  const { fromTime, toTime } = statisticsOptions
  const { items } = topUpStatistics

  let dataByWeekday = {
    '1': {},
    '2': {},
    '3': {},
    '4': {},
    '5': {},
    '6': {},
    '7': {},
    average: {},
  }

  const formatForWeekday = m => weekday => {
    dataByWeekday[weekday][m.format('1970-01-01 HH:mm')] = 0
  }

  let hourDate = moment(0)
  for (let hourIndex = 0; hourIndex < 24; hourIndex++) {
    keys(dataByWeekday).forEach(formatForWeekday(hourDate))

    hourDate = hourDate.add(1, 'hour')
  }

  forEach(items, ({ type, identificationMethod, source, hour, turnoverTTC }) => {
    // item has been filtered by view (or no filter at all)
    if (isFiltered(statisticsOptions, { identificationMethod, source, type })) {
      // fill turnover by period by weekday
      const period = moment(hour).format('1970-01-01 HH:mm')
      const weekday = moment(hour).isoWeekday()
      dataByWeekday[weekday][period] += turnoverTTC
    }
  })

  // calculate number of each day on timerange
  let nbDays = []
  for (let i = 1; i <= 7; i++) {
    nbDays.push(nbWeekdaysBetween(fromTime, toTime, i))
  }

  // calculate number of total days
  const nbTotalDays = nbDays.reduce((a, b) => a + b)

  topUpStatistics.graphDataByWeekday = keys(dataByWeekday).map(weekday => {
    let data = {}

    forEach(dataByWeekday[weekday], (value, moment) => {
      switch (weekday) {
        // format average data for every days at this moment
        case 'average':
          let sumOfDays = 0
          for (let i = 1; i <= 7; i++) {
            sumOfDays += dataByWeekday[i][moment]
          }
          data[moment] = convertMillionPrice(sumOfDays / nbTotalDays)
          break

        // otherwise, format data for a specific day at this moment
        default:
          data[moment] = parseFloat(
            nbDays[weekday - 1] > 0
              ? convertMillionPrice(dataByWeekday[weekday][moment] / nbDays[weekday - 1])
              : 0
          ).toFixed(2)
      }
    })

    return data
  })

  return topUpStatistics
}

const formatToStats = items => {
  const stats = {
    items,
  }

  return stats
}

export const formatTopUpStatistics = createFormatter(
  formatToStats,
  formatTopUpStatisticsSums,
  formatTopUpStatisticsSalesGraph,
  formatTopUpStatisticsGraphByWeekday
)
