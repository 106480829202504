import reduce from 'lodash/reduce'
import { createFormatter } from 'pmt-utils/format'
import { formatDate } from 'pmt-utils/date'

import { formatEventsList } from '../format'
import { getEvent } from '../utils'
import { Event } from '../constants'
import { removeArrayDuplicates } from 'pmt-utils/array'

const transformToObjectSession = events => {
  const serviceResponseEvent = getEvent(events, Event.SERVICE_RESPONSE)
  if (serviceResponseEvent) {
    return {
      id: serviceResponseEvent.context.sessionId,
      response: serviceResponseEvent,
      events: events,
      hasResponse: true,
    }
  }

  return {
    id: null,
    response: null,
    events: events,
    hasResponse: false,
  }
}

const formatLatency = session => {
  if (!session.hasResponse) {
    return session
  }
  // latency is calculated with our two events and will therefore differ from the app engine latency
  session.latency = session.response.date - session.response.payload.requestBeginDate

  // TODO: better format
  session.latencyFormatted = `${formatDate(session.latency, 'SSS')}ms`
  return session
}

/**
 * Add the entitiesCreated array that contains all the entitiesCreated from all our events
 */
const formatAllEntitiesCreated = session => {
  session.entitiesCreated = reduce(
    session.events,
    (arr, event) => arr.concat(event.context.entitiesCreated || []),
    []
  )

  // remove dupliates
  session.entitiesCreated = removeArrayDuplicates(session.entitiesCreated, entity => {
    // unique id per entity type.
    return `${entity.className}_${entity.entityId}`
  })
  return session
}

export const formatSession = createFormatter(
  formatEventsList,
  transformToObjectSession,
  formatAllEntitiesCreated,
  formatLatency
)
