const MediaErrors = () => ({
  GET: {
    default: 'global.media.errors.get.default',
  },
  LIST: {
    default: 'global.media.errors.list.default',
  },
  POST: {
    default: 'global.media.errors.post.default',
  },
})

export default MediaErrors
