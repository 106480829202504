import { tr } from 'pmt-modules/i18n'
import invariant from 'invariant'
import keys from 'lodash/keys'
import PaymentMethod from './PaymentMethod'

export const getPaymentMethodLabel = paymentMethod => {
  if (!paymentMethod) {
    return null
  }
  switch (paymentMethod) {
    case PaymentMethod.PAYMENT_CREDIT_CARD:
      return tr('global.payment_method.PAYMENT_CREDIT_CARD')
    case PaymentMethod.IRL_CASH:
      return tr('global.payment_method.IRL_CASH')
    case PaymentMethod.IRL_CHEQUE:
      return tr('global.payment_method.IRL_CHEQUE')
    case PaymentMethod.IRL_COUPON:
      return tr('global.payment_method.IRL_COUPON')
    case PaymentMethod.IRL_CREDIT_CARD:
      return tr('global.payment_method.IRL_CREDIT_CARD')
    case PaymentMethod.IRL_LUNCHEON_VOUCHER:
      return tr('global.payment_method.IRL_LUNCHEON_VOUCHER')
    case PaymentMethod.IRL_OTHER:
      return tr('global.payment_method.IRL_OTHER')
    case PaymentMethod.IRL_NOT_SPECIFIED:
      return tr('global.payment_method.IRL_NOT_SPECIFIED')
    case PaymentMethod.USER_ACCOUNT:
      return tr('global.payment_method.USER_ACCOUNT')
    case PaymentMethod.PAYMENT_TRD:
      return tr('global.payment_method.PAYMENT_TRD')
    default:
      invariant(false, `Invalid paymentMethod ${paymentMethod}`)
  }
}

export const getPaymentMethodLabelAsArray = () =>
  keys(PaymentMethod).map(value => ({
    label: getPaymentMethodLabel(value),
    value,
  }))
