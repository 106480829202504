import { tr } from 'pmt-modules/i18n'
import keys from 'lodash/keys'

export const ExportType = {
  ORDER: 'ORDER',
  USER: 'USER',
  PAYMENT: 'PAYMENT',
}

export const getExportTypeLabel = exportType => {
  const labels = {
    [ExportType.ORDER]: tr('global.export.group.type.order'),
    [ExportType.USER]: tr('global.export.group.type.user'),
    [ExportType.PAYMENT]: tr('global.export.group.type.payment'),
  }

  return labels[exportType]
}

export const getExportTypesOptions = () =>
  keys(ExportType).map(value => ({
    label: getExportTypeLabel(value),
    value,
  }))
