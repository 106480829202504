import createForm from 'pmt-modules/form/createForm'
import FormType from 'pmt-modules/form/FormType'

const defaultData = {
  channelExternalId: 'default',
}

const validationRules = () => ({})

export default createForm(FormType.API_CONSUMER__CATALOG, validationRules, defaultData)
