import isEmpty from 'lodash/isEmpty'
import { createMiddleware } from '../redux'
import { getRoute, replaceWith, reloadTo } from '../routing'
import Logger from 'pmt-utils/logger'

import { saveBasicToken, removeBasicToken } from './utils'

import { AuthProAction, logoutPro, LOGOUT_PRO } from './actions'

import { INVALID_AUTHORIZATION_API_ERROR_CODE } from './constants'

/**
 * Intercepts LOGIN action and redirects to dashboard screen if the login succeeded
 * Otherwise just sends action to next middleware
 *
 * @returns {Function}
 */
const authProMiddleware = createMiddleware(AuthProAction.SUCCESS, ({ dispatch, next, action }) => {
  Logger.setProContext(Logger.ProContext.PRO, action.data)

  saveBasicToken(action.data.authorization)

  next(action) // send it to next so identity will be set

  const props = action.data.props || {}
  let { redirectTo } = props

  if (!isEmpty(redirectTo)) {
    return next(replaceWith(redirectTo))
  } else {
    return next(replaceWith(getRoute('BO_SELECT_PRO_DOMAINS')))
  }
})

const authLogoutMiddleware = createMiddleware(LOGOUT_PRO, ({ dispatch }) => {
  removeBasicToken()
  dispatch(reloadTo(getRoute('LOGIN')))
})

const isUnauthorized = action =>
  action.error && action.error.code === INVALID_AUTHORIZATION_API_ERROR_CODE

const unauthorizedMiddleware = createMiddleware(isUnauthorized, ({ dispatch, action }) => {
  // we don't want to logout when the pro is on the login page and used bad credentials.
  if (action.type !== AuthProAction.FAILURE) {
    if (action.response?.status === 403) {
      dispatch(logoutPro())
    }
  }
})

export const authProMiddlewares = [authProMiddleware, authLogoutMiddleware, unauthorizedMiddleware]
