import ApiEndpoints from '../config/ApiEndpoints'

const DepositApi = {
  getDetail: depositId => ({
    endpoint: ApiEndpoints.DEPOSIT,
    type: 'GET',
    query: {
      loadPayment: true,
    },
    params: {
      depositId,
    },
  }),

  // getRestaurantHistory: (restaurantId, fromDate, toDate) => ({
  //   endpoint: ApiEndpoints.DEPOSITS_HISTORY,
  //   type: 'GET',
  //   query: {
  //     restaurantId,
  //     fromTime: fromDate,
  //     toTime: toDate,
  //     v2: true,
  //     filterBy: 'dueDate',
  //   },
  // }),

  putDeposit: deposit => ({
    endpoint: ApiEndpoints.DEPOSIT,
    headers: {
      'restaurant-id': deposit.restaurantId,
    },
    type: 'PUT',
    params: {
      depositId: deposit.id,
    },
    body: deposit,
  }),
}

export default DepositApi
