import { createApiCallAction, createApiEnumAction } from 'pmt-modules/api/utils'
import DepositApi from 'pmt-modules/api/calls/DepositApi'

export const GetDepositDetailAction = createApiEnumAction('DEPOSIT::GET')

export const fetchDeposit = depositId =>
  createApiCallAction(GetDepositDetailAction, DepositApi.getDetail(depositId), {
    depositId,
  })

export const PutDepositDetailAction = createApiEnumAction('DEPOSIT::PUT')

export const putDeposit = deposit =>
  createApiCallAction(PutDepositDetailAction, DepositApi.putDeposit(deposit), {
    deposit,
  })
