import React from 'react'

import { withStyles } from 'pmt-ui/styles'
import { Link } from 'pmt-modules/routing'
import Typography from 'pmt-ui/Typography'
import NavigationChevronRight from 'pmt-ui/svg-icons/navigation/chevron-right'

import LinearProgress from '@material-ui/core/LinearProgress'

const styles = theme => ({
  root: {
    paddingRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  chevron: {
    fontSize: 22,
    paddingLeft: theme.spacing(1),
  },
})

const ArianeContent = ({ path }) => {
  if (path.title) {
    return <span>{path.title}</span>
  }

  // we don't have a title to display, we are allegedly fetching data (or missed something)
  return <LinearProgress style={{ width: 30 }} />
}

const ArianePath = ({ path, isLast, config = null, classes }) => (
  <Typography
    variant="body2"
    component="div"
    // colorSecondary
    className={classes.root}
  >
    {path.definition == null ? (
      <ArianeContent path={path} />
    ) : (
      <Link
        to={path.definition}
        params={config !== null ? config.params : null}
        query={config !== null ? config.query : null}
      >
        <ArianeContent path={path} />
      </Link>
    )}

    {!isLast && <NavigationChevronRight className={classes.chevron} />}
  </Typography>
)

export default withStyles(styles)(ArianePath)
