import { tr } from 'pmt-modules/i18n'
import { notEmpty } from 'pmt-modules/form/validation'
import createForm from 'pmt-modules/form/createForm'
import FormType from 'pmt-modules/form/FormType'

export EditGeneralInformationsForm from './EditGeneralInformationsForm'

const defaultData = {
  name: null,
  theme: {
    mainBackgroundColor: null,
  },
  settings: {
    defaultLocale: null,
  }
}

/**
 * Validation rules for register form
 * options parameters should contains fields retrieved from api consumer settings
 * it is structured as is : {
 *   type: FIELD_TYPE,
 *   required: boolean
 * }
 * if field is required, we automatically add a notEmpty rule
 */
const validationRules = () => ({
  name: [[notEmpty, tr('global.groups.form.validation.name_invalid')]],
})

export default createForm(FormType.GROUP_EDIT_GENERAL_INFOS, validationRules, defaultData)
