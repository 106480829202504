//
// The permissions data on the front differ from the API data.
// This transform the front data to the Api data.
// !!! Do not use this class directly, use `fromatProRoleForApi`
//

const formatPermission = permission => {
  const apiPermissions = {}

  permission.permissions.forEach(subPermission => {
    // final permission for boolean variable
    if (subPermission.final) {
      apiPermissions[subPermission.name] = subPermission.hasPermission
    } else {
      // no permission, or the creator / editor has not the permission
      // verify false, not undefined because 'creatorHasPermission' can be undefined in certain cases
      if (!subPermission.hasPermission || subPermission.creatorHasPermission === false) {
        apiPermissions[subPermission.name] = null
      } else {
        apiPermissions[subPermission.name] = formatPermission(subPermission)
      }
    }
  })

  return apiPermissions
}

const transformForApi = permissions => {
  const apiPermissions = {}

  permissions.sections.forEach(section => {
    if (!section.hasPermission) {
      apiPermissions[section.name] = null
    } else {
      apiPermissions[section.name] = formatPermission(section)
    }
  })

  return apiPermissions
}

export default transformForApi
