import isNil from 'lodash/isNil'
import isUndefined from 'lodash/isUndefined'
//import moment from 'moment'

/**
 * getAvailableEntity                 returns the needed entity by checking the list (if already loaded) of it
 *                                    the data returned is less than 5 minutes ago (default)
 * @param  {ImmutableJS} entity       entity stored in entity state
 * @param  {ImmutableJS} entityList   list of entities  stored in entity state
 * @param  {string} lastUpdatedIndex  index of last time the list has been updated (e.g. : lastUpdated)
 * @param  {string} entityId          id of the entity required
 * @param  {number} lastUpdateLimit   lapsing interval of data
 */

const getAvailableEntity = (entity, entityList, lastUpdatedIndex, entityId, lastUpdateLimit = 300000) => {
  const entityData = entity.get('data')
  const entityListData = entityList.get('data')
  //const lastUpdateDataDiff = (!isUndefined(entity.get(lastUpdatedIndex))) ? moment().valueOf() - entity.get(lastUpdatedIndex) : lastUpdateLimit + 1
  //const lastUpdateListDiff = (!isUndefined(entityList.get(lastUpdatedIndex))) ? moment().valueOf() - entityList.get(lastUpdatedIndex) : lastUpdateLimit + 1

  if (!isNil(entityData) && !isNil(entityId) && String(entityData.get('id')) === String(entityId)) {// && lastUpdateLimit > lastUpdateDataDiff) {
      return entityData.toJS()
  } else if (!isNil(entityListData)) {// && lastUpdateLimit > lastUpdateListDiff) {
    const entityFound = entityListData.find((entity) => { return entity.get('id') === entityId })
    if (!isUndefined(entityFound)) {
      return entityFound.toJS()
    }
  }

  return null
}

export default getAvailableEntity
