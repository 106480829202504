import RolesApi from 'pmt-modules/api/calls/RolesApi'
import { createApiCallAction, createApiEnumAction } from 'pmt-modules/api/utils'

export const LoadRestaurantsGroupRolesAction = createApiEnumAction('RESTAURANTS_GROUP::ROLES::GET')

export const loadRestaurantsGroupRoles = (restaurantsGroupId, cursor, limit = 50) =>
  createApiCallAction(
    LoadRestaurantsGroupRolesAction,
    RolesApi.getForRestaurantsGroup(restaurantsGroupId, cursor, limit),
    {
      restaurantsGroupId,
      cursor,
      limit,
    }
  )
