import {
  StatisticsGroupType,
  createGroupStatistics,
  createStatistics,
} from 'pmt-modules/statistics'

import StatisticsApi from 'pmt-modules/api/calls/StatisticsApi'

import { formatTopUpStatistics } from '../format/topUpStatistics'
import { TopUpStatisticsType } from './constants'

const generalStats = createStatistics(
  TopUpStatisticsType.GENERAL,
  StatisticsApi.getTopUpStats,
  formatTopUpStatistics
)

export default createGroupStatistics(StatisticsGroupType.TOP_UP, [generalStats])
