import { tr } from 'pmt-modules/i18n'
import React from 'react'
import isNull from 'lodash/isNull'

import { Link } from 'pmt-modules/routing'
import Menu, { MenuItem } from 'pmt-ui/Menu'
import Button from 'pmt-ui/Button'
import Typography from 'pmt-ui/Typography'
import NavigationMoreVert from 'pmt-ui/svg-icons/navigation/more-vert'

class ProMe extends React.Component {
  state = {
    proMenuAnchorEl: null,
  }

  handleOpenProMenu = event => {
    this.setState({
      proMenuAnchorEl: event.currentTarget,
    })
  }

  handleCloseMenu = () => {
    this.setState({
      proMenuAnchorEl: null,
    })
  }

  render() {
    const { display, proMe, classes } = this.props

    const { proMenuAnchorEl } = this.state

    if (isNull(proMe) || !display) {
      return <div />
    }

    return (
      <div>
        <Button
          className={classes.menuItem}
          aria-owns={proMenuAnchorEl ? 'pro-menu' : null}
          aria-haspopup="true"
          onClick={this.handleOpenProMenu}
        >
          {proMe.fullName}
          &nbsp;
          <NavigationMoreVert color="inherit" />
        </Button>

        <Menu
          id="pro-menu"
          className={classes.menu}
          anchorEl={proMenuAnchorEl}
          open={Boolean(proMenuAnchorEl)}
          onClose={this.handleCloseMenu}
        >
          <MenuItem>
            <Link to={Link.getRoute('BO_SETTINGS_PAGE')}>
              <Typography variant="body2" color="inherit" component="span">
                {tr('header.pro_me.settings')}
              </Typography>
            </Link>
          </MenuItem>
        </Menu>
      </div>
    )
  }
}

export default ProMe
