import createForm from 'pmt-modules/form/createForm'
import FormType from 'pmt-modules/form/FormType'

const defaultData = {
  mainFontColor: null,
  mainBackgroundColor: null,
  //
  // "customCss": {
  //   "errorLayout": {
  //     "width": "100%",
  //     "position": "relative"
  //   }
  // }
  //
  customCss: null,
  //
  // "logo": {
  //   "path": "https://storage.googleapis.com/..."
  // }
  //
  logo: null,
}

const validationRules = () => ({})

export default createForm(FormType.API_CONSUMER__THEME, validationRules, defaultData)
