import createForm from 'pmt-modules/form/createForm'
import FormType from 'pmt-modules/form/FormType'
import { tr } from 'pmt-modules/i18n'

const defaultData = {
  modes: [],
  onSite: {
    canOrderUsingSlots: false,
    canOrderUsingAsap: true,
  },
  takeAway: {
    canOrderUsingSlots: true,
    canOrderUsingAsap: true,
  },
  delivery: {
    canOrderUsingSlots: false,
    canOrderUsingAsap: true,
  },
}

const validationRules = () => ({
  onSite: [
    [
      obj => ((obj?.canOrderUsingSlots ?? false) || (obj?.canOrderUsingAsap ?? true)),
      tr('global.form.required'),
    ],
  ],
  takeAway: [
    [
      obj => ((obj?.canOrderUsingSlots ?? true) || (obj?.canOrderUsingAsap ?? true)),
      tr('global.form.required'),
    ],
  ],
  delivery: [
    [
      obj => ((obj?.canOrderUsingSlots ?? false) || (obj?.canOrderUsingAsap ?? true)),
      tr('global.form.required'),
    ],
  ],
})

export default createForm(FormType.API_CONSUMER__ORDER, validationRules, defaultData)
