import createForm from 'pmt-modules/form/createForm'
import FormType from 'pmt-modules/form/FormType'

const frontSettingsDefaultData = {
  catering: {
    historyType: null,
  },
}

const validationRules = () => ({})

export default createForm(
  FormType.GROUP_EDIT_FRONT_SETTINGS,
  validationRules,
  frontSettingsDefaultData
)
