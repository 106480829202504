/**
 *
 */
export const PaymentMethod = {
  PAYMENT_CREDIT_CARD: 'PAYMENT_CREDIT_CARD',
  IRL_CASH: 'IRL_CASH',
  IRL_CHEQUE: 'IRL_CHEQUE',
  IRL_COUPON: 'IRL_COUPON',
  IRL_CREDIT_CARD: 'IRL_CREDIT_CARD',
  IRL_LUNCHEON_VOUCHER: 'IRL_LUNCHEON_VOUCHER',
  PAYMENT_TRD: 'PAYMENT_TRD',
  IRL_OTHER: 'IRL_OTHER',
  USER_ACCOUNT: 'USER_ACCOUNT',
  IRL_NOT_SPECIFIED: 'IRL_NOT_SPECIFIED',

  // legacy
  // IRL_PAYMENT: 'IRL_PAYMENT',
  // PAYMENT: 'PAYMENT',
  // NOT_PRE_PAYED: 'NOT_PRE_PAYED',
}

export default PaymentMethod
