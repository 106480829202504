import ProDomainApi from '../api/calls/ProDomainApi'

import { createApiCallAction, createApiEnumAction } from '../api/utils'

import { createAction } from '../redux'

//
// Fetch the domains of the current pro
//

export const FetchMeProDomains = createApiEnumAction('PRO_ME::DOMAINS::GET')

/**
 *
 * @param  {[type]} props can contains:
 *                        - useAsSelectedDomain: if true, we will use the default one as selected
 *                        domain
 *                        - useDefaultRGroup: if true, we will use the default restaurants group
 *                        of the default domain
 *                        - rGoupIdToUse
 * TODO: rename to FetchMeDomainsList
 */
export const fetchMeProDomainsList = (props = {}) =>
  createApiCallAction(FetchMeProDomains, ProDomainApi.getMeDomainsList(), {
    useAsSelectedDomain: props.useAsSelectedDomain || false,
    useDefaultRGroup: props.useDefaultRGroup || false,
    authFlow: props.authFlow || false,
  })

//
//
//

export const FetchMeDomain = createApiEnumAction('PRO_ME::DOMAIN::GET')

/**
 *
 * @param  {number} domainId  id of the pro's domain to retrieve
 * @param  {any} props     can contains:
 *                            - useAsSelectedDomain
 *                            - useDefaultRGroup
 *                            - rGoupIdToUse
 *                            - sessionRGroupId, can be different than rGroupIdToUse when rGoupIdToUse
 *                              has been chosen from the url.
 *                            - sessionDomainId, can be different than domainId when domainId
 *                              has been chosen from the url.
 *                            - fallbackToSession: if true and the request failed, we try to load
 *                              the domain saved in the session
 *                            - fallbackToDefault: if true and the request failed, we try to load
 *                              the default domain instead of redirecting to the domain page.
 *                              (see fetchMeDomainFailure). We try fallbackToSession first.
 *                            - loadArchived: if true make request call to also load archived
 *                              restaurantsGroups (loadRestaurantsGroupArchived query param)
 */
export const fetchMeDomain = (domainId, props = {}) =>
  createApiCallAction(FetchMeDomain, ProDomainApi.getMeDomain(domainId, props.loadArchived), {
    domainId,
    ...props,
  })

//
// Select a domain for the current pro to use
//

export const SELECT_DOMAIN = 'PRO_ME::DOMAINS::SELECT'

/**
 *
 * @param  {[type]} domain  selected domain
 * @param  {[type]} options options
 *                          - redirectTo: url to redirect to once the restaurants group is selected
 */
export const selectMeDomain = (domain, options) =>
  createAction(SELECT_DOMAIN, {
    domain,
    options,
  })

//
//
//

export const FetchDomain = createApiEnumAction('PRO::DOMAIN::GET')

/**
 *
 * @param  {number} domainId  id of the pro's domain to retrieve
 * @param  {[type]} options can contains:
 *                            - withPro boolean
 *                            - withRole boolean
 */
export const fetchDomain = (domainId, options = { withProAccount: false, withRole: false }) =>
  createApiCallAction(FetchDomain, ProDomainApi.getDomain(domainId, options), {
    domainId,
    ...options,
  })
