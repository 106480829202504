import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import { createSelector } from 'reselect'

import { formatImportReport } from './format'

const getUploadData = state => state.entities.importUser.get('upload')
const getImportData = state => state.entities.importUser.get('import')

export const getImportUploadType = createSelector(
  [ getImportData ],
  (resultData) => {
    if (isNil(resultData)) {
      return null
    }
    const uploadType = resultData.get('uploadType')

    return uploadType || null
  }
)

export const getImportReport = createSelector(
  [ getImportData, getImportUploadType ],
  (resultData, uploadType) => {
    if (isNil(resultData)) {
      return null
    }
    const dataState = resultData.get('data')

    if (isNil(dataState)) {
      return null
    }

    const data = dataState.toJS()

    const formatProps = {
      uploadType,
    }
    return formatImportReport(data, formatProps)
  }
)

export const getImportUploadedFile = createSelector(
  [ getUploadData ],
  (uploadData) => {
    if (isNil(uploadData)) {
      return null
    }
    // map of files
    const files = uploadData.files

    if (isEmpty(files)) {
      return null
    }

    // get only one file, the first object
    return files[Object.keys(files)[0]]
  }
)

export const isFetchingImportUser = createSelector(
  [ getImportData ],
  (resultData) => {
    if (isNil(resultData)) {
      return null
    }
    const isFetching = resultData.get('isFetching')

    return isFetching || false
  }
)

export const getImportError = createSelector(
  [ getImportData ],
  (resultData) => {
    if (isNil(resultData)) {
      return null
    }
    const errorData = resultData.get('error')

    if (isNil(errorData)) {
      return null
    }

    const error = errorData.toJS()

    // little trick here: in case of report error, we receive the report.
    // we receive a more problematic error only if we have a code.
    return isNil(error.code) ? null : error
  }
)
