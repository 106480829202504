import Immutable from 'immutable'
import concat from 'lodash/concat'
import uniqWith from 'lodash/uniqWith'

import { createSimpleReducer, createReducer } from '../redux'

import {
  GetProRoleAction,
  GetProRoleListAction,
  PostProRoleAction,
  PutProRoleAction,
  RESET_DATA_ACTION,
} from './actions'

export * from './actions'
export * from './selectors'
export * from './constants'
export * from './middlewares'

//
// Contains ProRole reducers for:
// - retrieve a list of pro role
// - retrieve a pro role
// - post a pro role
// - put a pro role
//

// Example of ProRole data
// entity: {
//   data: null,
//   isFetching: false,
//   lastUpdated: null
//   error: null,
// }

const ENTITY_DEFAULT = Immutable.fromJS({
  data: null,
  isFetching: false,
  lastUpdated: null,
  error: null,
})

const DEFAULT = Immutable.fromJS({
  entity: {
    // entity per id
  },
  list: {
    data: null,
    isFetching: false,
    lastUpdated: null,
    pagin: null,
    error: null,
  },
})

const getProRoleRequest = (state, action) =>
  state.setIn(['entity', action.data.roleId], ENTITY_DEFAULT)

const getProRoleSuccess = (state, action) =>
  state.setIn(
    ['entity', action.data.roleId],
    Immutable.fromJS({
      data: action.response,
      isFetching: false,
      lastUpdated: new Date(),
      error: null,
    })
  )

const getProRoleFailure = (state, action) =>
  state.setIn(
    ['entity', action.data.roleId],
    Immutable.fromJS({
      data: null,
      isFetching: false,
      lastUpdated: new Date(),
      error: action.error,
    })
  )

const getProRoleListRequest = (state, action) =>
  state.mergeIn(['list'], {
    isFetching: true,
    lastUpdated: null,
    error: null,
  })

const getProRoleListSuccess = (state, action) => {
  let newList = []

  action.response = {
    data: action.response.data,
    paging: action.response.paging,
  }

  /**
   * A null before cursor means we ran the action to load the first
   * data page. In this case, we don't merge the data,
   * we reset it.
   */
  if (action.response.paging.cursors.before === null) {
    newList = action.response.data
  } else {
    let currentList = state.getIn(['list', 'data'])
    if (typeof currentList !== 'undefined') {
      currentList = currentList
        .valueSeq()
        .toArray()
        .map(item => {
          return item.toJS()
        })
    } else {
      currentList = []
    }

    newList = concat(currentList, action.response.data)
    newList = uniqWith(newList, (a, b) => a.id === b.id)
  }

  return state
    .merge({
      list: {
        isFetching: false,
        lastUpdated: new Date(),
        error: null,
      },
    })
    .mergeIn(['list', 'paging'], action.response.paging)
    .setIn(['list', 'data'], Immutable.fromJS(newList))
}

const getProRoleListFailure = (state, action) =>
  state.merge({
    list: {
      data: null,
      isFetching: false,
      lastUpdated: new Date(),
      error: action.error,
    },
  })

export const proRoleReducer = createReducer(DEFAULT, {
  [GetProRoleAction.REQUEST]: getProRoleRequest,
  [GetProRoleAction.SUCCESS]: getProRoleSuccess,
  [GetProRoleAction.FAILURE]: getProRoleFailure,
  [GetProRoleListAction.REQUEST]: getProRoleListRequest,
  [GetProRoleListAction.SUCCESS]: getProRoleListSuccess,
  [GetProRoleListAction.FAILURE]: getProRoleListFailure,
})

//
//
//

const resetData = (state, action) => createSimpleReducer.DEFAULT_STATE

export const postProRoleReducer = createSimpleReducer(PostProRoleAction, {
  [RESET_DATA_ACTION]: resetData,
})

export const putProRoleReducer = createSimpleReducer(PutProRoleAction, {
  [RESET_DATA_ACTION]: resetData,
})
