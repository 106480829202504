//
// - https://firebase.google.com/
// - https://github.com/prescottprue/react-redux-firebase/
//
//
// We use firebase to:
// - interact with cloud storage: https://firebase.google.com/products/storage/
//
//

// https://firebase.google.com/docs/web/setup
import firebase from 'firebase/app'
// You don't need to import firebase/app either since it's being imported above
import 'firebase/storage'
import 'firebase/database'
import 'firebase/auth'

import { combineReducers } from 'redux'
import { registerFirebaseConfig } from './config'
import { createSimpleReducer } from 'pmt-modules/redux'
import { PMTFirebaseAuth } from './actions'

export * from './config'
export * from 'react-redux-firebase'

let _firebaseApp = null

export const initializeFirebase = appConfig => {
  const config = appConfig.firebase

  if (config) {
    registerFirebaseConfig(config)

    if (config.credentials) {
      _firebaseApp = firebase.initializeApp(config.credentials)
    }
  }
}

export const getFirebaseApp = () => _firebaseApp

export const getFirebase = () => firebase

const firebaseAuthReducer = createSimpleReducer(PMTFirebaseAuth)

export const pmtFirebaseReducer = combineReducers({
  auth: firebaseAuthReducer,
})
