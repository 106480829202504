import ProDomainApi from 'pmt-modules/api/calls/ProDomainApi'

import { createApiCallAction, createApiEnumAction } from 'pmt-modules/api/utils'

export const LoadDomainRestaurantsGroupsAction = createApiEnumAction(
  'DOMAIN::RESTAURANTS_GROUPS::GET'
)

export const loadDomainRestaurantsGroups = restaurantsGroupId =>
  createApiCallAction(
    LoadDomainRestaurantsGroupsAction,
    ProDomainApi.getRestaurantsGroups(restaurantsGroupId),
    {
      restaurantsGroupId,
    }
  )
