import { createFormatter } from 'pmt-utils/format'

const formatRowData = (row) => {
  //
  // We get the indexes of the row / cell starting at '0'
  // An excel file column begins at 0
  //
  row.rowNumFormatted = row.rowNum + 1

  return row
}


const formatRowInformation = createFormatter(
  formatRowData,
)

export default formatRowInformation
