import Immutable from 'immutable'

import { LOCATION_CHANGE } from 'pmt-modules/routing'

import { FetchMeProDomains, FetchMeDomain, FetchDomain } from './actions'

export * from './actions'
export * from './selectors'
export * from './middlewares'
export * from './format'
export MeDomainContainer from './components/MeDomainContainer'
export DomainContainer from './components/DomainContainer'

const DEFAULT = Immutable.fromJS({
  isFetching: false,
  list: undefined, // must be undefined for default parameter on case FetchMeDomain.REQUEST
  error: null,
})

const listToObject = list => {
  const obj = {}
  list.forEach(
    domain =>
      (obj[domain.id] = {
        isFetching: false,
        data: domain,
        error: null,
      })
  )

  return obj
}

export const meDomainReducer = (state = DEFAULT, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return state.merge({
        error: null,
      })

    case FetchMeProDomains.REQUEST:
      return state.merge({
        isFetching: true,
        list: null,
        error: null,
      })

    case FetchMeProDomains.SUCCESS:
      return state.merge({
        isFetching: false,
        list: listToObject(action.response),
        error: null,
      })

    case FetchMeProDomains.FAILURE:
      return state.merge({
        isFetching: false,
        list: null,
        error: action.error,
      })

    //
    //
    //

    case FetchMeDomain.REQUEST:
      return state.updateIn(['list'], map => {
        if (!map) {
          map = new Immutable.Map()
        }

        return map.set(
          action.data.domainId,
          new Immutable.fromJS({
            isFetching: true,
            data: null,
            error: null,
          })
        )
      })

    case FetchMeDomain.SUCCESS:
      return state.updateIn(['list'], map => {
        if (!map) {
          map = new Immutable.Map()
        }

        return map.set(
          action.data.domainId,
          new Immutable.fromJS({
            isFetching: false,
            data: action.response,
            error: null,
          })
        )
      })

    case FetchMeDomain.FAILURE:
      // must use updateIn list and set on map.
      // in the case of the url query `domain` does not exists, and is changed by hand, it can
      // broke
      return state.updateIn(['list'], map => {
        if (!map) {
          map = new Immutable.Map()
        }
        return map.set(
          action.data.domainId,
          new Immutable.fromJS({
            isFetching: false,
            data: null,
            error: action.errror,
          })
        )
      })

    default:
      return state
  }
}

export const domainReducer = (state = DEFAULT, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return state.merge({
        error: null,
      })

    //
    //
    //

    case FetchDomain.REQUEST:
      return state.updateIn(['list'], (map = new Immutable.Map()) =>
        map.set(
          action.data.domainId,
          new Immutable.fromJS({
            isFetching: true,
            data: null,
            error: null,
          })
        )
      )

    case FetchDomain.SUCCESS:
      return state.setIn(
        ['list', action.data.domainId],
        new Immutable.fromJS({
          isFetching: false,
          data: action.response,
          error: null,
        })
      )

    case FetchDomain.FAILURE:
      // must use updateIn list and set on map.
      // in the case of the url query `domain` does not exists, and is changed by hand, it can
      // broke
      return state.updateIn(['list'], map => {
        if (!map) {
          map = new Immutable.Map()
        }
        return map.set(
          action.data.domainId,
          new Immutable.fromJS({
            isFetching: false,
            data: null,
            error: action.errror,
          })
        )
      })

    default:
      return state
  }
}
