import isNil from 'lodash/isNil'

const getTableWithTableType = (tableType, state) => {
  return state.entities.table.get(tableType)
}

export const getSortOptions = (tableType, state) => {
  let table = getTableWithTableType(tableType, state)

  if (isNil(table)) {
    return {}
  }

  const sortOptions = table.get('sortOptions')
  if (!sortOptions) {
    return {}
  }

  return table.get('sortOptions').toJS()
}
