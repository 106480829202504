import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let Store = props => (
  <SvgIcon {...props}>
    <path d="M12,18H6V14H12M21,14V12L20,7H4L3,12V14H4V20H14V14H18V20H20V14M20,4H4V6H20V4Z" />
  </SvgIcon>
)
Store = pure(Store)
Store.displayName = 'Store'
Store.muiName = 'SvgIcon'

export default Store
