import { createSimpleSelector, createSelectorsPerObject } from '../redux'

import { formatProAccount } from '../proAccountsList/format'

const getProAccountSelectorsPerObject = createSelectorsPerObject(
  state => state.entities.proAccount.proAccount,
  (state, props) => props.proAccountId,
  formatProAccount
)

export const makeIsFetchingProAccount = getProAccountSelectorsPerObject.makeIsFetching
export const makeGetProAccount = getProAccountSelectorsPerObject.makeGetData
export const makeGetFetchProAccountError = getProAccountSelectorsPerObject.makeGetError

//
//
//

const postProAccountSelectors = createSimpleSelector(state => state.entities.postProAccount)

export const isFetchingPostProAccount = postProAccountSelectors.isFetching
export const getDataPostProAccount = postProAccountSelectors.getData
export const getErrorPostProAccount = postProAccountSelectors.getError

//
//
//

const putProAccountSelectors = createSimpleSelector(state => state.entities.putProAccount)

export const isFetchingPutProAccount = putProAccountSelectors.isFetching
export const getDataPutProAccount = putProAccountSelectors.getData
export const getErrorPutProAccount = putProAccountSelectors.getError
