import isEmpty from 'lodash/isEmpty'
import { createApiCallAction, createApiEnumAction } from '../api/utils'

import createAction from '../redux/createAction'
import { getQueryParam } from 'pmt-utils/url'
import { getSessionSelectedRestaurantsGroupId, getSessionSelectedDomainId } from './session'

import RestaurantsGroupApi from '../api/calls/RestaurantsGroupApi'

//
//
//

export const BoActions = {
  SET_SELECTED_DOMAIN: 'BoActions::SET_SELECTED_DOMAIN',
  SET_SELECTED_RGROUP: 'BoActions::SET_SELECTED_RGROUP',
  // define the current restaurants group selected by the pro from a list
  SELECT_RGROUP: 'BoActions::SELECT_RGROUP',
}

export const setSelectedDomain = selectedDomain =>
  createAction(BoActions.SET_SELECTED_DOMAIN, {
    selectedDomain,
  })

export const setSelectedRestaurantsGroup = selectedRestaurantsGroup =>
  createAction(BoActions.SET_SELECTED_RGROUP, {
    selectedRestaurantsGroup,
  })

//
// define the current restaurants group selected by the pro from a list
//

/**
 *
 * @param  {[type]} rGroup   [description]
 * @param  {[type]} domainId [description]
 * @param  {[type]} options
 *                          - redirectTo: url to redirect to once the restaurants group is selected
 *
 */
export const selectRestaurantsGroup = (rGroup, domainId, options) =>
  createAction(BoActions.SELECT_RGROUP, {
    rGroup,
    domainId,
    options,
  })

//
// Load the default user domain and restaurants group
//

export const LOAD_DEFAULT_DOMAIN = 'BO::LOAD_DEFAULT_DOMAIN'

/**
 * the currently used user domain is set on the url (query param `domain`)
 */
const getUrlDomainId = () => {
  const domainId = getQueryParam('domain', window.location.path)
  // isEmpty to handle query params such as `domain=`, that produce an empty value.
  // We consider an empty value as being a null value to simplify checks on the rest of the flow.
  return !isEmpty(domainId) ? domainId : null
}

/**
 * the currently used restaurants group id is set on the url (query param `rGroup`)
 */
export const getUrlRGroupId = () => {
  const rGroupId = getQueryParam('rGroupId', window.location.path)
  // isEmpty to handle query params such as `rGroupId=`, that produce an empty value.
  // We consider an empty value as being a null value to simplify checks on the rest of the flow.
  return !isEmpty(rGroupId) ? rGroupId : null
}

export const loadDefaultDomain = (
  domainId = getUrlDomainId(),
  rGroupId = getUrlRGroupId(),
  retriedWithDomainDeductionAfterErrorFromApi = false
) =>
  createAction(LOAD_DEFAULT_DOMAIN, {
    domainId,
    rGroupId,
    fallbackToSession: true,
    sessionRGroupId: getSessionSelectedRestaurantsGroupId(),
    sessionDomainId: getSessionSelectedDomainId(),
    retriedWithDomainDeductionAfterErrorFromApi,
  })

//
//
//

export const GetSelectedRestaurantsGroupAction = createApiEnumAction('RGROUP::SELECTED::GET')

export const fetchSelectedRestaurantsGroup = rGroupId =>
  createApiCallAction(
    GetSelectedRestaurantsGroupAction,
    RestaurantsGroupApi.getRestaurantsGroup(rGroupId),
    {
      rGroupId,
    }
  )
