import React from 'react'

import isEmpty from 'lodash/isEmpty'
import template from 'pmt-utils/string/template'
import ArianePath from './ArianePath'
import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  root: {
    display: 'flex',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: `0 ${theme.spacing(2)}px`,
  },
})

const getConfig = (arianeProps, pathDefinition) => {
  if (!pathDefinition || !arianeProps || !arianeProps.routes) {
    return null
  }

  return arianeProps.routes[pathDefinition.name]
}

const Ariane = ({ arianeProps, classes, routeConfig }) => {
  //
  // template
  //
  const routeProps = routeConfig.props

  const ariane = routeProps.ariane && routeProps.ariane()

  if (!ariane) {
    return null
  }
  if (ariane && arianeProps.templateValues) {
    ariane.forEach(path => {
      path.title = template(isEmpty(path.title) ? '' : path.title, arianeProps.templateValues)
    })
  }

  return (
    <div className={classes.root}>
      {(ariane || []).map((path, index) => (
        <ArianePath
          key={index}
          path={path}
          config={getConfig(arianeProps, path ? path.definition : null)}
          isLast={index === ariane.length - 1}
        />
      ))}
    </div>
  )
}

export default withStyles(styles)(Ariane)
