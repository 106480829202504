export { default as TableContainer } from '@material-ui/core/TableContainer'
export { default as TableHead } from '@material-ui/core/TableHead'
export { default as TableBody } from '@material-ui/core/TableBody'
export { default as TableRow } from '@material-ui/core/TableRow'
export { default as TableCell } from '@material-ui/core/TableCell'
export { default as TableSortLabel } from '@material-ui/core/TableSortLabel'
export { default as TableFooter } from '@material-ui/core/TableFooter'
export { default as TablePagination } from '@material-ui/core/TablePagination'

export default from '@material-ui/core/Table'
