import { redirectTo } from '../routing'

import { createApiCallAction, createApiEnumAction } from '../api/utils'

import { getRoute } from '../routing'
import UserApi from '../api/calls/UserApi'

// NOTE: comment when do not using it for debug
// import { getUserFixture } from '../api/fixtures/user'

export const GetUserAction = createApiEnumAction('USER::GET')

export const redirectToUser = (userId, queryParams) => (dispatch, getState) => {
  return dispatch(redirectTo(getRoute('STORE_LOCATOR_USER'), { userId }, queryParams))
}

export const fetchUser = (rGroupId, userId, loadAccounts = true) =>
  createApiCallAction(GetUserAction, UserApi.getUser(rGroupId, userId, loadAccounts), {
    userId,
  })

export const fetchUserWithUnknownRestaurantsGroup = (userId, loadAccounts = true) =>
  createApiCallAction(
    GetUserAction,
    UserApi.getUserWithUnknownRestaurantsGroup(userId, loadAccounts),
    {
      userId,
    }
  )

//
//
//

export const GetUserListAction = createApiEnumAction('USER_LIST::GET')

export const fetchUserList = (rGroupId, nextCursor = '', limit = 50) =>
  createApiCallAction(GetUserListAction, UserApi.getUserList(rGroupId, nextCursor, limit), {
    rGroupId,
    nextCursor,
    limit,
  })

//
//
//

export const PutUserAction = createApiEnumAction('USER::PUT')

/**
 * Update an user from restaurants group
 * used on backoffice
 */
export const updateUser = user =>
  createApiCallAction(PutUserAction, UserApi.putUser(user), {
    user,
    userId: user.id,
  })
