import { createFormatter, createListFormatter } from 'pmt-utils/format'

const formatCreator = proRole => {
  // old roles does not have a creator, they have been created by a pmt admin
  proRole.createdByPmt = true

  // TODO: api does not send a creator / creatorId for now
  // if (proRole.creator && !endsWith(proRole.creator.email, '@paymytable.com')) {
  //   // not created by pmt if creator exists and has not a paymytable email
  //   proRole.createdByPmt = false
  // }

  return proRole
}

export const formatProRole = createFormatter(formatCreator)

export const formatProRolesList = createListFormatter(formatProRole)
