import isNil from 'lodash/isNil'
import { createSelector } from 'reselect'

import { formatUser } from '../user/format'

const getUserIdOnProps = (state, props) => props.userId
const getUserCardState = state => state.entities.userCard

const makeGetUserCardUserData = () =>
  createSelector([getUserIdOnProps, getUserCardState], (userId, userCardsState) => {
    const userCardsData = userCardsState.get(userId)
    if (isNil(userCardsData)) {
      return null
    }

    let userCard = userCardsData.get('userData')

    if (isNil(userCard)) {
      return null
    }

    return userCard
  })

export const makeGetUserCardUser = () =>
  createSelector([makeGetUserCardUserData()], userCard => {
    if (!userCard) {
      return null
    }

    const userCardUser = userCard.get('user')

    if (!userCardUser) {
      return null
    }

    return formatUser(userCardUser.toJS())
  })

export const makeIsFetchingUserCardUser = () =>
  createSelector([makeGetUserCardUserData()], userCard => {
    if (!userCard) {
      return false
    }

    return userCard.get('isFetching') || false
  })

export const makeGetUserAmountState = () =>
  createSelector([makeGetUserCardUserData()], userCard => {
    if (!userCard) {
      return false
    }

    const userCardUser = userCard.get('user')
    if (!userCardUser) {
      return null
    }

    const formattedUser = formatUser(userCardUser.toJS())

    if (isNil(formattedUser.userAccount) || !formattedUser.userAccount.amountState) {
      return null
    }

    return formattedUser.userAccount.amountState || null
  })

export const makeGetUserCardError = () =>
  createSelector([makeGetUserCardUserData()], userCard => {
    if (!userCard) {
      return null
    }

    const error = userCard.get('error')

    if (!error) {
      return null
    }

    return formatUser(error.toJS())
  })
