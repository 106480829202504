const RestaurantSettingsPermissions = {
  READ: 'restaurantSettings',

  READ_PUBLIC_INFO: 'restaurantSettings.publicInfo',

  EDIT_PUBLIC_INFO: 'restaurantSettings.publicInfo.canEdit',

  READ_MENU: 'restaurantSettings.menu',

  EDIT_MENU: 'restaurantSettings.menu.canEdit',

  READ_OPENING_HOURS: 'restaurantSettings.openingHours',

  EDIT_OPENING_HOURS: 'restaurantSettings.openingHours.canEdit',

  READ_LEGAL: 'restaurantSettings.legal',

  READ_LEGAL_RESTAURATEUR: 'restaurantSettings.legal.restaurateur',

  EDIT_LEGAL_RESTAURATEUR: 'restaurantSettings.legal.restaurateur.canEdit',

  READ_LEGAL_SOCIETY: 'restaurantSettings.legal.society',

  EDIT_LEGAL_SOCIETY: 'restaurantSettings.legal.society.canEdit',

  READ_LEGAL_REPRESENTATIVE: 'restaurantSettings.legal.representative',

  EDIT_LEGAL_REPRESENTATIVE: 'restaurantSettings.legal.representative.canEdit',

  READ_FINANCE: 'restaurantSettings.finance',

  READ_FINANCE_BANK_ACCOUNT: 'restaurantSettings.finance.bankAccount',

  EDIT_FINANCE_BANK_ACCOUNT: 'restaurantSettings.finance.bankAccount.canEdit',

  CREATE_FINANCE_BANK_ACCOUNT: 'restaurantSettings.finance.bankAccount.canCreate',

  READ_FINANCE_OTHER: 'restaurantSettings.finance.other',

  EDIT_FINANCE_OTHER: 'restaurantSettings.finance.other.canEdit',

  READ_FEATURES: 'restaurantSettings.features',

  EDIT_FEATURES: 'restaurantSettings.features.canEdit',

  READ_CONFIGURATION: 'restaurantSettings.configuration',

  READ_CONFIGURATION_GENERAL: 'restaurantSettings.configuration.general',

  EDIT_CONFIGURATION_GENERAL: 'restaurantSettings.configuration.general.canEdit',

  READ_CONFIGURATION_POS: 'restaurantSettings.configuration.pos',

  EDIT_CONFIGURATION_POS: 'restaurantSettings.configuration.pos.canEdit',

  READ_CONFIGURATION_MANGO_PAY: 'restaurantSettings.configuration.mango_pay',

  EDIT_CONFIGURATION_MANGO_PAY: 'restaurantSettings.configuration.mango_pay.canEdit',

  READ_CONFIGURATION_COMMISSION: 'restaurantSettings.configuration.commission',

  EDIT_CONFIGURATION_COMMISSION: 'restaurantSettings.configuration.commission.canEdit',

  READ_THEME: 'restaurantSettings.theme',

  EDIT_THEME: 'restaurantSettings.theme.canEdit',
}

export default RestaurantSettingsPermissions
