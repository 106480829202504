import createMiddleware from '../redux/createMiddleware'

import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

import { getRoute, formatQueryParams, reloadTo, reloadToPath } from '../routing'

import {
  BoActions,
  LOAD_DEFAULT_DOMAIN,
  loadDefaultDomain,
  getUrlRGroupId,
  // fetchSelectedRestaurantsGroup,
} from './actions'

import { fetchMeProDomainsList, FetchMeProDomains, fetchMeDomain, FetchMeDomain } from '../domain'

import { logoutPro } from '../authPro/actions'

import { boRedirectToPermissionErrorPage } from './security/middlewares'

const onInvalidDomain = createMiddleware(
  [FetchMeProDomains.FAILURE, FetchMeDomain.FAILURE],
  ({ dispatch, action }) => {
    // retriedWithDomainDeductionAfterErrorFromApi true -> already been there (on the else section)
    // and we couldn't deduce a good domain id, so we log out.
    if (
      'null' === action.data.domainId ||
      action.data.retriedWithDomainDeductionAfterErrorFromApi
    ) {
      // already tried to loadDefaultDomain or there is something wrong with the data ("null" as string)
      dispatch(logoutPro())
    } else {
      // null to force search for domain
      // true -> put retriedWithDomainDeductionAfterErrorFromApi to true for the next load domain action,
      // avoiding to have an infinite loop here.
      dispatch(loadDefaultDomain(null, getUrlRGroupId(), true))
    }
  }
)

const boSelectRestaurantsGroupMiddleware = createMiddleware(
  BoActions.SELECT_RGROUP,
  ({ action }) => {
    const queryParams = {
      rGroupId: action.rGroup.id,
      domain: action.domainId,
    }

    // for now, we reload.
    // Since the current selected restaurants group change, too many data has to be reloaded,
    // so we reload, not redirect
    if (!isEmpty(action.options.redirectTo)) {
      const redirectTo = action.options.redirectTo

      // set new query params
      const finalRedirectTo = formatQueryParams(redirectTo, queryParams)

      reloadToPath(finalRedirectTo)
    } else {
      reloadTo(getRoute('HOME'), {}, queryParams)
    }
  }
)

/**
 * Flows:
 *
 * We try to retrieve the pro `domain` by priority with the domain info
 * - on the url (`domain` query param)
 * - on session
 * - default user domain
 *
 * We try to retrieve the pro `restaurantsGroup` by priority with the restaurantsGroup info
 * - on the url (`rGroupId` query param)
 * - on session
 * - default restaurants group
 */
const boLoadDefaultDomainMiddleware = createMiddleware(
  LOAD_DEFAULT_DOMAIN,
  ({ action, dispatch }) => {
    // domainId and rGroupId are mostly the one on the url.
    const domainId = action.domainId
    const rGroupId = action.rGroupId
    const retriedWithDomainDeductionAfterErrorFromApi =
      action.retriedWithDomainDeductionAfterErrorFromApi

    // retrieve on session
    const sessionRGroupId = String(action.sessionRGroupId)
    const sessionDomainId = String(action.sessionDomainId)

    // by priority: data on url then on session
    const rGoupIdToUse = isNil(rGroupId) ? sessionRGroupId : rGroupId
    const domainIdToUse = isNil(domainId) ? sessionDomainId : domainId

    const useDefaultRGroup = isNil(rGoupIdToUse)

    if (!isNil(domainIdToUse)) {
      // we have data to select a domain
      dispatch(
        // try to select the domain
        // see modules/domain/middlewares for the next step
        fetchMeDomain(domainIdToUse, {
          useAsSelectedDomain: true,
          useDefaultRGroup,
          // allow us to use the default domain if the domainIdToUse is invalid for the pro.
          // It can be invalid when :
          // - the query param is invalid
          // - the session domain id is invalid (after a logout / login with another account for
          // example)
          fallbackToDefault: true,
          // if we are using the action domainId (mostly the one on the url), and there is an error
          // should we try to use the data on session storage?
          fallbackToSession: domainIdToUse !== sessionDomainId,
          rGoupIdToUse,
          sessionRGroupId,
          sessionDomainId,
          retriedWithDomainDeductionAfterErrorFromApi,
        })
      )
    } else {
      // we have no data on the action (mostly on the url) or in session, retrieve the user domains
      // list, and select the default one on it.
      // see modules/domain/middlewares for the next step
      dispatch(
        fetchMeProDomainsList({
          useAsSelectedDomain: true,
          useDefaultRGroup,
          rGoupIdToUse,
          sessionRGroupId,
          sessionDomainId,
          authFlow: true,
          retriedWithDomainDeductionAfterErrorFromApi,
        })
      )
    }

    // we don't have to fetch the restaurants group since we have it we the domain
    // our middlewares in domain/middlewares handle the group
    // Problem was that the rGoupIdToUse here could be invalid for the domain
    // so we need to check on the domain middlewares that the group is valid
    // We can select the group on the domain middlewarre because we load the domain with its
    // restaurants group. It could change, and we would need to uncomment this + find a way to
    // verify that the group selected is valid for the domain
    // if (!useDefaultRGroup) {
    //   dispatch(fetchSelectedRestaurantsGroup(rGoupIdToUse))
    // }
  }
)

export const boMiddlewares = [
  onInvalidDomain,
  boSelectRestaurantsGroupMiddleware,
  boLoadDefaultDomainMiddleware,
  boRedirectToPermissionErrorPage,
]
