import ProRoleErrors from '../errors/ProRoleErrors'
import ApiEndpoints from '../config/ApiEndpoints'

const ProRoleApi = {
  getProRole: roleId => ({
    endpoint: ApiEndpoints.PRO_ROLE,
    type: 'GET',
    params: {
      roleId,
    },
  }),

  getProRoleList: (restaurantsGroupId, cursor, limit) => ({
    endpoint: ApiEndpoints.PRO_ROLES,
    type: 'GET',
    query: {
      restaurantsGroupId,
      cursor,
      limit,
    },
  }),

  postProRole: proRole => ({
    endpoint: ApiEndpoints.PRO_ROLES,
    type: 'POST',
    params: {},
    body: proRole,
    errorHandler: ProRoleErrors.post,
  }),

  putProRole: (roleId, proRole) => ({
    endpoint: ApiEndpoints.PRO_ROLE,
    type: 'PUT',
    params: {
      roleId,
    },
    body: proRole,
    errorHandler: ProRoleErrors.put,
  }),
}

export default ProRoleApi
