import invariant from 'invariant'
import forEach from 'lodash/forEach'

export const setPermissionToChildren = (permission, hasPermission) => {
  if (permission.permissions) {
    permission.permissions = permission.permissions.map(permission => {
      if (permission.creatorHasPermission !== false) {
        permission.hasPermission = hasPermission
      }
      return setPermissionToChildren(permission, hasPermission)
    })
  }

  return permission
}

export const hasOneChildrenWithPermission = permission => {
  if (permission.final) {
    return permission.hasPermission
  }

  if (permission.permissions) {
    let res = false
    forEach(permission.permissions, subPermission => {
      if (subPermission.hasPermission === true) {
        res = true
        return false // quit loop
      }
      const subPermissionHasPermission = hasOneChildrenWithPermission(subPermission)
      if (subPermissionHasPermission === true) {
        res = true
        return false // quit loop
      }
    })
    return res
  }

  invariant(false, 'should not happen')
  return false
}

//
//
//

export const hasOneChildrenWithCreatorPermission = permission => {
  if (permission.final) {
    return permission.creatorHasPermission
  }

  if (permission.permissions) {
    let res = false
    forEach(permission.permissions, subPermission => {
      if (subPermission.creatorHasPermission === true) {
        res = true
        return false // quit loop
      }
      const subPermissionHasPermission = hasOneChildrenWithCreatorPermission(subPermission)
      if (subPermissionHasPermission === true) {
        res = true
        return false // quit loop
      }
    })
    return res
  }

  invariant(false, 'should not happen')
  return false
}

//
//
//

// Returns true if theree is a sub permission with hasPermission set to true. We don't check
// for hasPermission set to true when creatorHasPermission is set to false
//
// Note: when the current permission does no have the permission, and the creator does
// not have it too, `hasAllChildrenWithPermission` will return true
// So you should use hasOneChildrenWithCreatorPermission too in your condition
export const hasAllChildrenWithPermission = permission => {
  if (permission.permissions) {
    let res = true
    forEach(permission.permissions, subPermission => {
      if (subPermission.creatorHasPermission) {
        if (subPermission.hasPermission === false) {
          res = false
          return false // quit loop
        }
        if (!subPermission.final) {
          const subPermissionHasAllPermissions = hasAllChildrenWithPermission(subPermission)
          if (subPermissionHasAllPermissions === false) {
            res = false
            return false // quit loop
          }
        }
      }
    })
    return res
  }

  invariant(false, `missing permission definition for ${JSON.stringify(permission)}`)
  return false
}

//
//
//

const hasOneChildrenWith = comparator => permission => {
  if (permission.permissions) {
    const callback = hasOneChildrenWith(comparator)

    let res = false
    forEach(permission.permissions, subPermission => {
      if (subPermission.creatorHasPermission) {
        if (comparator(subPermission) && subPermission.hasPermission) {
          res = true
          return false // quit loop
        }

        if (!subPermission.final) {
          const subPermissionHasOnePermission = callback(subPermission)
          if (subPermissionHasOnePermission === true) {
            res = true
            return false // quit loop
          }
        }
      }
    })

    return res
  }

  invariant(false, 'should not happen')
  return false
}

const hasAllChildrenWith = (comparator, level = 0) => permission => {
  if (permission.permissions) {
    let res = true
    const callback = hasAllChildrenWith(comparator, level + 1)

    forEach(permission.permissions, subPermission => {
      if (subPermission.creatorHasPermission) {
        if (comparator(subPermission) && !subPermission.hasPermission) {
          res = false
          return false // quit loop
        }

        // check child permissions
        if (!subPermission.final) {
          const subPermissionHasAllPermissions = callback(subPermission)
          if (subPermissionHasAllPermissions === false) {
            res = false
            return false // quit loop
          }
        }
      }
    })

    // without `hasOnePermissionWith` we consider it having all the permissions if there is no
    // permission at all in the first level
    return res && (level > 0 || hasOneChildrenWith(comparator)(permission))
  }

  invariant(false, 'should not happen')
  return false
}

export const hasAllChildrenWithReadPermission = hasAllChildrenWith(permission => permission.isRead)

export const hasAllChildrenWithCreatePermission = hasAllChildrenWith(
  permission => permission.isCreate
)

export const hasAllChildrenWithEditPermission = hasAllChildrenWith(permission => permission.isEdit)

export const hasAllChildrenWithArchivedPermission = hasAllChildrenWith(
  permission => permission.isArchive
)

//
//
//

const setAllChildrenWith = comparator => (permission, hasPermission) => {
  if (permission.permissions) {
    const callback = setAllChildrenWith(comparator)
    let permissionGrantedToOneChild = false
    permission.permissions = permission.permissions.map(subPermission => {
      if (subPermission.creatorHasPermission !== false) {
        if (comparator(subPermission) === true) {
          subPermission.hasPermission = hasPermission

          permissionGrantedToOneChild = hasPermission

          if (subPermission.isRead && !subPermission.hasPermission) {
            // removed read permission, must remove all the sub-permissions
            subPermission = setPermissionToChildren(subPermission, false)
            return subPermission
          }
        }
      }
      const updatedSubPermission = callback(subPermission, hasPermission)

      if (updatedSubPermission.hasPermission) {
        permissionGrantedToOneChild = true
      }

      return updatedSubPermission
    })

    if (permissionGrantedToOneChild) {
      // a child has the permission, the parent must have it too (read)
      permission.hasPermission = true
    }
  }

  return permission
}

export const setAllChildrenWithReadPermission = setAllChildrenWith(permission => permission.isRead)

export const setAllChildrenWithCreatePermission = setAllChildrenWith(
  permission => permission.isCreate
)

export const setAllChildrenWithEditPermission = setAllChildrenWith(permission => permission.isEdit)

export const setAllChildrenWithArchivedPermission = setAllChildrenWith(
  permission => permission.isArchive
)
