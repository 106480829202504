import map from 'lodash/map'

import { rowKeysColumn, rowKeysLabel } from '../constants'
import { formatUser } from '../../user/format'

const formatDataTable = (datas) => {

  const formatHeader = key => ({
    title: rowKeysColumn[key],
    subtitle: rowKeysLabel[key],
  })

  const tableHeadColumns = [
    { title: 'Ligne' },
    { title : 'Action' },
    formatHeader('firstName'),
    formatHeader('lastName'),
    formatHeader('birthdate'),
    formatHeader('email'),
    formatHeader('nationality'),
    formatHeader('countryOfResidence'),
    formatHeader('companyName'),
    formatHeader('companyId'),
    formatHeader('employeeId'),
    formatHeader('physicalBadgeId'),
    formatHeader('grantLevelId'),
    formatHeader('billingEntityId'),
    formatHeader('balance'),
    formatHeader('status'),
    formatHeader('gender'),
    formatHeader('mobile'),
    formatHeader('address'),
    formatHeader('postCode'),
    formatHeader('city'),
    formatHeader('country'),
  ]

  const dataTable = {
    tableHeadColumns,
  }

  // first line is line 2, we increment before adding the value
  // so line is index based 2
  let line = 1
  dataTable.rows = map(datas, (data, key) => {
    const { userDB, userAccount, userAddress, isUpdate, deposit } = data

    const user = formatUser(userDB)

    line += 1

    return {
      isUpdate,
      // keys: see `constants.rowKeys`
      cells: [
        {
          columnName: 'line',
          value: line,
        },
        {
          columnName: 'action',
          value: isUpdate ? 'Modifié' : 'Créé',
        },
        {
          columnName: 'firstName',
          value: user.firstName,
        },
        {
          columnName: 'lastName',
          value: user.lastName,
        },
        {
          columnName: 'birthdate',
          value: user.birthdateFormatted,
        },
        {
          columnName: 'email',
          value: user.email,
        },
        {
          columnName: 'nationality',
          value: user.nationality,
        },
        {
          columnName: 'countryOfResidence',
          value: user.countryOfResidence,
        },
        {
          columnName: 'companyName',
          value: userAccount.externalInfo.companyName,
        },
        {
          columnName: 'companyId',
          value: userAccount.externalInfo.companyId,
        },
        {
          columnName: 'employeeId',
          value: userAccount.externalInfo.employeeId,
        },
        {
          columnName: 'physicalBadgeId',
          value: userAccount.externalInfo.badgeId,
        },
        {
          columnName: 'grantLevelId',
          value: userAccount.externalInfo.employeeGrantLevel,
        },
        {
          columnName: 'billingEntityId',
          value: userAccount.externalInfo.billingEntityId,
        },
        {
          columnName: 'balance',
          value: deposit ? deposit.amount : 0,
        },
        {
          columnName: 'status',
          value: '', // TODO: handle
        },
        {
          columnName: 'gender',
          value: user.genderLabel,
        },
        {
          columnName: 'mobile',
          value: user.phoneFormatted,
        },
        {
          columnName: 'address',
          value: userAddress ? userAddress.street : '',
        },
        {
          columnName: 'postCode',
          value: userAddress ? userAddress.postCode : '',
        },
        {
          columnName: 'city',
          value: userAddress ? userAddress.city : '',
        },
        {
          columnName: 'country',
          value: userAddress ? userAddress.country : '',
        },
      ]
    }
  })

  return dataTable
}

export default formatDataTable
