import { tr } from 'pmt-modules/i18n'
import invariant from 'invariant'

export const EmailType = {
  FEEDBACK: 'FEEDBACK',
  PAYMENT_WITH_PDF: 'PAYMENT_WITH_PDF',
  PAYMENT_REFUNDED: 'PAYMENT_REFUNDED',
  PAYMENT_REMOTE: 'PAYMENT_REMOTE',
  USER_EMAIL_VERIFICATION: 'USER_EMAIL_VERIFICATION',
  USER_EMAIL_CHANGED_TO_NEW_EMAIL: 'USER_EMAIL_CHANGED_TO_NEW_EMAIL',
  USER_EMAIL_CHANGED_TO_OLD_EMAIL: 'USER_EMAIL_CHANGED_TO_OLD_EMAIL',
  USER_PASSWORD_RECOVERY_LINK: 'USER_PASSWORD_RECOVERY_LINK',
  USER_PASSWORD_CHANGED: 'USER_PASSWORD_CHANGED',
  USER_LIGHT_CREATE_ACCOUNT: 'USER_LIGHT_CREATE_ACCOUNT',
  USER_LIGHT_REGISTRATION_CODE: 'USER_LIGHT_REGISTRATION_CODE',
  // USER_SPONSORED_FRIEND: 'USER_SPONSORED_FRIEND',

  ORDER_ON_SITE_PAYMENT: 'ORDER_ON_SITE_PAYMENT',
  ORDER_PAID: 'ORDER_PAID', // ORDER_NOTIFY_RESTAURANT: 'ORDER_NOTIFY_RESTAURANT',
  ORDER_REFUNDED: 'ORDER_REFUNDED',
  // MAIL_EXPORT_FILE_LINK: 'MAIL_EXPORT_FILE_LINK',
  // MAIL_EXPORT_SUBSIDY_BILL_LINK: 'MAIL_EXPORT_SUBSIDY_BILL_LINK',
  // MAIL_EXPORT_DEPOSITS_LINK: 'MAIL_EXPORT_DEPOSITS_LINK',
  // MAIL_RESTAURANTS_GROUP_SALES: 'MAIL_RESTAURANTS_GROUP_SALES',
  // ACCOUNT_CREATED_BY_IMPORT: 'ACCOUNT_CREATED_BY_IMPORT',
  // PRO_ACCOUNT_CREATED: 'PRO_ACCOUNT_CREATED',
  // PRO_PASSWORD_RECOVERY_LINK: 'PRO_PASSWORD_RECOVERY_LINK',

  USER_ACCOUNT_LOW_BALANCE: 'USER_ACCOUNT_LOW_BALANCE',
}

export const EmailTemplateType = {
  [EmailType.FEEDBACK]: 'feedback/feedback',

  [EmailType.PAYMENT_WITH_PDF]: 'payment/payment_with_pdf',

  [EmailType.PAYMENT_REFUNDED]: 'payment/refunded',
  [EmailType.PAYMENT_REMOTE]: 'payment/remote',

  [EmailType.USER_EMAIL_VERIFICATION]: 'user/user_email_verification',
  [EmailType.USER_EMAIL_CHANGED_TO_NEW_EMAIL]: 'user/email_changed_to_new_email',
  [EmailType.USER_EMAIL_CHANGED_TO_OLD_EMAIL]: 'user/email_changed_to_old_email',

  [EmailType.USER_PASSWORD_RECOVERY_LINK]: 'user/user_password_recovery_link',
  [EmailType.USER_PASSWORD_CHANGED]: 'user/user_password_changed',
  [EmailType.USER_LIGHT_CREATE_ACCOUNT]: 'user/user_light_create_account',
  [EmailType.USER_LIGHT_REGISTRATION_CODE]: 'user/user_light_registration_code',

  // [EmailType.USER_SPONSORED_FRIEND]: 'user/sponsored_friend',

  [EmailType.ORDER_ON_SITE_PAYMENT]: 'order/order_on_site_payment',
  [EmailType.ORDER_PAID]: 'order/order_paid',
  // [EmailType.ORDER_NOTIFY_RESTAURANT]: 'order/order_notify_restaurant',
  [EmailType.ORDER_REFUNDED]: 'order/order_refunded',

  // [EmailType.MAIL_EXPORT_FILE_LINK]: 'export/mail_export_data',
  // [EmailType.MAIL_EXPORT_SUBSIDY_BILL_LINK]: 'export/mail_export_subsidy_bill',
  // [EmailType.MAIL_EXPORT_DEPOSITS_LINK]: 'export/mail_export_deposits',
  // [EmailType.MAIL_RESTAURANTS_GROUP_SALES]: 'export/mail_restaurants_group_sales',
  // [EmailType.ACCOUNT_CREATED_BY_IMPORT]: 'user/user_created_by_pro',
  // [EmailType.PRO_ACCOUNT_CREATED]: 'pro/pro_account_created',
  // [EmailType.PRO_PASSWORD_RECOVERY_LINK]: 'pro/pro_password_recovery_link',

  [EmailType.USER_ACCOUNT_LOW_BALANCE]: 'user_account/low_balance',
}

export const getTemplateForEmailType = type => {
  return EmailTemplateType[type]
}

export const getEmailTypeLabel = type => {
  switch (type) {
    case EmailType.FEEDBACK:
      return tr('global.templates.email.FEEDBACK')
    case EmailType.PAYMENT_WITH_PDF:
      return tr('global.templates.email.PAYMENT_WITH_PDF')
    case EmailType.PAYMENT_REFUNDED:
      return tr('global.templates.email.PAYMENT_REFUNDED')
    case EmailType.PAYMENT_REMOTE:
      return tr('global.templates.email.PAYMENT_REMOTE')
    case EmailType.USER_EMAIL_VERIFICATION:
      return tr('global.templates.email.USER_EMAIL_VERIFICATION')
    case EmailType.USER_EMAIL_CHANGED_TO_NEW_EMAIL:
      return tr('global.templates.email.USER_EMAIL_CHANGED_TO_NEW_EMAIL')
    case EmailType.USER_EMAIL_CHANGED_TO_OLD_EMAIL:
      return tr('global.templates.email.USER_EMAIL_CHANGED_TO_OLD_EMAIL')
    case EmailType.USER_PASSWORD_RECOVERY_LINK:
      return tr('global.templates.email.USER_PASSWORD_RECOVERY_LINK')
    case EmailType.USER_PASSWORD_CHANGED:
      return tr('global.templates.email.USER_PASSWORD_CHANGED')
    case EmailType.USER_LIGHT_CREATE_ACCOUNT:
      return tr('global.templates.email.USER_LIGHT_CREATE_ACCOUNT')
    case EmailType.USER_LIGHT_REGISTRATION_CODE:
      return tr('global.templates.email.USER_LIGHT_REGISTRATION_CODE')
    case EmailType.ORDER_ON_SITE_PAYMENT:
      return tr('global.templates.email.ORDER_ON_SITE_PAYMENT')
    case EmailType.ORDER_PAID:
      return tr('global.templates.email.ORDER_PAID')
    case EmailType.ORDER_REFUNDED:
      return tr('global.templates.email.ORDER_REFUNDED')
    case EmailType.USER_ACCOUNT_LOW_BALANCE:
      return tr('global.templates.email.USER_ACCOUNT_LOW_BALANCE')

    default:
      invariant(false, `getEmailTypeLabel: ${type} not handled`)
  }
}

//
// custom texts
//

export const CustomTextSource = {
  RESTAURANTS_GROUP: 'RESTAURANTS_GROUP',
  RESTAURANT: 'RESTAURANT',
}

/**
 * All the custom text that can exist
 */
export const CustomTextType = {
  ORDER__CART__ON_SITE: 'ORDER__CART__ON_SITE', //	message personnalisé panier sur place
  ORDER__CART__DELIVERY: 'ORDER__CART__DELIVERY', //	message personnalisé panier livraison
  ORDER__CART__TAKE_AWAY: 'ORDER__CART__TAKE_AWAY', //	message personnalisé panier à emporter
  ORDER__REGISTER__SUBHEADER: 'ORDER__REGISTER__SUBHEADER', //	message personnalisé inscription
  ORDER__LOGIN__SUBHEADER: 'ORDER__LOGIN__SUBHEADER', //	message personnalisé connexion
  order_TAKE_AWAY_instructions: 'order_TAKE_AWAY_instructions', // order plugin & api order email confirmation instructions
  order_ON_SITE_instructions: 'order_ON_SITE_instructions', // order plugin & api order email confirmation instructions
  order_DELIVERY_instructions: 'order_DELIVERY_instructions', // order plugin & api order email confirmation instructions
}

/**
 * The list of custom texts for the restaurant
 */
export const getRestaurantCustomTextTypes = () => [
  CustomTextType.ORDER__CART__ON_SITE,
  CustomTextType.ORDER__CART__DELIVERY,
  CustomTextType.ORDER__CART__TAKE_AWAY,
  CustomTextType.ORDER__REGISTER__SUBHEADER,
  CustomTextType.ORDER__LOGIN__SUBHEADER,
  CustomTextType.order_TAKE_AWAY_instructions,
  CustomTextType.order_ON_SITE_instructions,
  CustomTextType.order_DELIVERY_instructions,
]

/**
 * Custom text explaination
 */
export const getCustomTextTypeExplaination = type => {
  switch (type) {
    case CustomTextType.order_TAKE_AWAY_instructions:
      return tr('global.CustomTextType.order_TAKE_AWAY_instructions.explaination')
    case CustomTextType.order_ON_SITE_instructions:
      return tr('global.CustomTextType.order_ON_SITE_instructions.explaination')
    case CustomTextType.order_DELIVERY_instructions:
      return tr('global.CustomTextType.order_DELIVERY_instructions.explaination')

    case CustomTextType.ORDER__CART__ON_SITE:
    case CustomTextType.ORDER__CART__DELIVERY:
    case CustomTextType.ORDER__CART__TAKE_AWAY:
    case CustomTextType.ORDER__REGISTER__SUBHEADER:
    case CustomTextType.ORDER__LOGIN__SUBHEADER:
      return ''

    default:
      return ''
  }
}
