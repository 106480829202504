import invariant from 'invariant'

import isNil from 'lodash/isNil'
import isArray from 'lodash/isArray'

/**
 * @param  {StatisticsGroupType} type    the statisticsType for the group
 *
 * @return {[type]}                 the group statistics:
 *                                  {
 *                                    type, // the statisticsType
 *                                    groupe: true,
 *                                    statistics: [
 *                                      // array of createStatistics
 *                                    ],
 *                                  }
 */
const createGroupStatistics = (type, statistics) => {
  invariant(!isNil(type), 'statistics type is required')
  invariant(isArray(statistics), 'statistics must be an array')

  return {
    type,
    group: true,
    statistics,
  }
}

export default createGroupStatistics
