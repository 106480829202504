import { createReducerPerObject } from 'pmt-modules/redux'
import Immutable from 'immutable'
import { createReducer } from 'pmt-modules/redux'

import {
  FetchApiAsyncServiceDataAction,
  CallAsyncApiServiceAction,
  SetAsyncApiServiceResultAction,
} from './actions'
import { AsyncServiceStatus } from './constants'

export const apiAsyncServiceRunnerReducer = createReducerPerObject(
  CallAsyncApiServiceAction,
  action => action.data.actionUUID,
  {}
)

export const apiAsyncServiceResultReducer = createReducer(Immutable.fromJS({}), {
  [SetAsyncApiServiceResultAction]: (state, action) =>
    state.setIn(action.dataStatePath, action.result),
})

export const apiAsyncServiceDataReducer = createReducerPerObject(
  FetchApiAsyncServiceDataAction,
  action => action.data.asyncServiceId,
  {
    //  dataModifier: ,
  },
  {
    override: {
      onRequest: (state, action, getProp) => {
        const currentStatus = state.getIn([getProp(action), 'data', 'status'])
        // ignore requests when we already DONE.
        // /!\ it is a trick in case our container does not work well and infinite loop calls.
        if (
          currentStatus === AsyncServiceStatus.DONE ||
          currentStatus === AsyncServiceStatus.FAILED
        ) {
          return state
        }

        return state.mergeIn([getProp(action)], {
          // data: null,
          isFetching: true,
          error: null,
          actionData: action.data,
        })
      },

      onSuccess: (state, action, getProp) => {
        // only update when we receive for the current request (last waitFor saved).
        // This handles multiple API calls in a row who does not end in the order they were made.
        const currentWaitFor = state.getIn([getProp(action), 'actionData', 'waitFor'])
        if (currentWaitFor && currentWaitFor !== action.data.waitFor) {
          return state
        }

        return state.mergeIn([getProp(action)], {
          data: action.response,
          isFetching: false,
          error: null,
          actionData: action.data,
        })
      },
    },
  }
)
