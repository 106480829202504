const DocumentationPermissions = {
  ACCESS: 'documentation',

  API: 'documentation.apiDocPermissions',

  USER_API: 'documentation.apiDocPermissions.canAccessUserDoc',
  PRO_API: 'documentation.apiDocPermissions.canAccessProDoc',
  THIRD_PARTY_API: 'documentation.apiDocPermissions.canAccessThirdPartyDoc',
  POS_API: 'documentation.apiDocPermissions.canAccessPosDoc',
}

export default DocumentationPermissions
