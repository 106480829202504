import { createApiEnumAction, createApiCallAction } from 'pmt-modules/api/utils'
import EntityHistoryApi from 'pmt-modules/api/calls/EntityHistoryApi'

export const FetchHistoryEntityDetail = createApiEnumAction('HISTORY::ENTITY_REVISION_DETAIL')

export const fetchHistoryEntityDetail = (entityHistoryId, entityType, entityId) =>
  createApiCallAction(FetchHistoryEntityDetail, EntityHistoryApi.getDetail(entityHistoryId), {
    entityHistoryId,
    entityType,
    entityId,
  })

export const FetchHistoryEntityList = createApiEnumAction('HISTORY::ENTITY_REVISIONS_LIST')

//
//
//

export const fetchHistoryEntityList = (entityType, entityId, cursor = '', limit = 20) =>
  createApiCallAction(
    FetchHistoryEntityList,
    EntityHistoryApi.getList(entityType, entityId, cursor, limit),
    {
      entityType,
      entityId,
      cursor,
      limit,
    }
  )

//
//
//

export const FetchHistoryEntityForSession = createApiEnumAction(
  'HISTORY::ENTITY_REVISION_FOR_SESSION'
)

export const fetchHistoryEntityForSession = sessionId =>
  createApiCallAction(FetchHistoryEntityForSession, EntityHistoryApi.getForSession(sessionId), {
    sessionId,
  })
