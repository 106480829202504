import { createSharedListReducer } from 'pmt-modules/redux/sharedList'
import { LoadRestaurantsGroupRolesAction } from './actions'

//
// Retain the roles for a restaurants group
//

export const restaurantsGroupRolesReducer = createSharedListReducer(
  LoadRestaurantsGroupRolesAction,
  action => action.data.restaurantsGroupId,
  {
    pagination: true,
  }
)
