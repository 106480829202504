import { createFormatter } from 'pmt-utils/format'
import isNil from 'lodash/isNil'

const formatRoute = entityCreated => {
  const getRouteForClassName = className => {
    switch (className) {
      case 'com.paymytable.order.order.entity.Order':
        return {
          routeName: 'ORDER_DETAIL',
          params: {
            orderId: entityCreated.entityId,
          },
        }

      case 'com.paymytable.payment.payment.entity.UserCheck': // old path
      case 'com.paymytable.payment.entity.UserCheck': // new and current path
        return {
          routeName: 'PAYMENT_DETAIL',
          params: {
            paymentId: entityCreated.entityId,
          },
        }

      case 'com.paymytable.deposit.model.Deposit':
        return {
          routeName: 'DEPOSIT_DETAIL',
          params: {
            depositId: entityCreated.entityId,
          },
        }

      case 'com.paymytable.payment.entity.Check':
      default:
        return null
    }
  }

  entityCreated.route = getRouteForClassName(entityCreated.className)
  entityCreated.hasRoute = !isNil(entityCreated.route)

  return entityCreated
}

export const formatEntityCreated = createFormatter(formatRoute)
