import { createMuiTheme, dark, light, getLuminance } from 'pmt-ui/styles'
import { responsiveFontSizes } from '@material-ui/core/styles'

import lightBlue from '@material-ui/core/colors/lightBlue'

import blue from 'pmt-ui/colors/blue'
import brown from 'pmt-ui/colors/brown'
import orange from 'pmt-ui/colors/orange'
import pink from 'pmt-ui/colors/pink'
import red from 'pmt-ui/colors/red'
import grey from 'pmt-ui/colors/grey'
import lightGreen from 'pmt-ui/colors/lightGreen'
import yellow from 'pmt-ui/colors/yellow'

import { getQueryParam } from 'pmt-utils/url'

const PMTColors = {
  red400: red[400],
  red600: red[600],
  orange300: orange[300],
  lightGreen400: lightGreen[400],
  lightGreen800: lightGreen[800],
  grey500: grey[500],
  grey50: grey[50],
  white: '#fff',
  lightBlue400: lightBlue[400],

  // non-generic colors (used on statistics for example)
  blue700: blue[700],
  brown400: brown[400],
  pink400: pink[400],
  yellow400: yellow[400],

  middlegrey: '#919ca7',
  lightgrey: '#eceff1', // efeff4

  secondaryGrey: '#4a4a4a',

  backgroundGrey: '#f5f5f5',

  pageTitleColor: '#262626',

  lightGreyBackground: '#f3f3f3',

  black: 'black',
}

const primary = {
  light: '#4f83cc',
  main: '#01579b',
  dark: '#002f6c',
}

const getPMTColor = color => PMTColors[color]

const getPMTColors = () => PMTColors

const muiTheme = createMuiTheme({
  palette: {
    type: getQueryParam('theme', window.location.uri) || 'light', // Switching the dark mode on is a single property value change.
    primary,
    // we use the material default secondary color for now.
    // secondary,
    contrastThreshold: 0.5,
    // https://github.com/callemall/material-ui/issues/8183
    // override getContrastText function to have a white color on green buttons
    // TODO : check the evolution of the issue above
  },
  typography: {
    fontFamily: ['Lato'],
    caption: {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
  overrides: {
    MuiTableCell: {
      head: {
        // since we use Lato font, 500 is not available, so we use 700 here
        fontWeight: 700,
      },
    },
  },
  pmt: {
    colors: getPMTColors(),
    status: {
      error: getPMTColor('red400'),
      warning: getPMTColor('orange300'),
      inactive: getPMTColor('grey500'),
      valid: getPMTColor('lightGreen400'),
      info: getPMTColor('blue700'),
    },
    alert: {
      caption: getPMTColor('grey500'),
      info: getPMTColor('blue700'),
      error: getPMTColor('red400'),
      warning: getPMTColor('orange300'),
      success: getPMTColor('lightGreen400'),
    },
    button: {
      dangerBtnStyle: {
        background: getPMTColor('red400'),
        borderColor: getPMTColor('red400'),
        color: 'white',

        '&:hover': {
          background: getPMTColor('red600'),
          borderColor: getPMTColor('red600'),
        },
      },
    },
    circularProgress: {
      width: 50,
    },
    sidebar: {
      backgroundColor: '#fafafa',
      width: 250,
    },
    navigation: {
      backgroundColor: '#3C3C3C',
      item: {
        color: 'white',
      },
    },
    errorLabel: {
      marginTop: 20,
      color: getPMTColor('red400'),
    },
    separator: {
      400: '#cacaca',
    },
    loading: {
      color: primary.main,
    },
    card: {
      inset: {
        position: 'relative',
        padding: 16,
        borderColor: 'rgba(0, 0, 0, 0.26)',
        transition:
          'padding-left 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,border-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '4px',
      },
    },
    page: {
      stats: {
        card: {
          // card for stats, mostly on the right of the page.
          minWidth: 150,
          minHeight: 60,
          display: 'flex',
          textAlign: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
        },
      },
      proRole: {
        // height of a permission row
        permissionRowHeight: 40,
      },
    },
    pageTitle: {
      lineHeight: `36px`,
    },
    // https://material.io/guidelines/components/buttons-floating-action-button.html#buttons-floating-action-button-floating-action-button
    floatingActionButton: {
      fromLeftEdge: {
        default: 24,
        xs: 16,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24,
      },
      fromBottomEdge: {
        default: 24,
        xs: 16,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24,
      },
    },
    typography: {
      fonts: {
        104: {
          style: {
            fontSize: 10,
            fontWeight: 400,
            margin: 0,
          },
        },
        124: {
          style: {
            fontSize: 12,
            fontWeight: 400,
            margin: 0,
          },
        },
        134: {
          style: {
            fontSize: 13,
            fontWeight: 400,
            margin: 0,
          },
        },
        137: {
          style: {
            fontSize: 13,
            fontWeight: 700,
            margin: 0,
          },
        },
        144: {
          style: {
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '1rem',
            margin: 0,
          },
        },
        147: {
          style: {
            fontSize: 14,
            fontWeight: 700,
            margin: 0,
          },
        },
        164: {
          style: {
            fontSize: 16,
            fontWeight: 400,
            margin: 0,
          },
        },
        167: {
          style: {
            fontSize: 16,
            fontWeight: 700,
            margin: 0,
          },
        },
        184: {
          style: {
            fontSize: 18,
            fontWeight: 400,
          },
        },
        187: {
          style: {
            fontSize: 18,
            fontWeight: 700,
            margin: 0,
          },
        },
        204: {
          style: {
            fontSize: 20,
            fontWeight: 400,
            margin: 0,
          },
        },
        207: {
          style: {
            fontSize: 20,
            fontWeight: 700,
            margin: 0,
          },
        },
        224: {
          style: {
            fontSize: 22,
            fontWeight: 400,
            margin: 0,
          },
        },
        240: {
          style: {
            fontSize: 24,
            fontWeight: 400,
            margin: 0,
          },
        },
        247: {
          style: {
            fontSize: 24,
            fontWeight: 700,
            margin: 0,
          },
        },
      },
    },
    toolbar: {
      dense: {
        // this is applied to the toolbar root element if `variant="dense"`
        // because it's written hard style in material-ui, we define it here
        // thus, we won't use it in our toolbar styles ever, but this is needed
        // if we need to get the value for other purpose.
        minHeight: 48,
      },
    },
  },
})

const theme = {
  muiTheme: responsiveFontSizes(muiTheme),
}

theme.muiTheme.palette.getContrastText = color => {
  if (getLuminance(color) <= theme.muiTheme.palette.contrastThreshold) {
    return dark.text.primary
  } else {
    return light.text.primary
  }
}

export default theme
