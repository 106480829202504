import isArray from 'lodash/isArray'
import createMiddleware from '../../redux/createMiddleware'

import { getRoute, redirectTo } from '../../routing'

import { REDIRECT_TO_PERMISSION_ERROR_PAGE } from './actions'

export const boRedirectToPermissionErrorPage = createMiddleware(
  REDIRECT_TO_PERMISSION_ERROR_PAGE,
  ({ action, dispatch }) => {
    let permissions = action.permissions

    if (!isArray(permissions)) {
      permissions = [permissions]
    }

    // Redirect to the page that will describe the permission error
    dispatch(
      redirectTo(
        getRoute('BO_PERMISSIONS_ERROR'),
        {},
        {
          permissions: permissions.join(','),
          from: window.location.href,
        }
      )
    )
  }
)
