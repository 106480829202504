import Immutable from 'immutable'
import isNil from 'lodash/isNil'

//
// This module handles the information of users cards
// A card contains all the information / statistics / etc about a user
//

import { GetUserCardAction } from './actions'
import { PutUserAction } from 'pmt-modules/user'
import { UpdateUserAccountAction } from 'pmt-modules/userAccount'

export * from './actions'
export * from './selectors'
export * from './components'

// Example of data:
// {
//    [userId]: {
//      userData: {
//        user
//        isFetching: ,
//        error: ,
//        lastUpdated
//      }
//    }
// }
//
// We handle for a user the `userData` that contains the user.
// The goal is to let us add other type of information (example: history / stats / etc) in the
// future.
//

const DEFAULT = Immutable.fromJS({})

const DEFAULT_USER_DATA = {
  user: null,
  isFetching: true,
  lastUpdated: null,
  error: null,
}

export const userCardReducer = (state = DEFAULT, action) => {
  switch (action.type) {
    case GetUserCardAction.REQUEST:
      return state.merge({
        [action.data.userId]: {
          userData: DEFAULT_USER_DATA,
        },
      })

    case GetUserCardAction.SUCCESS:
      return state.mergeIn([action.data.userId, 'userData'], {
        user: action.response,
        isFetching: false,
        lastUpdated: new Date(),
        error: null,
      })

    case PutUserAction.SUCCESS:
      const updateUser = (state, action) => {
        /**
         * userAccounts are not sent back with method PUT, so we maintain them by picking in state
         */
        const userId = String(action.data.userId)
        action.response.userAccounts = state.getIn([userId, 'userData', 'user', 'userAccounts'])
        return state.mergeIn([userId, 'userData'], {
          user: action.response,
          isFetching: false,
          lastUpdated: new Date(),
          error: null,
        })
      }
      return updateUser(state, action)

    case UpdateUserAccountAction.SUCCESS:
      const updateUserAccounts = (state, action) => {
        const userId = String(action.data.userId)
        let userState = state.getIn([userId, 'userData', 'user'])

        if (!userState) {
          // no user retrieveed for this user account, we don't want to have an "empty" user
          // with only user accounts, so we do nothing
          // Otherwise we will have incomplete data on our state
          return state
        }

        // update user account
        let userAccounts = userState.getIn(['userAccounts'])
        if (!isNil(userAccounts)) {
          userAccounts = userAccounts
            .toJS()
            .map(
              (userAccount, index) =>
                userAccount.id === action.response.id ? action.response : userAccount
            )
        } else {
          userAccounts = [action.response]
        }

        userAccounts = userAccounts.map(
          userAccount => (userAccount.id === action.response.id ? action.response : userAccount)
        )

        userState = userState.setIn(['userAccounts'], Immutable.fromJS(userAccounts))

        return state.mergeIn([userId, 'userData'], {
          user: userState,
          isFetching: false,
          lastUpdated: new Date(),
          error: null,
        })
      }
      return updateUserAccounts(state, action)

    case GetUserCardAction.FAILURE:
      return state.mergeIn([action.data.userId, 'userData'], {
        user: null,
        isFetching: false,
        lastUpdated: null,
        error: action.error,
      })

    default:
      return state
  }
}
