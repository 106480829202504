//
//
//
//

let _config = null

export const registerFirebaseConfig = (firebaseConfig) => {
  _config = firebaseConfig
}

export const getFirebaseConfig = () => _config
