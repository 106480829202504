import { ForgotPasswordAction } from './actions'
import { getRoute, replaceWith } from '../routing'

import { createMiddleware } from '../redux'

/**
 * Intercepts ForgotPasswordAction.SUCCESS action and redirects to login screen if the recovery
 *
 * @returns {Function}
 */
const forgotPasswordMiddleware = createMiddleware(
  ForgotPasswordAction.SUCCESS,
  ({ next, action }) => {
    if (action.data.redirectToLogin) {
      return next(replaceWith(getRoute('LOGIN')))
    }
  }
)

export const forgotPasswordMiddlewares = [forgotPasswordMiddleware]
