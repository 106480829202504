import forEach from 'lodash/forEach'
import startsWith from 'lodash/startsWith'
import endsWith from 'lodash/endsWith'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { createFormatter, createListFormatter } from 'pmt-utils/format'
import { formatDate } from 'pmt-utils/date'
import { generateUUID } from 'pmt-utils/uuid'

const logTruncatedRegex = /\.\.\.\(length \d*\)$/

const formatLogAnchorId = log => {
  // unique identifier used for scroll
  log.anchorId = generateUUID()
  return log
}

const formatLogTime = log => {
  log.logTimeFormatted = formatDate(log.logTime, 'DD-MM-YYYY HH:mm:ss.SSS')
  return log
}

const formatLogMessage = log => {
  // remove line like '[s~paymytable/165.434619960848743530].<stdout>: [DEBUG] 10:28:38.254 com.paymytable.common.action.Action'
  log.logMessage = log.logMessage
    // remove PMTLogger lines like '[s~paymytabledev/1.410850343626215818].<stdout>: [INFO] 2018-07-02 13:37:33,375 DefaultLogger'
    .replace(/\[.*DefaultLogger \n/, '')
    // remove log4j line like '[s~paymytable/165.434619960848743530].<stdout>: [DEBUG] 10:28:38.254 com.paymytable.common.action.Action'
    .replace(/\[s~paymytable.*/, '') // us
    .replace(/\[e~paymytable.*/, '') // eu
    .replace(/<continued from previous message>/, '')
    .replace(/<continued in next message>/, '')
    .replace(logTruncatedRegex, '')
    .trim()

  return log
}

const formatAction = log => {
  log.isActionBegin = startsWith(log.logMessage, '---------- Action')

  if (log.isActionBegin) {
    // TODO: better regex, remove arr[3]
    const extractRegexName = /\[name\]: (.*)\n.*/
    const arrName = log.logMessage.match(extractRegexName)

    const extractRegexProps = /\[props\]:\n((.*|\n)*)/
    const arrProps = log.logMessage.match(extractRegexProps)

    log.action = { actionName: arrName && arrName[1], props: arrProps && arrProps[1] }
  }

  log.isActionEnd = startsWith(log.logMessage, '[action end]:')
  if (log.isActionEnd) {
    // TODO: better regex, remove arr[3]
    const extractRegex = /\[action end\]: (.*)/
    // TODO: extract elapsed time
    const arr = log.logMessage.match(extractRegex)

    const extractRegexResult = /\[result\]: ((.*|\n)*)/
    const arrResult = log.logMessage.match(extractRegexResult)

    log.actionEnd = {
      actionName: get(arr, 1, 'unknown'),
      result: arrResult && arrResult[1],
    }
  }

  return log
}

const formatAddTask = log => {
  log.isAddTask = startsWith(log.logMessage, '[TASK] Added task to queue: ')

  if (log.isAddTask) {
    let payload = null
    let arr = null

    log.addTaskData = {}

    if (log.logMessage.includes('Host=')) {
      arr = log.logMessage.match(
        /Host=\[(.*)\]},.*method=(.*), params.*url=(.*), countdownMillis.*\nasync: (.*)\n\[payload\] (.*)/
      )

      if (arr && arr.length >= 6) {
        payload = get(arr, 5, null)
      }

      log.addTaskData = {
        taskName: get(arr, 3, null),
        method: get(arr, 2, null),
        host: get(arr, 1, null),
        async: get(arr, 4, null),
      }
    } else if (log.logMessage.includes('TaskOptions[')) {
      arr = log.logMessage.match(
        /TaskOptions\[.*method=(.*), params.*url=(.*), countdownMillis.*tag=null\]\n.*async: (.*)\n.*payload\] (.*)/
      )

      if (arr && arr.length >= 5) {
        payload = get(arr, 4, null)
      }

      log.addTaskData = {
        taskName: get(arr, 2, null),
        method: get(arr, 1, null),
        host: '',
        async: get(arr, 3, null),
      }
    }

    log.addTaskData.payload = payload

    log.addTaskData.hasPayload =
      log.addTaskData.payload && !startsWith(log.addTaskData.payload, '[bytes]')

    if (!isEmpty(log.addTaskData.taskName)) {
      log.addTaskData.taskName = log.addTaskData.taskName.replace('/worker/tasks/', '')
    }
  }

  return log
}

const formatServiceResponse = log => {
  log.isServiceResponse = startsWith(log.logMessage, 'HTTP RESPONSE:')

  if (log.isServiceResponse) {
    // TODO: optimize regex
    const extractRegex = /HTTP RESPONSE:\n.*{(.*)}\n\n.*\n((.*|\n)*)/
    const arr = log.logMessage.match(extractRegex)
    const content = get(arr, 2, null)
    log.serviceResponseData = {
      headers: get(arr, 1, null),
      content,
      hasContent: !isEmpty(content),
    }
  }

  return log
}

const formatDispatchEvent = log => {
  log.isDispatchEvent = startsWith(log.logMessage, 'Differ dispatch event')

  if (log.isDispatchEvent) {
    // TODO: optimize regex, remove case 3
    const extractRegex = /Differ dispatch event \[(.*)\]($|\n((.*|\n)*))/
    const arr = log.logMessage.match(extractRegex)
    const content = get(arr, 2, '').trim()

    log.dispatchEventData = {
      eventName: get(arr, 1, 'unknown'),
      content,
      hasContent: !isEmpty(content),
    }
  }

  return log
}

const formatJavaException = log => {
  log.isJavaException = startsWith(log.logMessage, 'an exception was thrown')

  if (log.isJavaException) {
    // TODO: optimize regex, remove case 3
    const extractRegex = /an exception was thrown(.*)\n((.*|\n)*)/
    const arr = log.logMessage.match(extractRegex)

    const stacktrace = get(arr, 2, null)

    let stacktraceHead = ''
    let exceptionFilePath = ''
    if (stacktrace) {
      const extractStacktraceHead = /(.*\n){3}/
      const arrStacktraceHead = stacktrace.match(extractStacktraceHead)
      stacktraceHead = arrStacktraceHead[0]

      if (stacktraceHead) {
        const extractExceptionFilePath = /\((.*)\)/
        const arrExceptionFilePath = stacktraceHead.match(extractExceptionFilePath)
        if (arrExceptionFilePath && arrExceptionFilePath.length >= 2) {
          exceptionFilePath = arrExceptionFilePath[1]
        }
      }
    }

    log.javaExceptionData = {
      message: get(arr, 1, log.logMessage),
      stacktrace,
      stacktraceHead,
      exceptionFilePath,
    }
  }

  return log
}

const formatTaskRun = log => {
  log.isTaskRun = startsWith(log.logMessage, 'Task com.paymytable.')
  if (log.isTaskRun) {
    // TODO: better regex, seperate payload and task data
    const extractRegex = /Task (.*)\nStarted by session (.*)\nTaskname=(.*)\nResource: ({(.*|\n)*})/
    const arr = log.logMessage.match(extractRegex)

    // TODO: extract payload

    let resource = get(arr, 4, null)
    if (resource && resource.startsWith('{')) {
      try {
        resource = JSON.parse(resource)
      } catch (_) {}
    }

    log.taskRunData = {
      taskClass: get(arr, 1, 'unknown'),
      runnerSessionId: get(arr, 2, 'unknown'),
      taskName: get(arr, 3, 'unknown'),
      resource,
    }
  }

  return log
}

const formatJson = log => {
  // very simple way but will be mostly correct, and takes almost no resources
  log.hasJson = log.logMessage.indexOf('{') !== -1
  return log
}

const formatContinuity = log => {
  // Big log messaages can be truncated. The rest of the log line is in another log line.
  // Truncate data can be found, since its ends with:
  // ...(length 16452)
  // ...(length 1)
  // ...(length 164522122121)
  // sometimes it seem we only have the <continued in next message> message
  log.hasNextLog =
    logTruncatedRegex.test(log.logMessage) ||
    endsWith(log.logMessage, '<continued in next message>')

  // has previous log if it begin (=== 0) by the following message
  log.hasPreviousLog = log.logMessage.indexOf('<continued from previous message>') === 0
  return log
}

const formatLogInfo = log => {
  // first group (eg. "[e~paymytable-eu-preprod/1.459437160496773219].<stdout>: ") is a non-capturing optional group
  // it will be present for logs generated with java8, and absent for logs generated with java 11
  const regexExtractLogInfo = /(?:\[e~paymytable-eu.*: )?\[(.*)] .* (.*)/
  const arr = log.logMessage.match(regexExtractLogInfo)
  log.logLevel = get(arr, 1, 'no-level')
  log.javaClass = get(arr, 2, 'unknown class')
  // removing all that matches the regex
  // eg. removing "[LEVEL] datetime javaclass" or "[e~paymytable-eu-preprod/1.459437160496773219].<stdout>: [LEVEL] datetime javaclass"
  // eg. removing "[INFO] 06:40:35.879 com.paymytable.logging.LoggingService"
  // so the logMessage will now directly begin by what is after the javaclass, aka the main content of the log
  // eg. "---------- Action" or '[TASK] Added task to queue: ' or '[tag] api-consumer-id 6324468122124288'
  log.logMessage = log.logMessage.replace(arr && arr.length >= 1 ? arr[0] : '', '')
  return log
}

const formatLog = createFormatter(
  formatLogAnchorId,
  formatLogTime,
  formatJson,
  formatContinuity, // must be before formatLogMessage
  formatLogInfo,
  formatLogMessage,
  formatAction,
  formatAddTask,
  formatServiceResponse,
  formatDispatchEvent,
  formatJavaException,
  formatTaskRun
)

const mergeTruncatedLogs = logs => {
  const finalLogs = []

  for (let i = 0; i < logs.length; i++) {
    const log = logs[i]

    if (log.hasNextLog) {
      const mergedLog = { ...log, hasBeenMerged: true }

      let merging = true
      while (merging) {
        i = i + 1
        const nextLog = logs[i]
        mergedLog.logMessage += nextLog.logMessage

        merging = nextLog.hasNextLog
      }
      finalLogs.push(mergedLog)
    } else {
      finalLogs.push(log)
    }
  }

  return finalLogs
}

export const formatLogs = createListFormatter(formatLog, mergeTruncatedLogs)

const groupByRequestId = logs => {
  const res = {}

  logs.forEach(log => {
    if (!res[log.requestId]) {
      res[log.requestId] = [log]
    } else {
      res[log.requestId].push(log)
    }
  })

  const list = []
  forEach(res, (logs, requestId) => {
    list.push({
      requestId,
      logs,
    })
  })

  return list
}

export const formatLogsPerRequestId = createFormatter(formatLogs, groupByRequestId)
