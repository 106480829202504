import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import forEach from 'lodash/forEach'
import { PASSWORD_MIN_LENGTH } from './constants'

//
// Define predicates to validate the ProAccount form
//

const isValidProAccountPasswordRequired = password =>
  !isEmpty(password) && password.length >= PASSWORD_MIN_LENGTH

export const isValidProAccountPassword = (password, data) => {
  // little trick to know if we are in edit mode, there is an id.
  const isEditMode = !isNull(data.id)

  if (data.fakeEmail) {
    if (isEditMode) {
      return isEmpty(password) || isValidProAccountPasswordRequired(password)
    }
    return isValidProAccountPasswordRequired(password)
  }

  return isEmpty(password)
}

export const areValidDomains = domains => {
  if (isEmpty(domains)) {
    return false
  }

  let res = true
  forEach(domains, domain => {
    if (isNull(domain.restaurantsGroupId) || isNull(domain.roleId)) {
      res = false
      return false // quit loop
    }
  })
  return res
}
