import { createApiCallAction, createApiEnumAction } from 'pmt-modules/api/utils'

import FeedbackApi from 'pmt-modules/api/calls/FeedbackApi'

//
//
//

export const FetchFeedbackAnswerResumeForObject = createApiEnumAction(
  'FEEDBACK::ANSWER_RESUME_FOR_OBJECT::GET'
)

export const fetchFeedbackAnswerResumeForObject = (objectType, objectId) =>
  createApiCallAction(
    FetchFeedbackAnswerResumeForObject,
    FeedbackApi.getFeedbackResumeForObject(objectType, objectId),
    {
      objectType,
      objectId,
    }
  )
