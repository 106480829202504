const TableType = {
  USER: 'TABLE::USER',
  COMPANY: 'TABLE::COMPANY',
  COMPANY_USERS: 'TABLE::COMPANY::USERS',
  BILLING_ENTITY: 'TABLE::BILLING_ENTITY',
  USER_ORDERS: 'TABLE::USER_ORDERS',
  USER_TOP_UP: 'TABLE::USER_TOP_UP',

  IMPORT_USER__DATA_ERRORS: 'TABLE::IMPORT_USER::DATA_ERRORS',
  IMPORT_USER__DATA_RESULT: 'TABLE::IMPORT_USER__DATA_RESULT',

  RGROUP_RESTAURANTS_LIST: 'TABLE::RGROUP_RESTAURANTS_LIST',
  RGROUP_LIST: 'TABLE::RGROUP_LIST',

  DOMAIN: 'TABLE::DOMAIN',

  PRO_ROLE: 'TABLE::PRO_ROLE',
}

export default TableType
