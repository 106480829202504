import {
  FetchHistoryEntityDetail,
  FetchHistoryEntityList,
  FetchHistoryEntityForSession,
} from './actions'
import { combineReducers } from 'redux'
import { createSimpleReducer, createPaginateReducer } from '../redux'

export * from './constants'

//
//
//

const entityHistoryDetailReducer = createSimpleReducer(FetchHistoryEntityDetail)

const entityHistoryForSessionReducer = createSimpleReducer(FetchHistoryEntityForSession)

const entityHistoryListsReducer = createPaginateReducer(FetchHistoryEntityList)

export const entityHistoryReducer = combineReducers({
  detail: entityHistoryDetailReducer,
  list: entityHistoryListsReducer,
  session: entityHistoryForSessionReducer,
})
