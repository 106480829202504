import isNil from 'lodash/isNil'
import { createSelector } from 'reselect'

import { createSimpleSelector, getAvailableEntity } from '../redux'
import { formatProRolesList } from './format'

const getProRole = state => state.entities.proRole
const getProRoleIdOnProps = (state, props) => props.proRoleId

export const makeIsFetchingProRole = () =>
  createSelector([getProRole, getProRoleIdOnProps], (proRoleData, proRoleId) => {
    return proRoleData.getIn(['entity', proRoleId, 'isFetching'], false)
  })

export const makeGetFetchProRoleError = () =>
  createSelector([getProRole, getProRoleIdOnProps], (proRoleData, proRoleId) => {
    const errorState = proRoleData.getIn(['entity', proRoleId, 'error'])

    if (isNil(errorState)) {
      return null
    }

    return errorState.toJS()
  })

export const makeGetProRole = () =>
  createSelector([getProRole, getProRoleIdOnProps], (proRole, proRoleIdSelected) => {
    if (isNil(proRole)) {
      return null
    }

    let proRoleEntityState = proRole.get('entity')
    if (!proRoleEntityState) {
      return null
    }
    let proRoleEntity = proRoleEntityState.get(proRoleIdSelected, null)
    if (!proRoleEntity) {
      return null
    }
    const proRoleList = proRole.get('list')
    const entity = getAvailableEntity(proRoleEntity, proRoleList, 'lastUpdated', proRoleIdSelected)

    if (!entity) {
      return null
    }

    return entity
  })

export const isFetchingProRoleList = createSelector([getProRole], proRoleData => {
  return proRoleData.getIn(['list', 'isFetching'])
})

export const getProRoleListError = createSelector([getProRole], proRoleData => {
  const errorState = proRoleData.getIn(['list', 'error'], null)
  return errorState ? errorState.toJS() : null
})

export const getProRoleList = createSelector([getProRole], proRoleData => {
  const proRoleList = proRoleData.getIn(['list', 'data'])
  if (isNil(proRoleList)) {
    return null
  }

  return formatProRolesList(proRoleList.toJS())
})

export const getProRoleListPagination = createSelector([getProRole], proRoleData => {
  if (isNil(proRoleData)) {
    return null
  }

  const paging = proRoleData.getIn(['list', 'paging'])

  if (!paging) {
    return null
  }

  return paging.toJS()
})

//
//
//

const postProRoleSelectors = createSimpleSelector(state => state.entities.postProRole)

export const isFetchingPostProRole = postProRoleSelectors.isFetching
export const getDataPostProRole = postProRoleSelectors.getData
export const getErrorPostProRole = postProRoleSelectors.getError

const putProRoleSelectors = createSimpleSelector(state => state.entities.putProRole)

export const isFetchingPutProRole = putProRoleSelectors.isFetching
export const getDataPutProRole = putProRoleSelectors.getData
export const getErrorPutProRole = putProRoleSelectors.getError
