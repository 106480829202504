import { createSimpleReducer } from 'pmt-modules/redux'
import { combineReducers } from 'redux'
import Immutable from 'immutable'

import {
  FetchApiConsumerConfigurationAction,
  PostApiConsumerAction,
  GetApiConsumerValidationAction,
  PatchApiConsumerConfigurationAction,
} from './actions'

const apiConsumerConfigurationReducer = createSimpleReducer(FetchApiConsumerConfigurationAction, {
  [PatchApiConsumerConfigurationAction.SUCCESS]: (state, action) =>
    state.set('data', Immutable.fromJS(action.response)),
})

const apiConsumerCreationReducer = createSimpleReducer(PostApiConsumerAction)

const apiConsumerPatchReducer = createSimpleReducer(PatchApiConsumerConfigurationAction)

const apiConsumerValidationReducer = createSimpleReducer(GetApiConsumerValidationAction)

export const apiConsumerConfigurationReducers = combineReducers({
  creation: apiConsumerCreationReducer,
  configuration: apiConsumerConfigurationReducer,
  patch: apiConsumerPatchReducer,
  validation: apiConsumerValidationReducer,
})
