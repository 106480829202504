import ApiEndpoints from '../config/ApiEndpoints'
import ProErrors from '../errors/ProErrors'

const ProApi = {
  getProMe: () => {
    return {
      endpoint: ApiEndpoints.PRO_ME,
      type: 'GET',
    }
  },

  getRecoverPassword: email => ({
    type: 'GET',
    query: {
      email,
    },
    headers: {
      authorization: null,
    },
    endpoint: ApiEndpoints.PRO_FORGOT_PASSWORD,
    errorHandler: ProErrors.FORGOT_PASSWORD,
  }),

  getProAccount: proAccountId => ({
    type: 'GET',
    endpoint: ApiEndpoints.PRO_ACCOUNT,
    params: {
      proAccountId,
    },
    query: {
      loadDomains: true,
    },
    // TODO: errorHandler
    // errorHandler:
  }),

  getProAccountList: (rGroupId, cursor, limit) => ({}),

  postProAccount: proAccount => ({
    type: 'POST',
    endpoint: ApiEndpoints.PRO_ACCOUNTS,
    query: {},
    body: proAccount,
    errorHandler: ProErrors.postProAccount,
  }),

  putProAccount: (proAccountId, proAccount) => ({
    type: 'PUT',
    endpoint: ApiEndpoints.PRO_ACCOUNT,
    params: {
      proAccountId,
    },
    query: {},
    body: proAccount,
    errorHandler: ProErrors.putProAccount,
  }),

  getProAccountsList: (restaurantsGroupId, options, cursor, limit) => ({
    endpoint: ApiEndpoints.PRO_ACCOUNTS,
    type: 'GET',
    query: {
      restaurantsGroupId,
      withRestaurantsGroupChildren: options.withRestaurantsGroupChildren,
      withDisabledAccounts: options.withDisabledAccounts,
      withParentsProAccounts: options.withParentsProAccounts,
      loadProRole: true,
      loadProDomains: true,
      cursor: cursor || '',
      limit,
    },
  }),
}

export default ProApi
