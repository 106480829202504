import ProApi from 'pmt-modules/api/calls/ProApi'

import { createApiCallAction, createApiEnumAction } from 'pmt-modules/api/utils'

export const LoadProAccountsListAction = createApiEnumAction('PRO_ACCOUNT::LIST::GET')

/**
 * 
 * @param {*} restaurantsGroupId 
 * @param {object} options {
          withDisabledAccounts,
          withProAccountsFromChildrenGroups,
          withParentsProAccounts,
        }
 * @param {*} cursor 
 * @param {*} limit 
 */
export const loadProAccountsList = (restaurantsGroupId, options = {}, cursor = '', limit = 50) =>
  createApiCallAction(
    LoadProAccountsListAction,
    ProApi.getProAccountsList(
      restaurantsGroupId,
      {
        ...options,
        withRestaurantsGroupChildren: options.withProAccountsFromChildrenGroups,
      },
      cursor,
      limit
    ),
    {
      restaurantsGroupId,
      options,
      cursor,
      limit,
    }
  )
