import login from 'pmt-modules/authPro/forms/login'
import forgotPassword from 'pmt-modules/proForgotPassword/forms/forgotPassword'
import postRestaurant from 'pmt-modules/restaurant/forms/post'
import createUser from 'pmt-modules/user/forms/create'
import editUserAccount from 'pmt-modules/userAccount/forms/edit'
import editUser from 'pmt-modules/user/forms/edit'
import editProAccount from 'pmt-modules/proAccount/forms/edit'
import editProRole from 'pmt-modules/proRole/forms/edit'
import selectEntityHistory from 'pmt-modules/entityHistory/form/select'
import createGroup from 'pmt-modules/restaurantsGroup/forms/create'
import editGeneralInformationsForm from 'pmt-modules/restaurantsGroup/forms/editGeneralInformationsForm'
import editGroup from 'pmt-modules/restaurantsGroup/forms/edit'
import editGroupFrontParameters from 'pmt-modules/restaurantsGroup/forms/editGroupFrontParameters'
import editGroupFinanceParameters from 'pmt-modules/restaurantsGroup/forms/editGroupFinanceParameters'
import editGroupPaymentMethodDetailParameters from 'pmt-modules/restaurantsGroup/forms/editGroupPaymentMethodDetailParameters'
import editGroupUserManagementParameters from 'pmt-modules/restaurantsGroup/forms/editGroupUserManagementParameters'
import scheduledActionFrom from 'pmt-modules/scheduledAction/forms'

import newApiConsumerForm from 'pmt-modules/apiConsumer/configuration/form/newApiConsumerForm'
import apiConsumerAuthenticationForm from 'pmt-modules/apiConsumer/configuration/form/authenticationForm'
import apiConsumerThemeForm from 'pmt-modules/apiConsumer/configuration/form/themeForm'
import apiConsumerAnalyticsForm from 'pmt-modules/apiConsumer/configuration/form/analyticsForm'
import apiConsumerRegistrationForm from 'pmt-modules/apiConsumer/configuration/form/registrationForm'
import apiConsumerIdentificationForm from 'pmt-modules/apiConsumer/configuration/form/identificationForm'
import apiConsumerIncognitoIdentificationForm from 'pmt-modules/apiConsumer/configuration/form/incognitoIdentificationForm'
import apiConsumerFrontForm from 'pmt-modules/apiConsumer/configuration/form/frontForm'
import apiConsumerSecurityForm from 'pmt-modules/apiConsumer/configuration/form/securityForm'
import apiConsumerThirdPartyForm from 'pmt-modules/apiConsumer/configuration/form/thirdPartyForm'
import apiConsumerTextsForm from 'pmt-modules/apiConsumer/configuration/form/textsForm'
import apiConsumerOrderForm from 'pmt-modules/apiConsumer/configuration/form/orderForm'
import apiConsumerDigitalMenuForm from 'pmt-modules/apiConsumer/configuration/form/digitalMenu'
import apiConsumerCatalogForm from 'pmt-modules/apiConsumer/configuration/form/catalog'
import apiConsumerGeneralForm from 'pmt-modules/apiConsumer/configuration/form/generalForm'

const forms = [
  login,
  forgotPassword,
  postRestaurant,
  createUser,
  editUserAccount,
  editUser,
  editProAccount,
  editProRole,
  selectEntityHistory,
  createGroup,

  editGeneralInformationsForm,
  editGroup,
  editGroupFrontParameters,
  editGroupFinanceParameters,
  editGroupPaymentMethodDetailParameters,
  editGroupUserManagementParameters,
  scheduledActionFrom,

  newApiConsumerForm,
  apiConsumerAuthenticationForm,
  apiConsumerThemeForm,
  apiConsumerAnalyticsForm,
  apiConsumerRegistrationForm,
  apiConsumerIdentificationForm,
  apiConsumerIncognitoIdentificationForm,
  apiConsumerFrontForm,
  apiConsumerSecurityForm,
  apiConsumerThirdPartyForm,
  apiConsumerTextsForm,
  apiConsumerOrderForm,
  apiConsumerDigitalMenuForm,
  apiConsumerCatalogForm,
  apiConsumerGeneralForm,
]

export default forms
