import { tr } from 'pmt-modules/i18n'
import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import ui from 'pmt-modules/reduxUi'
import { SketchPicker } from 'react-color'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import set from 'lodash/set'
import isNil from 'lodash/isNil'

import { patchGroup, getLocales } from 'pmt-modules/restaurantsGroup'
import { withForm, FormType } from 'pmt-modules/form'

import ListChooser from 'pmt-ui/ListChooser'
import { CardHeader } from 'pmt-ui/Card'
import TextField from 'pmt-ui/TextField'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'
import LocaleFlag from 'pmt-ui/LocaleFlag'
import TimezoneChooser from 'components/TimezoneChooser'

import { uintColorToHexadecimal, hexadecimalToUintColor } from 'pmt-utils/theme/index'
import { getLocaleLabel } from 'pmt-modules/i18n/utils'

const styles = theme => ({
  cardHeader: {
    padding: 0,
    paddingBottom: theme.spacing(2),
  },
  formContainer: {
    overflow: 'hidden',
  },
  colorContainer: {
    overflow: 'hidden',
    marginTop: theme.spacing(2),
  },
  colorValueContainer: {
    float: 'left',
    padding: theme.spacing(0.5),
    border: `1px solid ${theme.pmt.colors.secondaryGrey}`,
    borderRadius: 2,
    cursor: 'pointer',
  },
  colorLabel: {
    float: 'left',
    marginRight: theme.spacing(2),
    lineHeight: '30px',
  },
  color: {
    float: 'left',
    width: 20,
    height: 20,
  },
  colorHexadecimal: {
    float: 'left',
    marginLeft: theme.spacing(1),
    lineHeight: '20px',
  },
  popover: {
    position: 'absolute',
    zIndex: '2',
  },
  cover: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  flagIcon: {
    width: 24,
    marginRight: theme.spacing(1),
  },
})

class EditGeneralInformationsForm extends React.Component {
  constructor(props) {
    super(props)

    const { hasTheme, theme } = props.restaurantsGroup

    props.updateUI({
      color:
        hasTheme && theme.mainBackgroundColor
          ? uintColorToHexadecimal(theme.mainBackgroundColor)
          : null,
    })
  }

  handleClose = () => {
    this.props.updateUI({ showColorPicker: false })
  }

  handleChangeColor = color => {
    this.props.updateUI({ color })
    this.props.onChange({
      ...this.props.formData,
      theme: {
        ...this.props.formData.theme,
        mainBackgroundColor: hexadecimalToUintColor(color.hex),
      },
    })
  }

  render() {
    const { formData, formErrors, onChange, ui, updateUI, classes } = this.props

    return (
      <React.Fragment>
        <CardHeader className={classes.cardHeader} title={tr('groups.infos.edit.title')} />
        <div className={classes.formContainer}>
          <div className={classes.form}>
            <TextField
              required
              label={tr('groups.infos.name')}
              placeholder={tr('groups.infos.name')}
              value={formData.name || ''}
              onChange={event => onChange({ ...formData, name: event.target.value })}
              error={formData.name !== null && !isEmpty(formErrors.name.message)}
              helperText={formData.name !== null && formErrors.name.message}
              fullWidth
            />

            <div className="u-marginTop15">
              <TypographyCustom type="144" className={classes.colorLabel}>
                {tr('groups.infos.locales')}
              </TypographyCustom>
              <ListChooser
                options={getLocales().map(locale => ({
                  label: (
                    <span>
                      <LocaleFlag
                        className={classes.flagIcon}
                        locale={locale}
                        title={getLocaleLabel(locale)}
                      />
                      {getLocaleLabel(locale)}
                    </span>
                  ),
                  value: locale,
                }))}
                value={get(formData, 'settings.locales', []) || []}
                valueFormatter={locale => (
                  <span>
                    <LocaleFlag
                      className={classes.flagIcon}
                      locale={locale}
                      title={getLocaleLabel(locale)}
                    />
                  </span>
                )}
                multiple
                onChange={values => {
                  onChange(set(formData, 'settings.locales', values))
                }}
              />
            </div>

            <div className="u-marginTop15">
              <TypographyCustom type="144" className={classes.colorLabel}>
                {tr('groups.infos.defaultLocale')}
              </TypographyCustom>
              {!isNil(get(formData, 'settings.locales', [])) ? (
                <ListChooser
                  options={(get(formData, 'settings.locales', []) || []).map(locale => ({
                    label: (
                      <span>
                        <LocaleFlag
                          className={classes.flagIcon}
                          locale={locale}
                          title={getLocaleLabel(locale)}
                        />
                        {getLocaleLabel(locale)}
                      </span>
                    ),
                    value: locale,
                  }))}
                  value={get(formData, 'settings.defaultLocale', null)}
                  valueFormatter={locale => (
                    <span>
                      <LocaleFlag
                        className={classes.flagIcon}
                        locale={locale}
                        title={getLocaleLabel(locale)}
                      />
                    </span>
                  )}
                  onChange={value => {
                    onChange(set(formData, 'settings.defaultLocale', value))
                  }}
                  error={
                    get(formData, 'settings.defaultLocale', null) !== null &&
                    !isEmpty(formErrors.settings.message)
                  }
                />
              ) : (
                tr('global.not_available')
              )}
            </div>

            <div className="u-marginTop15">
              <TypographyCustom type="144" className={classes.colorLabel}>
                {tr('groups.infos.timezone')}
              </TypographyCustom>
              <TimezoneChooser
                value={get(formData, 'settings.timezone', null)}
                onChange={value => {
                  onChange(set(formData, 'settings.timezone', value))
                }}
                error={
                  get(formData, 'settings.timezone', null) !== null &&
                  !isEmpty(formErrors.settings.message)
                }
              />
            </div>

            <div className={classes.colorContainer}>
              <TypographyCustom type="144" className={classes.colorLabel}>
                {tr('groups.infos.theme')}
              </TypographyCustom>
              <div
                className={classes.colorValueContainer}
                onClick={() => updateUI({ showColorPicker: true })}
              >
                <div
                  className={classes.color}
                  style={{
                    backgroundColor: formData.theme
                      ? uintColorToHexadecimal(formData.theme.mainBackgroundColor)
                      : null,
                  }}
                />
                <TypographyCustom type="144" className={classes.colorHexadecimal}>
                  {formData.theme && uintColorToHexadecimal(formData.theme.mainBackgroundColor)}
                </TypographyCustom>
              </div>
            </div>
            {ui.showColorPicker && (
              <div className={classes.popover}>
                <div className={classes.cover} onClick={this.handleClose} />
                <SketchPicker
                  color={ui.color || { hex: null }}
                  onChangeComplete={this.handleChangeColor}
                />
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, props) => ({})

export default withForm(FormType.GROUP_EDIT_GENERAL_INFOS)(
  compose(
    connect(
      mapStateToProps,
      {
        patchGroup,
      }
    ),
    ui({
      state: {
        showColorPicker: false,
        color: null,
      },
    }),
    withStyles(styles)
  )(EditGeneralInformationsForm)
)
