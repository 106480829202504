/**
 * https://github.com/avilaton/excel-column-name/blob/master/index.js
 */
import get from 'lodash/get'

export const getExcelColumnName = number => {
  let colName = '',
    dividend = Math.floor(Math.abs(number)),
    rest

  while (dividend > 0) {
    rest = (dividend - 1) % 26
    colName = String.fromCharCode(65 + rest) + colName
    dividend = parseInt((dividend - rest) / 26, 10)
  }
  return colName
}

export const excelColToInt = colName => {
  let digits = colName.toUpperCase().split(''),
    number = 0

  for (let i = 0; i < digits.length; i++) {
    number += (digits[i].charCodeAt(0) - 64) * Math.pow(26, digits.length - i - 1)
  }

  return number
}

export const convertJsonToCsv = (data, keys) => {
  const replacer = (key, value) => (value === null ? '' : value)

  const header = keys || Object.keys(data[0])

  let csv = data.map(row =>
    header
      .map(
        // prettier-ignore
        fieldName => `${String(JSON.stringify(get(row, fieldName) || '', replacer)).replaceAll('"', '')}`
      )
      .join(',')
  )

  csv.unshift(header.join(','))

  csv = csv.join('\r\n')

  return csv
}
