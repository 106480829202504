import isNil from 'lodash/isNil'
import { existsOnArray } from 'pmt-utils/array'
import { createSelector } from 'reselect'

const getObjectState = (state, objectId) => {
  const groupState = state.get('objects').get(objectId)

  if (isNil(groupState)) {
    return null
  }

  return groupState.toJS()
}

const createObjectSelector = (options, callback) =>
  createSelector([options.getState, options.getObjectId], (state, objectId) => {
    const objectState = getObjectState(state, objectId)

    if (!objectState) {
      return null
    }

    return callback(objectState, state)
  })

/**
 * Generate a simple selector with basic isFetching / getData / getErrors
 * @param  {function} getState  Function to retrieve entity from state
 *                              state => state.entities.ENTITY_NAME
 * @param  {options}
 * - getObjectId function that receive the props and return the object id on it
 * - formatData  Callback function to format data
 * - formatter   list formatter for the list
 * @return {object}             Object with 3 selectors
 *                              isFetching / getData / getErrors
 */
const createSharedListSelectors = options => ({
  makeIsFetching: () => createObjectSelector(options, objectState => objectState.isFetching),

  makeGetData: () =>
    createObjectSelector(options, (objectState, state) => {
      const ids = objectState.ids
      if (!ids) {
        return null
      }

      // retrieve the objects from the list corresponding to the ids
      const list = state
        .get('list')
        .toJS()
        .filter(elem => existsOnArray(ids, id => elem.id === id))

      return options.formatter ? options.formatter(list) : list
    }),

  makeGetError: () => createObjectSelector(options, objectState => objectState.error),

  makeGetPaging: () => createObjectSelector(options, objectState => objectState.paging),
})

export default createSharedListSelectors
