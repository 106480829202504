import { tr } from 'pmt-modules/i18n'
import { notEmpty, isValidEmail } from 'pmt-modules/form/validation'
import { areValidDomains, isValidProAccountPassword } from './predicates'
import createForm from 'pmt-modules/form/createForm'
import FormType from 'pmt-modules/form/FormType'

import { DomainAction } from './constants'
import { SpecificActions, addProDomain } from './actions'
import { formatProAccountForm } from './format'

const createDomain = () => ({
  domainId: null,
  restaurantsGroupId: null,
  roleId: null,
  activated: true,
  canBeModified: true,
  action: DomainAction.ADD,
})

// in DEV environment, prefill form with the following data
const devData = {}

const defaultData = {
  firstName: null,
  lastName: null,
  email: null,
  fakeEmail: null, // must be null, to display selection
  domains: [createDomain()], // add an empty domain on creation
  password: null,
}

const validationRules = () => ({
  firstName: [[notEmpty, tr('pro.edit.validation.first_name')]],
  lastName: [[notEmpty, tr('pro.edit.validation.last_name')]],
  email: [
    [notEmpty, tr('pro.edit.validation.email.empty')],
    [isValidEmail, tr('pro.edit.validation.email.invalid')],
  ],
  domains: [
    [notEmpty, tr('pro.edit.validation.domains.empty')],
    [areValidDomains, tr('pro.edit.validation.domains.invalid')],
  ],
  password: [[isValidProAccountPassword, tr('pro.edit.validation.password.invalid')]],
})

const reducer = (data, action) => {
  switch (action.actionName) {
    case SpecificActions.ADD_PRO_DOMAIN:
      return {
        ...data,
        formData: { ...data.formData, domains: [...(data.formData.domains || []), createDomain()] },
      }

    default:
      return data
  }
}

export default createForm(FormType.PRO_ACCOUNT_EDIT, validationRules, defaultData, devData, {
  reducer,
  customActions: {
    addProDomain,
  },
  modifier: formatProAccountForm,
})
