import { createApiCallAction, createApiEnumAction } from 'pmt-modules/api/utils'

import ApiConsumerApi from 'pmt-modules/api/calls/ApiConsumerApi'

export const FetchApiConsumerConfigurationAction = createApiEnumAction(
  'API_CONSUMER::CONFIGURATION::GET'
)

export const fetchApiConsumerConfiguration = apiConsumerId =>
  createApiCallAction(
    FetchApiConsumerConfigurationAction,
    ApiConsumerApi.getSettings(apiConsumerId)
  )

//
//
//

export const PatchApiConsumerConfigurationAction = createApiEnumAction('API_CONSUMER::PATCH')

export const patchConfiguration = (
  apiConsumerId,
  body,
  currentConfiguration,
  updatedConfiguration
) =>
  createApiCallAction(
    PatchApiConsumerConfigurationAction,
    ApiConsumerApi.patchSettings(apiConsumerId, body),
    {
      apiConsumerId,
      currentConfiguration,
      updatedConfiguration,
      body,
    }
  )

//
//
//

export const PostApiConsumerAction = createApiEnumAction('API_CONSUMER::CONFIGURATION::POST')

export const postApiConsumer = apiConsumer =>
  createApiCallAction(PostApiConsumerAction, ApiConsumerApi.post(apiConsumer))

//
//
//

export const GetApiConsumerValidationAction = createApiEnumAction('API_CONSUMER::VALIDATION::GET')

export const getApiConsumerValidation = apiConsumerId =>
  createApiCallAction(GetApiConsumerValidationAction, ApiConsumerApi.getValidation(apiConsumerId))
