import { createFormatter } from 'pmt-utils/format'
import { formatDate } from 'pmt-utils/date'

const formatFullName = pro => {
  pro.fullName = `${pro.firstName} ${pro.lastName}`
  return pro
}

const formatCreationDate = pro => {
  pro.creationDateFormatted = formatDate(pro.creationDate, 'DD/MM/YY HH:mm')
  return pro
}

export const formatProMe = createFormatter(formatFullName, formatCreationDate)
