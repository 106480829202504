import createForm from 'pmt-modules/form/createForm'
import FormType from 'pmt-modules/form/FormType'

const defaultData = {
  managerId: null,
  baseUrl: null,
  headers: {},
}

const validationRules = () => ({})

export default createForm(
  FormType.GROUP_EDIT_USER_MANAGEMENT_SETTINGS,
  validationRules,
  defaultData
)
