import each from 'lodash/each'
import isNil from 'lodash/isNil'
import invariant from 'invariant'

const findRestaurantsGroupOnGroup = (rGroup, rGroupId) => {
  if (rGroup.id === rGroupId) {
    return rGroup
  }

  let found = null
  each(rGroup.children, children => {
    found = findRestaurantsGroupOnGroup(children, rGroupId)
    if (found !== null) {
      return false // quit loop
    }
  })

  return found
}

export const findRestaurantsGroupOnDomain = (domain, rGroupId) => {
  invariant(!isNil(domain.restaurantsGroup), 'domain not loaded with restaurants groups')

  if (!rGroupId) {
    return null
  }

  // parseInt since rGroupId could be as a string, while the id is a long
  return findRestaurantsGroupOnGroup(domain.restaurantsGroup, parseInt(rGroupId, 10))
}
