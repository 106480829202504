import createForm from 'pmt-modules/form/createForm'
import FormType from 'pmt-modules/form/FormType'

const defaultData = {
  geolocation: null,
  checkAccessSecurity: null,
}

const validationRules = () => ({})

export default createForm(FormType.API_CONSUMER__SECURITY, validationRules, defaultData)
