import createForm from 'pmt-modules/form/createForm'
import FormType from 'pmt-modules/form/FormType'

const defaultData = {
  type: null,
  fields: [],
}

const validationRules = () => ({})

export default createForm(FormType.API_CONSUMER__IDENTIFICATION, validationRules, defaultData)
