import { createAction } from '../redux'

export const TableAction = {
  UPDATE_QUERY: 'TABLE::UPDATE::QUERY',
}

/**
 * tableType - examples : TABLE::USER
 * sortOptions - contains the index to sort on and the order [asc or desc]
 */
export const updateTableQueryAction = (tableType, sortOptions) =>
  createAction(TableAction.UPDATE_QUERY, {
    tableType,
    sortOptions,
  })
