import { createMiddleware } from '../redux'
import { getRoute, replaceWith } from '../routing'

import { PostProAccountAction, PutProAccountAction } from './actions'

/**
 * Intercepts when a pro account is created, and redirect to the pro account list page
 */
export const proAccountPostSuccessMiddleware = createMiddleware(
  PostProAccountAction.SUCCESS,
  ({ dispatch }) => {
    dispatch(replaceWith(getRoute('BO_PRO_ACCOUNT_LIST'), {}))
  }
)

/**
 * Intercepts when a pro account is updated, and redirect to the pro account list page
 */
export const proAccountPutSuccessMiddleware = createMiddleware(
  PutProAccountAction.SUCCESS,
  ({ dispatch }) => {
    dispatch(replaceWith(getRoute('BO_PRO_ACCOUNT_LIST'), {}))
  }
)

export const proAccountMiddlewares = [
  proAccountPostSuccessMiddleware,
  proAccountPutSuccessMiddleware,
]
