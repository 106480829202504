import createForm from 'pmt-modules/form/createForm'
import FormType from 'pmt-modules/form/FormType'

const defaultData = {
  mode: 0, // TODO: enum
  oauth2: {
    clientId: null,
    clientSecret: null,
  },
  appToken: null,
  // appToken: {
  //      Set to true if the Api must require an app token for the requests made with this ApiConsumer
  //    appTokenRequired: false,
  //      Lifetime of an {@link com.paymytable.modules.appToken.AppToken} generated via this ApiConsumer.
  //    appTokenLifetime:
  // }
}

const validationRules = () => ({})

export default createForm(FormType.API_CONSUMER__AUTHENTICATION, validationRules, defaultData)
