/**
 * Enum of different existing forms.
 * The SearchType is used to save / get data on our form reducer
 */
const SearchType = {
  RESTAURANTS_IDS: 'SEARCH::RESTAURANTS_IDS',
  USER: 'SEARCH::USER',
}

export default SearchType
