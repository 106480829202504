import { tr } from 'pmt-modules/i18n'
import map from 'lodash/map'
import invariant from 'invariant'

export const Event = {
  SERVICE_RESPONSE: 'service.response',
  PAYMENT_AT_TABLE_CREATED: 'paymentAtTable.created',
  PAYMENT_AT_TABLE_FAILED: 'paymentAtTable.failed',
  ORDER_CREATED: 'order.created',
  ORDER_UPDATED: 'order.updated',
  ORDER_FAILED: 'order.failed',
  SMS_SENT: 'sms.sent',
  EMAIL_SENT: 'email.sent',
  USER_CREATED: 'user.created',
  USER_UPDATED: 'user.updated',
  THREE_DS_FAILED: 'payment.3ds.failed', // legacy, will be removed
  PSP_ERROR: 'psp.error',
  POS_ERROR: 'pos.error',
  SECURITY_WARNING: 'security.warning',
  API_EXCEPTION: 'api.exception',
  USER_MANAGER_ERROR: 'user_manager.error',
  TASK_CREATE: 'task.create',

  RESTAURANT_ENABLED: 'restaurant.enabled',
  RESTAURANT_DISABLED: 'restaurant.disabled',
  RESTAURANT_ARCHIVED: 'restaurant.archived',

  RESTAURANT_RIB_CHANGED: 'restaurant.rib.changed',
}

export const getEventLabel = eventName => {
  switch (eventName) {
    case Event.ORDER_FAILED:
      return tr('global.event.ORDER_FAILED.enabled.label')

    case Event.RESTAURANT_ENABLED:
      return tr('global.event.RESTAURANT_ENABLED.label')

    case Event.RESTAURANT_DISABLED:
      return tr('global.event.RESTAURANT_DISABLED.label')

    case Event.RESTAURANT_ARCHIVED:
      return tr('global.event.RESTAURANT_ARCHIVED.label')

    case Event.RESTAURANT_RIB_CHANGED:
      return tr('global.event.restaurant.RESTAURANT_RIB_CHANGED.label')

    // for now we use label only for audit and pro notifications. No need to label events that are not
    // on pro notifications
    default:
      return eventName
  }
}

/**
 * Events the user can look for on the audit events page
 */
const eventsToPropose = map(Event, eventName => eventName).filter(
  eventName =>
    [
      // list events that we DO NOT want to propose
      Event.SERVICE_RESPONSE,
      Event.TASK_CREATE,
    ].indexOf(eventName) === -1
)

//
//
//

export const ActorType = {
  PRO: 'PRO',
  USER: 'USER',
  ANONYMOUS: 'ANONYMOUS',
  ADMIN: 'ADMIN',
  RESTAURANT: 'RESTAURANT',
}

export const getActorTypeLabel = type => {
  // no I18n label for now return the type
  return type
}

//
//
//

export const getEventOptions = () =>
  eventsToPropose.map(event => ({
    label: event,
    value: event,
  }))

// Note: the order of the define types will define the order we propose the filters.
export const FilterType = {
  EVENT: 'EVENT',

  // custom front filters
  // restaurant filter will be converted to a search string because the API does not handle it
  RESTAURANT: 'RESTAURANT',

  // custom front filters
  // all errors from 400 to 500
  // Notation: 400..500
  ALL_ERRORS: 'ALL_ERRORS',

  METHOD: 'METHOD',
  ACTOR_TYPE: 'ACTOR_TYPE',
  ACTOR_EMAIL: 'ACTOR_EMAIL',
  API_CONSUMER: 'API_CONSUMER',
  URL: 'URL',
  IP: 'IP',
  FRONT_SESSION_ID: 'FRONT_SESSION_ID',

  // only for event service.response:
  HTTP_CODE: 'HTTP_CODE',

  //
  FROM_TIME: 'FROM_TIME',
  TO_TIME: 'TO_TIME',
}

export const FilterTypeKey = {
  [FilterType.HTTP_CODE]: 'httpCode',
  [FilterType.METHOD]: 'method',
  [FilterType.ACTOR_TYPE]: 'actorType',
  [FilterType.ACTOR_EMAIL]: 'actorEmail',
  [FilterType.API_CONSUMER]: 'apiConsumer',
  [FilterType.URL]: 'url',
  [FilterType.IP]: 'ip',
  [FilterType.EVENT]: 'event',
  [FilterType.FRONT_SESSION_ID]: 'frontSessionId',
}

export const getFiltersWithoutHidePossibility = () => [FilterType.RESTAURANT]

export const getFilterKeyForType = filterType => FilterTypeKey[filterType]

export const getFilterTypeLabel = filterType => {
  switch (filterType) {
    case FilterType.HTTP_CODE:
      return tr('audit.filter.type.HTTP_CODE')
    case FilterType.METHOD:
      return tr('audit.filter.type.METHOD')

    case FilterType.ACTOR_TYPE:
      return tr('audit.filter.type.ACTOR_TYPE')

    case FilterType.ACTOR_EMAIL:
      return tr('audit.filter.type.ACTOR_EMAIL')

    case FilterType.API_CONSUMER:
      return tr('audit.filter.type.API_CONSUMER')

    case FilterType.URL:
      return tr('audit.filter.type.URL')

    case FilterType.IP:
      return tr('audit.filter.type.IP')

    case FilterType.EVENT:
      return tr('audit.filter.type.EVENT')

    case FilterType.FRONT_SESSION_ID:
      return tr('audit.filter.type.FRONT_SESSION_ID')

    case FilterType.RESTAURANT:
      return tr('audit.filter.type.RESTAURANT')

    case FilterType.ALL_ERRORS:
      return tr('audit.filter.type.ALL_ERRORS')

    case FilterType.FROM_TIME:
    case FilterType.TO_TIME:
      return ''

    default:
      invariant(false, `filterType ${filterType} not handled`)
  }
}

export const getFilterTypeOptions = () =>
  map(FilterType, filterType => ({ label: getFilterTypeLabel(filterType), value: filterType }))

export const getMethodOptions = () =>
  map(['POST', 'GET', 'DELETE', 'PATCH', 'PUT'], type => ({
    label: type,
    value: type,
  }))

export const getActorTypeOptions = () => map(ActorType, type => ({ label: type, value: type }))

/**
 * list all possible errors http code for our API,
 */
export const getHttpErrorCodes = () => [400, 401, 402, 403, 404, 405, 500]
