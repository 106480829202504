import Application from 'pmt-modules/archi/Application'

import dataCatcher from './config/dataCatcher'
import devMiddlewares from './config/devMiddlewares'
import middlewares from './config/middlewares'
import entities from './config/entities'
import environment from './config/environment'
import forms from './config/forms'
import searches from './config/searches'
import getApiManagerOptions from './config/getApiManagerOptions'
import theme from './config/theme'
import firebase from './config/firebase'
import analytics from './config/analytics'
import statistics from './config/statistics'
import i18n from './config/i18n'
import routingEnum from './config/routingEnum'

require('pmt-modules/archi/styles/fontLatoStandalone.scss')

const appOptions = {
  dataCatcher,
  devMiddlewares,
  middlewares,
  entities,
  environment,
  forms,
  i18n,
  searches,
  getApiManagerOptions,
  theme,
  firebase,
  analytics,
  statistics,
  routingEnum,
}

Application(appOptions)
