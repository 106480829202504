import React from 'react'

import ToggleContainer from 'pmt-ui/ToggleContainer'
import View from './View'

const JsonDebugModal = ({ children, object, title = 'JSON View', beautify = 'auto' }) => (
  <ToggleContainer>
    {({ toggle, isOpen }) => (
      <React.Fragment>
        {children({ onOpen: toggle, isOpen })}

        {isOpen && <View title={title} json={object} onClose={toggle} beautify={beautify} />}
      </React.Fragment>
    )}
  </ToggleContainer>
)

export default JsonDebugModal
