import ApiEndpoints from '../config/ApiEndpoints'

const FirebaseApi = {
  auth: () => ({
    endpoint: ApiEndpoints.FIREBASE_AUTH,
    type: 'POST',
    params: {},
    query: {},
  }),
}

export default FirebaseApi
