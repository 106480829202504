import Immutable from 'immutable'
import concat from 'lodash/concat'
import uniqWith from 'lodash/uniqWith'

import {
  FetchUserOrdersListAction,
} from './actions'

export * from './components'
export * from './actions'
export * from '../order/format'
export * from './selectors'


const DEFAULT = Immutable.fromJS({
  list: {
    data: null,
    isFetching: false,
    paging: null,
    error: null,
  },
})

export const userOrdersReducer = (state = DEFAULT, action) => {
  switch (action.type) {
    case FetchUserOrdersListAction.REQUEST:
      return state.mergeIn(
        ['list'], {
          isFetching: true,
          lastUpdated: null,
          error: null,
        }
      )

    case FetchUserOrdersListAction.SUCCESS:
      let newList = [];

      /**
       * A null before cursor means we ran the action to load the first
       * data page. In this case, we don't merge the data,
       * we reset it.
       */
      if (action.response.paging.cursors.before === null) {
        newList = action.response.data
      } else {
        let currentList = state.getIn(['list', 'data'])

        if (typeof currentList !== 'undefined') {
          currentList = currentList.valueSeq().toArray().map((item) => {
            return item.toJS()
          })
        } else {
          currentList = []
        }

        newList = concat(currentList, action.response.data);
        newList = uniqWith(newList, (a, b) => a.id === b.id)
      }

      return state.merge({
        list: {
          isFetching: false,
          lastUpdated: new Date(),
          error: null,
        }
      })
      .mergeIn(['list', 'pagination'], action.response.paging)
      .setIn(['list', 'data'], Immutable.fromJS(newList))

    case FetchUserOrdersListAction.FAILURE:
      return state.merge({
        list: {
          data: null,
          isFetching: false,
          lastUpdated: new Date(),
          error: action.error,
        }
      })

    default:
      return state
  }
}
