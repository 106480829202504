import React from 'react'

import PropTypes from 'prop-types'
// import { findDOMNode } from 'react-dom'

import isNull from 'lodash/isNull'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import invariant from 'invariant'

import { toggleArrayValue } from 'pmt-utils/array'

import Content from './Content'
import ListChooserType from './ListChooserType'
import ValueView from './components/ValueView'
import EmptyValue from './components/EmptyValue'

class ListChooser extends React.Component {
  constructor(props) {
    super(props)

    // ref for our root object
    this.root = null

    this.state = {
      open: false,
    }
  }

  handleSelect = value => {
    if (this.props.disabled) {
      return
    }

    if (!this.props.multiple) {
      this.props.onChange(value)
      this.handleRequestClose()
    } else {
      this.props.onChange(toggleArrayValue(this.props.value, value))
    }
  }

  handleClickOpen = () => {
    if (this.props.disabled) {
      return
    }

    this.setState({
      open: true,
    })
  }

  handleRequestClose = () => {
    this.setState({
      open: false,
    })
  }

  handleDeleteValue = (valueToDelete = null) => {
    const values = this.props.value

    if (this.props.multiple) {
      const newValues = values.filter(value => value !== valueToDelete)

      this.props.onChange(newValues)
    } else {
      // not multiple mode, erase the value
      this.props.onChange(null)
    }
  }

  render() {
    const {
      className,
      label,
      labelButton,
      value,
      children,
      title,
      options,
      // onChange,
      // error, // TODO: handle
      helperText,
      type,
      valueIconFormatter,
      valueFormatter,
      valueFormatterHandleNullValue,
      multiple,
      required,
      disableFiltering,
      isFetching,
      comparator,
      clearable,
      disableSearch,
      disabled,
      paging,
      onLoadMore,
      viewType,
      alwaysDisplayAddButton,
      filteringWithContains,
    } = this.props

    invariant(
      !multiple || !isNull(valueFormatter),
      `[ListChooser] '${label}' valueFormatter required for multiple mode`
    )
    invariant(
      !multiple || isArray(value),
      `[ListChooser] '${label}' value '${value}' must be an array for multiple mode`
    )

    return (
      <div className={className} ref={ref => (this.root = ref)}>
        {children ? (
          children({
            onOpen: this.handleClickOpen,
          })
        ) : (
          <ValueView
            disabled={disabled}
            multiple={multiple}
            label={label}
            value={value}
            helperText={helperText}
            isFetching={isFetching}
            valueIconFormatter={valueIconFormatter}
            valueFormatter={valueFormatter}
            valueFormatterHandleNullValue={valueFormatterHandleNullValue}
            onDeleteValue={this.handleDeleteValue}
            required={required}
            clearable={clearable}
            onOpen={this.handleClickOpen}
            emptyRender={
              <EmptyValue
                show={multiple && isEmpty(value)}
                label={labelButton}
                onClickOpen={this.handleClickOpen}
                disabled={disabled}
              />
            }
            alwaysDisplayAddButton={alwaysDisplayAddButton}
          />
        )}

        <Content
          type={type}
          //anchorEl={findDOMNode(this.root)}
          anchorEl={this.root}
          open={this.state.open}
          title={title}
          value={value}
          options={(options || []).filter(Boolean)}
          onQueryChange={this.props.onQueryChange}
          onClose={this.handleRequestClose}
          onSelect={this.handleSelect}
          disableFiltering={disableFiltering}
          filteringWithContains={filteringWithContains}
          isFetching={isFetching}
          comparator={comparator}
          disableSearch={disableSearch}
          paging={paging}
          onLoadMore={onLoadMore}
          multiple={multiple}
          viewType={viewType}
        />
      </div>
    )
  }
}

ListChooser.defaultProps = {
  type: null,

  multiple: false,

  valueFormatter: null,

  valueFormatterHandleNullValue: false,

  onQueryChange: null,

  required: false,

  disableFiltering: false,

  isFetching: false,

  value: null,

  clearable: false,

  disableSearch: false,

  filteringWithContains: false,

  viewType: null,

  alwaysDisplayAddButton: false,
}

ListChooser.propTypes = {
  /**
   * Force the type of the list chooser.
   * If not specified we display as popover on desktop, and as dialog on smaller screens.
   */
  type: PropTypes.oneOf([ListChooserType.POPOVER, ListChooserType.DIALOG]),

  /**
   * Array of option object:
   * {
   *    label: '',
   *    value: ...,
   * }
   */
  options: PropTypes.array,

  /**
   * Label to display on the default children view
   */
  label: PropTypes.string,

  helperText: PropTypes.any,

  /**
   * @optionnal
   * Name of the list selection view (dialog / popover)
   */
  title: PropTypes.string,

  onChange: PropTypes.func.isRequired,

  multiple: PropTypes.bool,

  /**
   * true to display a loader.
   * Used when the options are loaded on the api via the query.
   */
  isFetching: PropTypes.bool,

  /**
   * Disable filtering of the list using the query state
   * Set to true when the query / options filtering are handled by the parent.
   */
  disableFiltering: PropTypes.bool,

  /**
   * Switch the filtering mode to "contains".
   * By default, false. So the filtering is done with fuse.js that makes an approximative search
   */
  filteringWithContains: PropTypes.bool,

  /**
   * One or multiple selected values
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.object,
  ]),

  /**
   * @optionnal
   *
   * View to display for the value
   * Must be a function, that will receive:
   * - onOpen -> function to call to open the list view
   *
   * if not specified, we display:
   * - a TextField is there is only one value possible
   * - a list of chips (https://material-ui-1dab0.firebaseapp.com/demos/chips/) if there is multiple
   *   values possible
   */
  children: PropTypes.func,

  /**
   * @optionnal if not in `multiple` mode
   * formatted value to display on the default children view
   */
  valueFormatter: PropTypes.func,

  /**
   * By legacy, null value will not call the valueFormatter. Set this to true to force it.
   */
  valueFormatterHandleNullValue: PropTypes.bool,

  /**
   * @optionnal
   *
   * function to compare two options objects.
   * Note that we handle comparaison between two objects that have an 'id' key.
   * Used to compare the value and the option.
   */
  comparator: PropTypes.func,

  /**
   * Function called when the input query changed.
   * Used when the query has to be known from the parent (ex: SearchListChooser)
   */
  onQueryChange: PropTypes.func,

  /**
   * If not required, a single choice can be removed, the onChange function will receive null.
   */
  required: PropTypes.bool,

  clearable: PropTypes.bool,

  /**
   * Disable search
   * Set to true if you don't want to display search option.
   */
  disableSearch: PropTypes.bool,

  /**
   * Disable possibility to choose on a list
   */
  disabled: PropTypes.bool,

  /**
   * In case the options could be paginated, we can pass a paging object and a `onLoadMore`
   * prop
   */
  paging: PropTypes.object,

  /**
   * func (nextCursor)
   *
   * TODO: when we load more, the list will increase in size, but since we don't close / open
   * the view, the Popover will not be resized.
   * Note: it could be resolved by the material-ui v1 update
   */
  onLoadMore: PropTypes.func,

  /**
   * Allows to define the type of view to display on the Body
   * see ViewType
   */
  viewType: PropTypes.string,

  /**
   * in 'multiple' mode, indicates that we want the "add" button to be always displayed (default: false => displayed only when no values are selected)
   */
  alwaysDisplayAddButton: PropTypes.bool,
}

export default ListChooser
