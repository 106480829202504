import moment from 'moment'
import forEach from 'lodash/forEach'
import keys from 'lodash/keys'
import isNil from 'lodash/isNil'

import { createFormatter, createListFormatter } from 'pmt-utils/format'
import { nbWeekdaysBetween, fromMs } from 'pmt-utils/date'
import { convertMillionPrice, formatMillionPriceWithCurrency } from 'pmt-utils/currency'
import { getPaymentMethodLabel } from 'pmt-modules/paymentMethods'
import { getIdentificationMethodLabel } from 'pmt-modules/identificationMethods'
import { getSalesFilter, getSalesFilterKeys } from 'pmt-modules/statistics'
import { getOrderModeLabel, getOrderModeStr } from '../constants'

const formatPrices = row => {
  row.turnoverTTCFormatted = formatMillionPriceWithCurrency(row.turnoverTTC)
  row.averageTTCFormatted = formatMillionPriceWithCurrency(row.averageTTC)
  row.turnoverTTC = convertMillionPrice(row.turnoverTTC)
  row.averageTTC = convertMillionPrice(row.averageTTC)
  return row
}

//
// Key stats
//

const transformToObject = stats => (stats ? stats[0] : null)

export const formatKeyStats = createFormatter(
  createListFormatter(createFormatter(formatPrices)),
  transformToObject
)

//
//
//

const formatOrderPeriodStatsRow = row => {
  // /!\ date/hour received is for UTC

  // get timestamp for utc
  const utcTimestamp = moment.utc(row.hour).valueOf()

  // convert date with client current locale
  row.hour = fromMs(utcTimestamp)
  return row
}

const formatDate = (hourMoment, format, graphLineFilter) => {
  if (graphLineFilter === getSalesFilterKeys().WEEK) {
    return moment(hourMoment.format('YYYY'))
      .add(hourMoment.isoWeek() - 1, 'weeks')
      .add(1, 'day')
      .format('DD/MM/YYYY')
  }
  return moment(hourMoment).format(format)
}

/**
 * Format statistics specifically for WeekdayGraph component
 */
const formatOrderStatisticsGraphByWeekday = (rows, options) => {
  let dataByWeekday = {
    '1': {},
    '2': {},
    '3': {},
    '4': {},
    '5': {},
    '6': {},
    '7': {},
    average: {},
  }

  const { fromTime, toTime } = options

  const formatHour = date => `1970-01-01 ${date.format('HH:mm')}`

  const formatForWeekday = m => weekday => {
    dataByWeekday[weekday][formatHour(m)] = 0
  }

  let hourDate = moment(0)
  for (let hourIndex = 0; hourIndex < 24; hourIndex++) {
    keys(dataByWeekday).forEach(formatForWeekday(hourDate))

    hourDate = hourDate.add(1, 'hour')
  }

  forEach(rows, row => {
    const hourMoment = moment(row.hour, 'DD/MM/YYYY HH:mm')
    // fill turnover by period by weekday
    const period = formatHour(hourMoment)
    const weekday = hourMoment.isoWeekday()
    dataByWeekday[weekday][period] += row.turnoverTTC
  })

  // calculate number of each day on timerange
  let nbDays = []
  for (let i = 1; i <= 7; i++) {
    nbDays.push(nbWeekdaysBetween(fromTime, toTime, i))
  }

  // calculate number of total days
  const nbTotalDays = nbDays.reduce((a, b) => a + b)

  const graphDataByWeekday = keys(dataByWeekday).map(weekday => {
    let data = {}

    forEach(dataByWeekday[weekday], (value, moment) => {
      switch (weekday) {
        // format average data for every days at this moment
        case 'average':
          let sumOfDays = 0
          for (let i = 1; i <= 7; i++) {
            sumOfDays += dataByWeekday[i][moment]
          }
          data[moment] = sumOfDays / nbTotalDays
          break

        // otherwise, format data for a specific day at this moment
        default:
          data[moment] = parseFloat(
            nbDays[weekday - 1] > 0 ? dataByWeekday[weekday][moment] / nbDays[weekday - 1] : 0
          )
      }
    })

    return data
  })

  return graphDataByWeekday
}

const formatToBigData = (rows, options) => {
  const { graphLineFilter, fromTime, toTime } = options

  const format = getSalesFilter()[graphLineFilter].filter

  let graphDataByFilterView = {}
  forEach(rows, row => {
    const hourMoment = moment(row.hour, 'DD/MM/YYYY HH:mm')

    // generate date string according to the filter used
    let date = formatDate(hourMoment, format, graphLineFilter)

    // init data
    let dateData = graphDataByFilterView[date]
    if (isNil(dateData)) {
      dateData = {
        averageTTC: 0,
        turnoverTTC: 0,
        nbOrders: 0,
      }
      graphDataByFilterView[date] = dateData
    }

    dateData.turnoverTTC += row.turnoverTTC
    dateData.nbOrders += row.nbOrders
    dateData.averageTTC = dateData.turnoverTTC / dateData.nbOrders
  })

  const graphData = keys(graphDataByFilterView).map(date => {
    return {
      date,
      ...graphDataByFilterView[date],
    }
  })

  const salesGraphData = {
    labels: [],
    turnoverTTC: [],
    averageTTC: [],
  }

  let hour = moment(fromTime)
  for (let i = 0; i < graphData.length; i++) {
    // fill graph with empty data for date from `fromTime` to next date received
    const data = graphData[i]
    // hour.format(format) !== data.date
    const dataDate = moment(data.date, 'DD/MM/YYYY')
    if (dataDate.isAfter(hour)) {
      while (!hour.isSame(dataDate, graphLineFilter)) {
        salesGraphData.labels.push(formatDate(hour, format, graphLineFilter))
        salesGraphData.turnoverTTC.push(0)
        salesGraphData.averageTTC.push(0)
        hour = hour.add(1, graphLineFilter)
      }
    }

    // fill graph with data received
    salesGraphData.labels.push(data.date)
    salesGraphData.turnoverTTC.push(data.turnoverTTC)
    salesGraphData.averageTTC.push(data.averageTTC)

    hour = hour.add(1, graphLineFilter)
  }

  // // fill graph with empty data for every date until `toTime` is reached
  while (hour.diff(moment(toTime)) < 0) {
    salesGraphData.labels.push(formatDate(hour, format, graphLineFilter))
    salesGraphData.turnoverTTC.push(0)
    salesGraphData.averageTTC.push(0)

    hour = hour.add(1, graphLineFilter)
  }

  //
  //
  //

  return {
    rows,
    graphData,
    salesGraphData,
    graphDataByWeekday: formatOrderStatisticsGraphByWeekday(rows, options),
  }
}

export const formatOrderPeriodStats = createFormatter(
  createListFormatter(createFormatter(formatPrices, formatOrderPeriodStatsRow)),
  formatToBigData
)

//
// Mode
//

const formatOrdersStatsModeRow = row => {
  row.modeLabel = getOrderModeLabel(row.mode)
  row.modeStr = getOrderModeStr(row.mode)
  return row
}

export const formatOrderStatsMode = createListFormatter(
  createFormatter(formatOrdersStatsModeRow, formatPrices)
)

//
// Source
//

export const formatOrderSourcesStats = createListFormatter(createFormatter(formatPrices))

//
// Payment methods
//

const formatOrdersStatsPaymentMethodsRow = row => {
  row.paymentMethodLabel = getPaymentMethodLabel(row.paymentMethod)
  row.paymentMethodTurnoverTTCFormatted = formatMillionPriceWithCurrency(
    row.paymentMethodTurnoverTTC
  )
  row.paymentMethodAverageTTCFormatted = formatMillionPriceWithCurrency(row.paymentMethodAverageTTC)

  return row
}

export const formatOrderPaymentMethods = createListFormatter(
  createFormatter(formatOrdersStatsPaymentMethodsRow, formatPrices)
)

//
// IdentificationMethods
//

const formatOrdersStatsIdentificationMethods = row => {
  row.identificationMethodLabel = getIdentificationMethodLabel(row.identificationMethod)
  return row
}

export const formatOrderIdentificationMethods = createListFormatter(
  createFormatter(formatPrices, formatOrdersStatsIdentificationMethods)
)

//
//
//

export const formatOrderRestaurants = createListFormatter(createFormatter(formatPrices))
