import { tr } from 'pmt-modules/i18n'
import React from 'react'

import { CopyToClipboard } from 'pmt-ui/react-copy-to-clipboard'
import { SnackbarContainer } from 'pmt-ui/Snackbar'

const CopyToClipboardWithNotif = ({ text, children }) => (
  <SnackbarContainer message={tr('global.copied')}>
    {({ onShow }) => (
      <CopyToClipboard text={text} onCopy={onShow}>
        {children}
      </CopyToClipboard>
    )}
  </SnackbarContainer>
)

export default CopyToClipboardWithNotif
