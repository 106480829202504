import ApiEndpoints from '../config/ApiEndpoints'

const RolesApi = {
  getForRestaurantsGroup: (restaurantsGroupId, cursor, limit) => {
    return {
      type: 'GET',
      query: {
        restaurantsGroupId,
        // handle null cursor
        cursor: cursor || '',
        limit,
      },
      endpoint: ApiEndpoints.PRO_ROLES,
      // There is no error to handle for now.
      // errorHandler:
    }
  },
}

export default RolesApi
