import React from 'react'
import { connect } from 'react-redux'

import { getFlashMessages } from './'

/**
 * Container to retrieve the current flash messages to display.
 */
class FlashMessagesContainer extends React.PureComponent {
  render() {
    const { flashMessages } = this.props

    return this.props.children({
      flashMessages,
    })
  }
}

FlashMessagesContainer.propTypes = {}

const mapStateToProps = state => ({
  flashMessages: getFlashMessages(state),
})

export default connect(
  mapStateToProps,
  {}
)(FlashMessagesContainer)
