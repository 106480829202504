

/**
 * The user update is divide in different parts, defined here
 */
export const UpdateUserFormPart = {
  // Used to reset
  NONE: 'UpdateUserForm::NONE',
  GENERAL: 'UpdateUserForm::GENERAL',
  DIETARY_PREFERENCES: 'UpdateUserForm::DIETARY_PREFERENCES',
  USER_ACCOUNT: 'UpdateUserForm::USER_ACCOUNT',
}
