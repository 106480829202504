import { AuthProAction } from 'pmt-modules/authPro'
import { ForgotPasswordAction } from 'pmt-modules/proForgotPassword'

import { DataCatcherType } from 'pmt-modules/dataCatcher'

export default [
  //
  // Catch user email
  // It allow us to update the forms with the user email (login form / recovery password form)
  //
  {
    actionType: AuthProAction.REQUEST,
    getObject: action => action.data.username,
    dataCatcherType: DataCatcherType.PRO_FORM_EMAIL,
  },
  {
    actionType: ForgotPasswordAction.REQUEST,
    getObject: action => action.data.username,
    dataCatcherType: DataCatcherType.PRO_FORM_EMAIL,
  },
]
