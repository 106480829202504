import React from 'react'
import isNil from 'lodash/isNil'
import isUndefined from 'lodash/isUndefined'

import invariant from 'invariant'

import { tr } from 'pmt-modules/i18n'

import { TableRow, TableCell } from 'pmt-ui/Table'

import Button, { ButtonLoading } from 'pmt-ui/Button'

const PaginationView = ({ classes, table, onLoadMore, isFetching }) => (
  <TableRow>
    <TableCell
      className={classes.tableCellFooter}
      colSpan={table?.tableHead?.columns?.length || 100}
    >
      <ButtonLoading
        component={<Button size="small" />}
        size={20}
        isLoading={isFetching}
        variant="outlined"
        color="primary"
        onClick={onLoadMore}
      >
        {tr('global.load_more')}
      </ButtonLoading>
    </TableCell>
  </TableRow>
)

const Pagination = ({ pagination, classes, table, isFetchingDatas, onLoadMore }) => {
  if (!pagination) {
    return null
  }

  const View = (
    <PaginationView
      classes={classes}
      table={table}
      isFetching={isFetchingDatas}
      onLoadMore={onLoadMore}
    />
  )

  if (pagination.cursors) {
    if (isNil(pagination.cursors.after)) {
      return null
    }

    return View
  }

  if (!isUndefined(pagination.nextFrom)) {
    if (pagination.isLastPage) {
      return null
    }

    return View
  }

  if (!isNil(pagination.limit)) {
    if (isNil(pagination.next)) {
      return null
    }

    return View
  }

  if (!isNil(pagination.page)) {
    if (pagination.page >= pagination.totalPages) {
      return null
    }

    return View
  }

  invariant(false, 'Invalid type of pagination')
}

export default Pagination
