import createForm from 'pmt-modules/form/createForm'
import FormType from 'pmt-modules/form/FormType'
import { notEmpty } from 'pmt-modules/form/validation'

const defaultData = {
  name: null,
  description: null,
  status: null,
}

const validationRules = () => ({
  name: [[notEmpty, 'Nom requis']],
  description: [[notEmpty, 'Description requise']],
  status: [[notEmpty, 'Status requis']],
})

export default createForm(FormType.API_CONSUMER__GENERAL, validationRules, defaultData)
