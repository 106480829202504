import { createApiCallAction, createApiEnumAction } from 'pmt-modules/api/utils'
import OrderApi from 'pmt-modules/api/calls/OrderApi'

export const GetOrderDetailAction = createApiEnumAction('ORDER::GET')

export const fetchOrder = orderId =>
  createApiCallAction(GetOrderDetailAction, OrderApi.getDetail(orderId), {
    orderId,
  })

export const PutOrderDetailAction = createApiEnumAction('ORDER::PUT')

export const putOrder = order =>
  createApiCallAction(PutOrderDetailAction, OrderApi.putOrder(order), {
    order,
  })
