import ApiEndpoints from '../config/ApiEndpoints'

const ApiAsyncServiceApi = {
  get: asyncServiceId => {
    return {
      endpoint: ApiEndpoints.API_ASYNC_SERVICE_DATA,
      type: 'GET',
      params: {
        asyncServiceId,
      },
    }
  },
}

export default ApiAsyncServiceApi
