import { createFormatter, createListFormatter, createSubObjectFormatter } from 'pmt-utils/format'

import formatImporterData from './formatImporterData'
import formatRowInformation from './formatRowInformation'
import formatErrorDescription from './formatErrorDescription'

const formatDataError = createFormatter(
  createSubObjectFormatter('rowInformation', formatRowInformation),
  createSubObjectFormatter('data', formatImporterData),
  createSubObjectFormatter('errorDescription', formatErrorDescription),
)

const formatDataErrors = createListFormatter(formatDataError)

export default formatDataErrors
