//
// https://github.com/prescottprue/react-redux-firebase/
//
// Warning: we use the v2, be carefull with the version of the doc:
// http://docs.react-redux-firebase.com/history/v2.0.0/docs/recipes/upload.html
//

const rrfConfig = {
  // http://docs.react-redux-firebase.com/history/v1.5.0/docs/recipes/upload.html
  fileMetadataFactory: uploadRes => {
    // upload response from Firebase's storage upload
    const {
      metadata: { name, fullPath },
    } = uploadRes
    // default factory includes name, fullPath, downloadURL
    return {
      name,
      fullPath,
    }
  },
}

export default {
  // for key we use the `ENV` from our config
  DEV: {
    // Firebase config
    credentials: {
      apiKey: 'AIzaSyCMaKgh728b7sdEr3-IVdz1dvg3G2e0DZ8',
      authDomain: 'paymytabledev.firebaseapp.com',
      databaseURL: 'https://paymytabledev.firebaseio.com',
      projectId: 'paymytabledev',
      storageBucket: 'paymytabledev-firebase-com',
      messagingSenderId: '679790149318',
    },
    //
    rrfConfig,
  },
  PREPROD: {
    // Firebase config
    credentials: {
      apiKey: 'AIzaSyA9op6DM3NoXxvirxtgbxr7HkJazN97K-o',
      authDomain: 'paymytable-eu-preprod.firebaseapp.com',
      projectId: 'paymytable-eu-preprod',
      storageBucket: 'paymytable-eu-preprod-firebase-com',
      messagingSenderId: '517015730789',
      appId: '1:517015730789:web:f130cb8f7023633054a48c',
      databaseURL: 'paymytable-eu-preprod-default-rtdb.europe-west1.firebasedatabase.app',
    },
    //
    rrfConfig,
  },
  PROD: {
    // Firebase config
    credentials: {
      apiKey: 'AIzaSyC0GlY9SiWH-zDZL4AHDZsbZXIp2SNqIIw',
      authDomain: 'paymytable-eu.firebaseapp.com',
      databaseURL: 'https://paymytable-eu-default-rtdb.europe-west1.firebasedatabase.app',
      projectId: 'paymytable-eu',
      storageBucket: 'paymytable-eu-firebase-com',
      messagingSenderId: '177135918636',
      appId: '1:177135918636:web:807ac0e2a3deb3f9f7bb89',
    },
    //
    rrfConfig,
  },
}
