import ApiEndpoints from '../config/ApiEndpoints'
import { getOrderModeFromStr } from 'pmt-modules/order'

const createOrderStats = type => props => ({
  endpoint: ApiEndpoints.STATISTICS_ORDER,
  type: 'POST',
  params: {
    restaurantsGroupId: props.options.rGroupId,
  },
  body: {
    type,
    restaurantsGroupId: props.options.rGroupId,
    fromTime: props.options.fromTime.valueOf(),
    toTime: props.options.toTime.valueOf(),

    // handle api compatibility
    modes: props.options.modes.map(mode => getOrderModeFromStr(mode)) || [],
    restaurantsIds: props.options.restaurantsIds || [],
    sourcesList: props.options.sources || [],
    paymentMethods: props.options.paymentMethods || [],
    identificationMethods: props.options.identificationMethods || [],
    hasGrantAmount:
      props.options.hasGrantAmount === null
        ? null
        : props.options.hasGrantAmount === '0' ? false : true,
  },
})

const StatisticsApi = {
  getOrderStatsKeyStats: createOrderStats('KEY_STATS'),

  getOrdersStatsPeriod: createOrderStats('PERIOD'),

  getOrderStatsMode: createOrderStats('MODES'),

  getOrderStatsSource: createOrderStats('SOURCES'),

  getOrderStatsPaymentMethods: createOrderStats('PAYMENT_METHODS'),

  getOrderStatsIdentificationMethods: createOrderStats('IDENTIFICATION_METHODS'),

  getOrderStatsRestaurants: createOrderStats('RESTAURANTS'),

  //
  //
  //

  getTopUpStats: props => ({
    endpoint: ApiEndpoints.STATISTICS_DEPOSIT,
    type: 'POST',
    params: {
      restaurantsGroupId: props.options.rGroupId,
    },
    body: {
      fromTime: props.options.fromTime.valueOf(),
      toTime: props.options.toTime.valueOf(),
    },
  }),

  getBalanceForDay: props => ({
    endpoint: ApiEndpoints.STATISTICS_BALANCE_FOR_DAY,
    type: 'POST',
    params: {
      restaurantsGroupId: props.options.rGroupId,
    },
    body: {
      date: props.options.toTime.valueOf(),
    },
  }),
}

export default StatisticsApi
