import ApiEndpoints from '../config/ApiEndpoints'

import PayoutsErrors from '../errors/PayoutsErrors'

const PayoutsApi = {
  getPayout: (payoutId, body) => ({
    endpoint: ApiEndpoints.PAYOUT,
    type: 'GET',
    body,
    params: { payoutId },
  }),

  getPayoutsList: (rGroupId, body) => ({
    endpoint: ApiEndpoints.PAYOUTS_LIST,
    type: 'POST',
    body: {
      date: `${body.month}/${body.year}`,
      restaurantIds: body.restaurantIds,
      cursor: body.cursor,
      limit: body.limit,
    },
    params: { rGroupId },
    errorHandler: PayoutsErrors.LIST,
  }),

  getPayoutsFailedList: (rGroupId, body) => ({
    endpoint: ApiEndpoints.PAYOUTS_ERROR_LIST,
    type: 'POST',
    body,
    params: { rGroupId },
    errorHandler: PayoutsErrors.LIST,
  }),

  getPayoutsReports: (rGroupId, query) => ({
    endpoint: ApiEndpoints.PAYOUTS_REPORTS,
    type: 'GET',
    params: { rGroupId },
    query,
    errorHandler: PayoutsErrors.REPORTS,
  }),

  postPayoutsReports: (rGroupId, fromDate) => ({
    endpoint: ApiEndpoints.PAYOUTS_REPORTS,
    type: 'POST',
    params: { rGroupId },
    query: { fromDate },
    errorHandler: PayoutsErrors.REPORTS,
  }),
}

export default PayoutsApi
