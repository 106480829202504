
export const UploadType = {
  VALIDATION_MODE: 'VALIDATION_MODE',
  CREATION_MODE: 'CREATION_MODE',
}


// the order of the keys on the excel file to import
export const rowKeys = [
  'firstName',
  'lastName',
  'birthdate',
  'email',
  'nationality',
  'countryOfResidence',
  'companyName',
  'companyId',
  'employeeId',
  'physicalBadgeId',
  'pricingRuleId',
  'billingEntityId',
  'balance',
  'status',
  'gender',
  'mobile',
  'address',
  'postCode',
  'city',
  'country',
]

export const rowKeysColumn = {
  'firstName': 'A',
  'lastName': 'B',
  'birthdate': 'C',
  'email': 'D',
  'nationality': 'E',
  'countryOfResidence': 'F',
  'companyName': 'G',
  'companyId': 'H',
  'employeeId': 'I',
  'physicalBadgeId': 'J',
  'pricingRuleId': 'K',
  'balance': 'L',
  'status': 'M',
  'gender': 'N',
  'mobile': 'O',
  'address': 'P',
  'postCode': 'Q',
  'city': 'R',
  'country': 'S',
}

export const rowKeysLabel = {
  'firstName': 'Prénom',
  'lastName': 'Nom',
  'birthdate': 'Date de naissance',
  'email': 'email',
  'nationality': 'Nationalité',
  'countryOfResidence': 'Pays de résidence',
  'companyName': `Nom de l'entreprise`,
  'companyId': `Id entreprise`,
  'employeeId': `Id employé`,
  'physicalBadgeId': 'Id badge physique',
  'pricingRuleId': 'Id catégorie tarifaire',
  'balance': `Solde initial`,
  'status': 'Status',
  'gender': 'Sexe',
  'mobile': 'Numéro de portable',
  'address': 'Rue',
  'postCode': 'Code postal',
  'city': 'Ville',
  'country': 'Pays',
}
