import createMiddleware from 'pmt-modules/redux/createMiddleware'

import { PostProNotificationsAction } from './actions'
import { redirectTo, getRoute } from 'pmt-modules/routing'

/**
 *
 */
const createdProNotificationSuccessMiddleware = createMiddleware(
  PostProNotificationsAction.SUCCESS,
  ({ action, dispatch, next }) => {
    dispatch(
      redirectTo(getRoute('PRO_NOTIFICATIONS_SETTINGS_EDIT'), {
        proNotificationSettingId: action.response.id,
      })
    )
  }
)

export const proNotificationsSettingsMiddlewares = [createdProNotificationSuccessMiddleware]
