import createForm from 'pmt-modules/form/createForm'
import FormType from 'pmt-modules/form/FormType'

const defaultData = {
  acquirer: null,
  psp: null,
}

const validationRules = () => ({})

export default createForm(FormType.GROUP_EDIT_FINANCE_SETTINGS, validationRules, defaultData)
