import { createSimpleReducer } from 'pmt-modules/redux'
import { createSimpleSelector } from 'pmt-modules/redux'
import { createApiCallAction, createApiEnumAction } from 'pmt-modules/api/utils'
import createAction from 'pmt-modules/redux/createAction'

function createAsyncServiceModule({ name, action, getApiCall, getState, formatter }) {
  const SET_RESULT = `${name}::SET_RESULT`

  const apiEnumAction = createApiEnumAction(`${name}::RUN_ASYNC_SERVICE`)

  const fetchAction = payload =>
    createApiCallAction(apiEnumAction, getApiCall(payload), {
      payload,
    })

  const setResult = result =>
    createAction(SET_RESULT, {
      result,
    })

  const reducer = createSimpleReducer(apiEnumAction, {
    [SET_RESULT]: (state, action) => {
      return state.setIn(['data', 'result'], action.result)
    },
  })

  const restaurantCheckupSelectors = createSimpleSelector(getState, formatter)

  return {
    reducer,
    fetchAction,
    setResult,
    isFetching: restaurantCheckupSelectors.isFetching,
    getData: restaurantCheckupSelectors.getData,
    getError: restaurantCheckupSelectors.getError,
  }
}

export default createAsyncServiceModule
