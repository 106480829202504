import ApiEndpoints from '../config/ApiEndpoints'

const AuditApi = {
  getEvents: props => ({
    endpoint: ApiEndpoints.EVENTS_SEARCH,
    type: 'POST',
    body: props,
  }),

  getSession: sessionId => ({
    endpoint: ApiEndpoints.EVENTS_SESSION,
    type: 'GET',
    params: {
      sessionId,
    },
  }),

  getLogs: props => ({
    endpoint: ApiEndpoints.AUDIT_LOGS_SEARCH,
    type: 'POST',
    body: props,
  }),
}

export default AuditApi
