import { createFormatter } from 'pmt-utils/format'
import { getBrowserTimeZone } from 'pmt-utils/timeZone'
import { getBrowserInfo } from 'pmt-utils/browser'

import { __VERSION__, __SDK_GIT_COMMIT__, __APP_GIT_COMMIT__ } from 'pmt-modules/environment'

const formatBrowserInfo = data => {
  data.browser = {
    timeZone: getBrowserTimeZone(),
    ...getBrowserInfo(),
  }
  return data
}

const formatBuildIndo = data => {
  data.build = {
    version: __VERSION__,
    sdkGitCommit: __SDK_GIT_COMMIT__,
    appGitCommit: __APP_GIT_COMMIT__,
    versionHash: `${__APP_GIT_COMMIT__}_${__SDK_GIT_COMMIT__}`,
  }
  return data
}

export const formatBoData = createFormatter(formatBrowserInfo, formatBuildIndo)
