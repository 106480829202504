import { createSelector } from 'reselect'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import { createSimpleSelector, createPaginateSelector } from 'pmt-modules/redux'

import { formatMedia, formatMedias } from './format'

//
// Get
//
const getMediaSelectors = createSimpleSelector(state => state.entities.media.get, formatMedia)

export const isFetchingMedia = getMediaSelectors.isFetching
export const getMediaData = getMediaSelectors.getData
export const getMediaError = getMediaSelectors.getError

//
// Get List
//
const getListMediaSelectors = createPaginateSelector(
  state => state.entities.media.getList,
  formatMedias
)

export const isFetchingGetListMedia = getListMediaSelectors.isFetching
export const getListMediaData = getListMediaSelectors.getList
export const getListMediaError = getListMediaSelectors.getError
export const getListMediaPaging = getListMediaSelectors.getPaging

//
// Post
//
const postMediaSelectors = createSimpleSelector(state => state.entities.media.post, formatMedia)

export const isFetchingPostMedia = postMediaSelectors.isFetching
export const getPostMediaData = postMediaSelectors.getData
export const getPostMediaError = postMediaSelectors.getError

//
// Upload
//
const getUploadState = state => state.entities.media.upload

export const getUploadedFile = createSelector([getUploadState], uploadState => {
  if (isNil(uploadState)) {
    return null
  }

  let files = uploadState.get('files')
  if (isEmpty(files)) {
    return null
  }

  files = files.toJS()

  // get only one file, the first object
  return files[Object.keys(files)[0]]
})
