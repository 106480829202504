import { notEmpty, isValidEmailOrEmpty, isValidPhone, isLocale } from 'pmt-modules/form/validation'

import createForm from 'pmt-modules/form/createForm'

import FormType from 'pmt-modules/form/FormType'

import Diet from '../../constants/Diet'
import Allergen from '../../constants/Allergen'
import Gender from '../../constants/Gender'
import { DEFAULT_BIRTHDATE } from '../../constants'

import { formatCreateUser } from '../../format/createUser'

// in DEV environment, prefill form with the following data
const devData = {
  email: `devweb+pmt_by_pro_${new Date().getSeconds()}@paymytable.com`,
  firstName: 'John',
  lastName: 'Doe',
  phone: `+33 6 30 25 ${new Date().getMinutes()} ${new Date().getSeconds()}`,
  nationality: 'FR',
  countryOfResidence: 'FR',
  marketing: {
    allowContact: true,
  },
  dietaryPreferences: {
    allergens: [Allergen.PEANUT, Allergen.CRUSTACEAN, Allergen.NUTS],
    diet: Diet.NONE,
  },
}

const defaultData = {
  email: null,
  firstName: null,
  lastName: null,
  phone: null,
  nationality: 'FR',
  countryOfResidence: 'FR',
  marketing: {
    allowContact: true, // created by a pro, so by default, it allows contact
  },
  dietaryPreferences: {
    allergens: [],
    diet: Diet.NONE,
  },
  gender: Gender.NONE,
  birthdate: DEFAULT_BIRTHDATE,
}

// TODO: I18n
const validationRules = {
  email: [[isValidEmailOrEmpty, 'global.form.invalid_mail']],
  firstName: [],
  lastName: [[notEmpty, 'global.form.required']],
  phone: [[isValidPhone, 'global.form.invalid_phone']],
  countryOfResidence: [[isLocale, 'global.form.required']],
  nationality: [[isLocale, 'global.form.required']],
  dietaryPreferences: {
    allergens: [],
    diet: [[notEmpty, 'global.form.required']],
  },
}

export default createForm(FormType.CREATE_USER, validationRules, defaultData, devData, {
  modifier: formatCreateUser,
})
