import ImportUserApi from '../api/calls/ImportUserApi'

import { createAction } from '../redux'

import {
  UploadType,
} from './constants'

import {
  createApiCallAction,
  createApiEnumAction,
} from '../api/utils'


export const ImportUserAction = createApiEnumAction('IMPORT_USER::POST')

/**
 *
 * @param  {[type]} body
 *                          - shouldWriteOnDb
 *                          - shouldSendEmail
 *                          - rowToEnd
 *                          // TODO: maj doc with new api version
 *                          - bucketName
 *                          - fileName
 * @return {[type]}       [description]
 */
export const validateImportUser = (rGroupId, body) => createApiCallAction(
  ImportUserAction,
  ImportUserApi.postImport(rGroupId, body),
  {
    rGroupId,
    body,
    uploadType: UploadType.VALIDATION_MODE,
  }
)

/**
 *
 * @param  {[type]} body
 *                          - shouldWriteOnDb
 *                          - shouldSendEmail
 *                          - rowToEnd
 *                          // TODO: maj doc with new api version
 *                          - bucketName
 *                          - fileName
 * @return {[type]}       [description]
 */
export const createImportUser = (rGroupId, body) => createApiCallAction(
  ImportUserAction,
  ImportUserApi.postImport(rGroupId, body),
  {
    rGroupId,
    body,
    uploadType: UploadType.CREATION_MODE,
  }
)

//
//
//

export const IMPORT_USER_NEW_IMPORT_FLOW = 'IMPORT_USER::NEW_FLOW'

export const newImportFlow = () => createAction(
  IMPORT_USER_NEW_IMPORT_FLOW,
)
