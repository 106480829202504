import { tr } from 'pmt-modules/i18n'
import React from 'react'

import Button from 'pmt-ui/Button'

/**
 * View displayed on multiple mode, when there is no value selected.
 */
const EmptyValue = ({ show, label = tr('global.add'), onClickOpen, disabled }) =>
  !show ? null : (
    <Button variant="outlined" color="primary" onClick={onClickOpen} disabled={disabled}>
      {label}
    </Button>
  )

export default EmptyValue
