import { tr } from 'pmt-modules/i18n'
import { notEmpty, isGreaterThan } from 'pmt-modules/form/validation'
import createForm from 'pmt-modules/form/createForm'
import FormType from 'pmt-modules/form/FormType'

// in DEV environment, prefill form with the following data
const devData = {}

const defaultData = {
  name: null,
  currency: null,
  password: null,
  restaurantsGroup: null,
}

/**
 * Validation rules for register form
 * options parameters should contains fields retrieved from api consumer settings
 * it is structured as is : {
 *   type: FIELD_TYPE,
 *   required: boolean
 * }
 * if field is required, we automatically add a notEmpty rule
 */
const validationRules = options => {
  const rules = {
    name: [[notEmpty, tr('global.restaurant.form.validation.name_invalid')]],
    currency: [[notEmpty, tr('global.restaurant.form.validation.currency_missing')]],
    password: [[isGreaterThan(5), tr('global.restaurant.form.validation.password_length')]],
    restaurantsGroup: [
      [notEmpty, tr('global.restaurant.form.validation.restaurantsGroup_missing')],
    ],
  }

  return rules
}

export default createForm(FormType.RESTAURANT_CREATE, validationRules, defaultData, devData)
