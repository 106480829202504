import Immutable from 'immutable'
import cloneDeep from 'lodash/cloneDeep'
import { createAction, createReducer } from 'pmt-modules/redux'
import { createSelector } from 'reselect'

export const FlashMessageType = {
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  SUCCESS: 'SUCCESS',
  INFO: 'INFO',
}

const addFlashMessageType = 'FlashMessagesModule::addFlashMessage'
const removeFlashMessageType = 'FlashMessagesModule::removeFlashMessageType'

export const addFlashMessage = flashMessage =>
  createAction(addFlashMessageType, {
    flashMessage,
  })

export const removeFlashMessage = flashMessageId =>
  createAction(removeFlashMessageType, {
    flashMessageId,
  })

const DEFAULT = Immutable.fromJS({
  messages: [],
})

const onAddFlashMessage = (state, action) =>
  state.updateIn(['messages'], flashMessages =>
    Immutable.fromJS([...cloneDeep(flashMessages), action.flashMessage])
  )

const onRemoveFlashMessage = (state, action) =>
  state.updateIn(['messages'], flashMessages =>
    flashMessages.filter(flashMessage => {
      return flashMessage.get('id') !== action.flashMessageId
    })
  )

const getState = state => state.entities.flashMessages

export const getFlashMessages = createSelector(getState, state => {
  const flashMessages = state.get('messages', [])
  return flashMessages.toJS ? flashMessages.toJS() : flashMessages
})

export const flashMessagesReducer = createReducer(DEFAULT, {
  [addFlashMessageType]: onAddFlashMessage,
  [removeFlashMessageType]: onRemoveFlashMessage,
})
