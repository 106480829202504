import createAsyncServiceModule from 'pmt-modules/module/createAsyncServiceModule'

import RestaurantApi from 'pmt-modules/api/calls/RestaurantApi'
import { createFormatter } from 'pmt-utils/format'

const formatRestaurantCheckupReport = createFormatter(data => data)

const asyncServiceModule = createAsyncServiceModule({
  name: 'RestaurantCheckup',
  getApiCall: payload => RestaurantApi.getCheckup(payload),
  getState: state => state.entities.restaurantCheckup,
  formatter: formatRestaurantCheckupReport,
})

export const fetchRestaurantCheckup = asyncServiceModule.fetchAction

export const isFetchingRestaurantCheckup = asyncServiceModule.isFetching
export const getRestaurantCheckup = asyncServiceModule.getData
export const getErrorRestaurantCheckup = asyncServiceModule.getError

export const restaurantCheckupReducer = asyncServiceModule.reducer
export const setCheckupReport = asyncServiceModule.setResult
