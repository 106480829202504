import { createApiCallAction, createApiEnumAction } from '../api/utils'
import ProApi from '../api/calls/ProApi'
import { createAction } from '../redux'

//
//
//

export const GetProAccountAction = createApiEnumAction('PRO_ACCOUNT::GET')

export const fetchProAccount = proAccountId =>
  createApiCallAction(GetProAccountAction, ProApi.getProAccount(proAccountId), {
    proAccountId,
  })

//
//
//

export const PostProAccountAction = createApiEnumAction('PRO_ACCOUNT::POST')

export const postProAccount = proAccount =>
  createApiCallAction(PostProAccountAction, ProApi.postProAccount(proAccount), {
    proAccount,
  })

//
//
//

export const PutProAccountAction = createApiEnumAction('PRO_ACCOUNT::PUT')

export const putProAccount = proAccount =>
  createApiCallAction(PutProAccountAction, ProApi.putProAccount(proAccount.id, proAccount), {
    proAccountId: proAccount.id,
    proAccount,
  })

//
//
//

export const RESET_DATA_ACTION = 'PRO_ROLE::RESET_DATA_ACTION'

// reset Put / Post reducer data
export const resetProAccountData = () => createAction(RESET_DATA_ACTION)
