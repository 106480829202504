import React from 'react'

import ProMeContainer from './ProMeContainer'

/**
 * Add the ProMeContainer as HOC for the given component
 */
const withProMe = (ProMeWrappedComponent) => {
  return (props) => (
    <ProMeContainer
      ProMeWrappedComponent={ProMeWrappedComponent}
      {...props}
    />
  )
}

export default withProMe
