import React from 'react'
import { tr } from 'pmt-modules/i18n'
import { createLoadable } from 'pmt-utils/react-loadable'

import RoutingEnum from './routingEnum'

import isNil from 'lodash/isNil'
import isUndefined from 'lodash/isUndefined'
import isFunction from 'lodash/isFunction'
import invariant from 'invariant'

import { DISABLE_FORM_DEV_DATA_QUERY_PARAM } from 'pmt-modules/form'
import { QUERY_PARAM_LOCALE } from 'pmt-modules/i18n'
import pimV2Routes from './routes-pim'

import {
  AdminPermissions,
  AppInstancePermissions,
  ApiConsumerPermissions,
  FinancePermissions,
  AuditPermissions,
  DocumentationPermissions,
  CrmPermissions,
  ProAccountPermissions,
  ProRolePermissions,
  HistoryPermissions,
  FeedbackPermissions,
  MediaPermissions,
  RestaurantsGroupPermissions,
  RestaurantSettingsPermissions,
  ProEventNotificationsPermissions,
  OrderPermissions,
} from 'pmt-modules/bo'

import PageLoading from '../components/PageLoading'

const createAsyncPage = loader => createLoadable(loader, () => <PageLoading />)

// list of the queries that are globaGl to all our urls, and should be kept
const globalQueries = [
  'rGroupId',
  'domain',
  'apiVersion',
  DISABLE_FORM_DEV_DATA_QUERY_PARAM,
  QUERY_PARAM_LOCALE,
  'simulate',
]

// merge our globalQueries with the specific queries of the route
const getQueries = (queries = []) => [...globalQueries, ...queries]

/**
 * We define  the `handler` for each route of our RoutingEnum.
 * The `handler` is the React Component to display for the route.
 * It should be a container in most cases.
 */
const routes = [
  {
    definition: RoutingEnum.PAGE_LOADING,
    handler: () => null,
    props: {
      menuReferer: null,
      authRequired: false,
      icon: null,
      ariane: () => [
        {
          title: '-', // display ariane but with nothing on it, otherwise, we have an ugly effect
        },
      ],
    },
  },
  {
    definition: RoutingEnum.BO_SETTINGS_PAGE,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "BoSettingsPage" */ '../pages/me/boSettings/BoSettingsPage')
    ),
    props: {
      menuReferer: null,
      authRequired: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.me.settings'),
          definition: RoutingEnum.BO_SETTINGS_PAGE,
        },
      ],
    },
  },
  {
    definition: RoutingEnum.HOME,
    handler: createAsyncPage(() => import(/*  webpackChunkName: "HomePage" */ '../pages/home')),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.HOME,
      authRequired: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.home'),
          definition: RoutingEnum.HOME,
        },
      ],
    },
  },
  {
    definition: RoutingEnum.BO_STATISTICS_ORDER,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "OrderStatisticsPage" */ '../pages/statistics/order')
    ),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.BO_STATISTICS_ORDER,
      authRequired: true,
      permissions: [RestaurantsGroupPermissions.ORDER_GENERAL_STATS],
      icon: null,
      query: getQueries(['fromTime', 'toTime', 'period']),
      ariane: () => [
        {
          title: tr('ariane.statistics'),
          definition: null,
        },
        {
          title: tr('ariane.statistics.order'),
          definition: RoutingEnum.BO_STATISTICS_ORDER,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.BO_STATISTICS_TOP_UP,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "TopUpStatisticsPage" */ '../pages/statistics/topUp')
    ),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.BO_STATISTICS_TOP_UP,
      authRequired: true,
      permissions: [RestaurantsGroupPermissions.DEPOSIT_GENERAL_STATS],
      icon: null,
      query: getQueries(['fromTime', 'toTime', 'period']),
      ariane: () => [
        {
          title: tr('ariane.statistics'),
          definition: null,
        },
        {
          title: tr('ariane.statistics.deposit'),
          definition: RoutingEnum.BO_STATISTICS_TOP_UP,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.ERRORS_LIST,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "ErrorsListPage" */ '../pages/errorslist')
    ),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.ERRORS_LIST,
      authRequired: true,
      permissions: [OrderPermissions.ACCESS, FinancePermissions.ACCESS],
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.errors.list'),
          definition: RoutingEnum.ERRORS_LIST,
        },
      ],
    },
  },

  //
  // Auth
  //
  {
    definition: RoutingEnum.LOGIN,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "LoginPage" */ '../pages/auth/login')
    ),
    props: {
      menuReferer: null,
      authRequired: false,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.home_backoffice'),
          definition: RoutingEnum.HOME,
        },
        {
          title: tr('ariane.login'),
          definition: RoutingEnum.LOGIN,
        },
      ],
    },
  },
  {
    definition: RoutingEnum.BO_SELECT_PRO_DOMAINS,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "SelectProDomainPage" */ '../pages/auth/domain')
    ),
    props: {
      withV2Design: true,
      menuReferer: null,
      authRequired: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.domain_selection'),
          definition: null,
        },
      ],
    },
  },
  {
    definition: RoutingEnum.BO_SELECT_RGROUP,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "SelectRestaurantsGroupPage" */ '../pages/auth/rGroup')
    ),
    props: {
      withV2Design: true,
      menuReferer: null,
      authRequired: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.domain_selection'),
          definition: RoutingEnum.BO_SELECT_PRO_DOMAINS,
        },
        {
          title: '{domainName}',
          definition: null,
        },
      ],
    },
  },
  {
    definition: RoutingEnum.LOGOUT,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "LogoutPage" */ '../pages/auth/logout')
    ),
    props: {
      menuReferer: null,
      authRequired: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.home_backoffice'),
          definition: RoutingEnum.HOME,
        },
        {
          title: tr('ariane.logout'),
          definition: RoutingEnum.LOGOUT,
        },
      ],
    },
  },
  {
    definition: RoutingEnum.FORGOT_PASSWORD,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "ForgotPasswordPage" */ '../pages/auth/forgotPassword')
    ),
    props: {
      menuReferer: null,
      authRequired: false,
      icon: null,
      ariane: null,
    },
  },

  //
  // User
  //

  {
    definition: RoutingEnum.BO_USER_ADD,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "UserAddStepUserPage" */ '../pages/user/add/user')
    ),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.BO_USER_LIST,
      authRequired: true,
      permissions: [CrmPermissions.ACCESS, CrmPermissions.CREATE_USER],
      isForGroupUserOnly: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.crm.clients'),
          definition: RoutingEnum.BO_USER_LIST,
        },
        {
          title: tr('ariane.crm.clients.new'),
          definition: null,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.BO_USER_ADD_STEP_ACCOUNT,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "UserAddStepAccountPage" */ '../pages/user/add/account')
    ),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.BO_USER_LIST,
      authRequired: true,
      permissions: [CrmPermissions.ACCESS, CrmPermissions.CREATE_USER],
      isForGroupUserOnly: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.crm.clients'),
          definition: RoutingEnum.BO_USER_LIST,
        },
        {
          title: tr('ariane.crm.clients.new'),
          definition: null,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.BO_USER_LIST,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "UserListPage" */ '../pages/user/list')
    ),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.BO_USER_LIST,
      authRequired: true,
      permissions: [CrmPermissions.ACCESS],
      isForGroupUserOnly: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.crm.clients'),
          definition: RoutingEnum.BO_USER_LIST,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.BO_USER_CARD,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "UserCardPage" */ '../pages/user/card')
    ),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.BO_USER_LIST,
      authRequired: true,
      permissions: [CrmPermissions.ACCESS],
      isForGroupUserOnly: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.crm.clients.client'),
          definition: RoutingEnum.BO_USER_LIST,
        },
        {
          title: '{userName}',
          definition: null,
        },
      ],
    },
  },

  //
  // ProAccount
  //

  {
    definition: RoutingEnum.BO_PRO_ACCOUNT_LIST,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "ProAccountListPage" */ '../pages/pro/list')
    ),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.BO_PRO_ACCOUNT_LIST,
      authRequired: true,
      permissions: [ProAccountPermissions.ACCESS],
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.pro.list'),
          definition: null,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.BO_PRO_ACCOUNT_CREATE,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "ProAccountAddPage" */ '../pages/pro/edit')
    ),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.BO_PRO_ACCOUNT_LIST,
      authRequired: true,
      permissions: [ProAccountPermissions.CREATE_PRO],
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.pro.list'),
          definition: RoutingEnum.BO_PRO_ACCOUNT_LIST,
        },
        {
          title: tr('ariane.pro.new'),
          definition: null,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.BO_PRO_ACCOUNT_EDIT,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "ProAccountEditPage" */ '../pages/pro/edit')
    ),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.BO_PRO_ACCOUNT_LIST,
      authRequired: true,
      permissions: [ProAccountPermissions.EDIT_PRO],
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.pro.list'),
          definition: RoutingEnum.BO_PRO_ACCOUNT_LIST,
        },
        {
          title: tr('ariane.pro.edit'),
          definition: null,
        },
      ],
    },
  },

  //
  // ProRole
  //

  {
    definition: RoutingEnum.BO_PRO_ROLE_LIST,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "ProRoleListPage" */ '../pages/proRole/list')
    ),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.BO_PRO_ROLE_LIST,
      authRequired: true,
      permissions: [ProRolePermissions.ACCESS],
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.pro_role'),
          definition: null,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.BO_PRO_ROLE_EDIT,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "ProRoleEditPage" */ '../pages/proRole/edit')
    ),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.BO_PRO_ROLE_LIST,
      authRequired: true,
      permissions: [ProRolePermissions.EDIT_ROLE],
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.pro_role'),
          definition: RoutingEnum.BO_PRO_ROLE_LIST,
        },
        {
          title: tr('ariane.pro_role.edit'),
          definition: null,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.BO_PRO_ROLE_CREATE,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "ProRoleAddPage" */ '../pages/proRole/edit')
    ),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.BO_PRO_ROLE_LIST,
      authRequired: true,
      permissions: [ProRolePermissions.CREATE_ROLE],
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.pro_role'),
          definition: RoutingEnum.BO_PRO_ROLE_LIST,
        },
        {
          title: tr('ariane.pro_role.create'),
          definition: null,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.BO_USER_FEEDBACK_LIST,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "UserFeedbackListPage" */ '../pages/user/feedback/list')
    ),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.BO_USER_LIST,
      authRequired: true,
      permissions: [],
      isForGroupUserOnly: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.clients'),
          definition: RoutingEnum.BO_USER_LIST,
        },
        {
          title: '{userFullName}',
          definition: RoutingEnum.BO_USER_CARD,
        },
        {
          title: tr('ariane.feedback.user'),
          definition: RoutingEnum.BO_USER_FEEDBACK_LIST,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.BO_USER_ORDER_LIST,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "UserOrderListPage" */ '../pages/user/order/list')
    ),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.BO_USER_LIST,
      authRequired: true,
      permissions: [OrderPermissions.ACCESS],
      isForGroupUserOnly: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.clients'),
          definition: RoutingEnum.BO_USER_LIST,
        },
        {
          title: '{userFullName}',
          definition: RoutingEnum.BO_USER_CARD,
        },
        {
          title: tr('ariane.client.order'),
          definition: RoutingEnum.BO_USER_ORDER_LIST,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.BO_USER_TOP_UP_LIST,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "UserTopUpListPage" */ '../pages/user/topUp/list')
    ),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.BO_USER_LIST,
      authRequired: true,
      permissions: [],
      isForGroupUserOnly: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.clients'),
          definition: RoutingEnum.BO_USER_LIST,
        },
        {
          title: '{userFullName}',
          definition: RoutingEnum.BO_USER_CARD,
        },
        {
          title: tr('ariane.client.topup'),
          definition: RoutingEnum.BO_USER_TOP_UP_LIST,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.BO_USER_TRANSACTIONS_LIST,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "UserTransactionsListPage" */ '../pages/user/transactions')
    ),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.BO_USER_LIST,
      authRequired: true,
      permissions: [],
      isForGroupUserOnly: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.clients'),
          definition: RoutingEnum.BO_USER_LIST,
        },
        {
          title: '{userFullName}',
          definition: RoutingEnum.BO_USER_CARD,
        },
        {
          title: tr('ariane.client.transactions'),
          definition: RoutingEnum.BO_USER_TRANSACTIONS_LIST,
        },
      ],
    },
  },

  //
  // Security
  //

  {
    definition: RoutingEnum.BO_PERMISSIONS_ERROR,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "PermissionsErrorPage" */ '../pages/security/permissionsError')
    ),
    props: {
      menuReferer: null,
      authRequired: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.error'),
          definition: null,
        },
        {
          title: tr('ariane.error.missing_permissions'),
          definition: null,
        },
      ],
    },
  },

  //
  // Group
  //

  {
    definition: RoutingEnum.BO_GROUPS_CREATE,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "GroupsCreatePage" */ '../pages/groups/create')
    ),
    props: {
      withV2Design: true,
      permissions: [RestaurantsGroupPermissions.CREATE_RESTAURANTS_GROUP],
      menuReferer: RoutingEnum.BO_GROUPS_CREATE,
      authRequired: true,
      ariane: () => [
        {
          title: tr('ariane.stores'),
          definition: null,
        },
        {
          title: tr('ariane.stores.groups'),
          definition: RoutingEnum.BO_GROUPS_TREE,
        },
        {
          title: tr('ariane.stores.groups.create'),
          definition: null,
        },
      ],
    },
  },
  {
    definition: RoutingEnum.BO_GROUPS_INFOS,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "GroupsInfosPage" */ '../pages/groups/infos')
    ),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.BO_GROUPS_INFOS,
      authRequired: true,
      ariane: () => [
        {
          title: tr('ariane.stores'),
          definition: null,
        },
        {
          title: '{restaurantsGroupName}',
          definition: null,
        },
        {
          title: tr('ariane.stores.general_informations'),
          definition: RoutingEnum.BO_GROUPS_INFOS,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.BO_GROUPS_TREE,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "GroupsTreePage" */ '../pages/groups/tree')
    ),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.BO_GROUPS_INFOS,
      authRequired: true,
      ariane: () => [
        {
          title: tr('ariane.stores'),
          definition: null,
        },
        {
          title: '{restaurantsGroupName}',
          definition: RoutingEnum.BO_GROUPS_INFOS,
        },
        {
          title: tr('ariane.stores.groups'),
          definition: RoutingEnum.BO_GROUPS_INFOS,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.BO_GROUPS_STORES,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "GroupsStoresPage" */ '../pages/groups/stores/list')
    ),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.BO_GROUPS_STORES,
      authRequired: true,
      ariane: () => [
        {
          title: tr('ariane.stores'),
          definition: null,
        },
        {
          title: '{restaurantsGroupName}',
          definition: RoutingEnum.BO_GROUPS_INFOS,
        },
        {
          title: tr('ariane.stores.stores'),
          definition: RoutingEnum.BO_GROUPS_STORES,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.BO_GROUPS_CUSTOM_TEXTS_EMAIL_LIST,
    handler: createAsyncPage(() =>
      import(
        /*  webpackChunkName: "GroupsStoresCustomTextsEmailListPage" */ '../pages/customTexts/customTextsEmails'
      )
    ),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.BO_GROUPS_INFOS,
      permissions: [RestaurantsGroupPermissions.TEXT_SETTINGS],
      authRequired: true,
      ariane: () => [
        {
          title: tr('ariane.stores'),
          definition: null,
        },
        {
          title: '{restaurantsGroupName}',
          definition: RoutingEnum.BO_GROUPS_INFOS,
        },
        {
          title: tr('ariane.stores.group.custom_texts.email_list'),
          definition: RoutingEnum.BO_GROUPS_CUSTOM_TEXTS_EMAIL_LIST,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.BO_GROUPS_CUSTOM_TEXTS_EMAIL_DETAIL,
    handler: createAsyncPage(() =>
      import(
        /*  webpackChunkName: "GroupsStoresCustomTextsEmailDetailPage" */ '../pages/customTexts/customTextsEmailDetail'
      )
    ),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.BO_GROUPS_INFOS,
      permissions: [RestaurantsGroupPermissions.TEXT_SETTINGS],
      authRequired: true,
      ariane: () => [
        {
          title: tr('ariane.stores'),
          definition: null,
        },
        {
          title: '{restaurantsGroupName}',
          definition: RoutingEnum.BO_GROUPS_INFOS,
        },
        {
          title: tr('ariane.stores.group.custom_texts.email_list'),
          definition: RoutingEnum.BO_GROUPS_CUSTOM_TEXTS_EMAIL_LIST,
        },
        {
          title: '{emailLabel}',
          definition: null,
        },
      ],
    },
  },

  // PSP Settings

  {
    definition: RoutingEnum.BO_GROUPS_PSP_SETTINGS_LIST,
    handler: createAsyncPage(() =>
      import(
        /*  webpackChunkName: "GroupsStoresPspSettingsListPage" */ '../pages/groups/pspSettings/list'
      )
    ),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.BO_GROUPS_INFOS,
      permissions: [RestaurantsGroupPermissions.ACCESS],
      authRequired: true,
      ariane: () => [
        {
          title: tr('ariane.stores'),
          definition: null,
        },
        {
          title: '{restaurantsGroupName}',
          definition: RoutingEnum.BO_GROUPS_INFOS,
        },
        {
          title: tr('ariane.stores.group.psp_settings.list'),
          definition: null,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.BO_GROUPS_PSP_SETTINGS_CREATE,
    handler: createAsyncPage(() =>
      import(
        /*  webpackChunkName: "GroupsStoresPspSettingsCreatePage" */ '../pages/groups/pspSettings/create-and-edit-and-details'
      )
    ),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.BO_GROUPS_INFOS,
      permissions: [RestaurantsGroupPermissions.CAN_EDIT],
      authRequired: true,
      ariane: () => [
        {
          title: tr('ariane.stores'),
          definition: null,
        },
        {
          title: '{restaurantsGroupName}',
          definition: RoutingEnum.BO_GROUPS_INFOS,
        },
        {
          title: tr('ariane.stores.group.psp_settings.list'),
          definition: RoutingEnum.BO_GROUPS_PSP_SETTINGS_LIST,
        },
        {
          title: tr('ariane.stores.group.psp_settings.create'),
          definition: null,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.BO_GROUPS_PSP_SETTINGS_DETAILS,
    handler: createAsyncPage(() =>
      import(
        /*  webpackChunkName: "GroupsStoresPspSettingsDetailsPage" */ '../pages/groups/pspSettings/create-and-edit-and-details'
      )
    ),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.BO_GROUPS_INFOS,
      permissions: [RestaurantsGroupPermissions.CAN_EDIT],
      authRequired: true,
      ariane: () => [
        {
          title: tr('ariane.stores'),
          definition: null,
        },
        {
          title: '{restaurantsGroupName}',
          definition: RoutingEnum.BO_GROUPS_INFOS,
        },
        {
          title: tr('ariane.stores.group.psp_settings.list'),
          definition: RoutingEnum.BO_GROUPS_PSP_SETTINGS_LIST,
        },
        {
          title: tr('ariane.stores.group.psp_settings.details'),
          definition: null,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.BO_GROUPS_PSP_SETTINGS_EDIT,
    handler: createAsyncPage(() =>
      import(
        /*  webpackChunkName: "GroupsStoresPspSettingsEditPage" */ '../pages/groups/pspSettings/create-and-edit-and-details'
      )
    ),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.BO_GROUPS_INFOS,
      permissions: [RestaurantsGroupPermissions.CAN_EDIT],
      authRequired: true,
      ariane: () => [
        {
          title: tr('ariane.stores'),
          definition: null,
        },
        {
          title: '{restaurantsGroupName}',
          definition: RoutingEnum.BO_GROUPS_INFOS,
        },
        {
          title: tr('ariane.stores.group.psp_settings.list'),
          definition: RoutingEnum.BO_GROUPS_PSP_SETTINGS_LIST,
        },
        {
          title: tr('ariane.stores.group.psp_settings.edit'),
          definition: null,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.BO_GROUPS_EDIT_HIERARCHY,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "GroupsEditHierarchyPage" */ '../pages/groups/edit/hierarchy')
    ),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.BO_GROUPS_INFOS,
      authRequired: true,
      ariane: () => [
        {
          title: tr('ariane.stores'),
          definition: null,
        },
        {
          title: '{restaurantsGroupName}',
          definition: RoutingEnum.BO_GROUPS_INFOS,
        },
        {
          title: tr('ariane.stores.groups'),
          definition: RoutingEnum.BO_GROUPS_INFOS,
        },
        {
          title: tr('ariane.stores.groups.edit.hierarchy'),
          definition: RoutingEnum.BO_GROUPS_EDIT_HIERARCHY,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.MOVE_STORE,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "MoveStorePage" */ '../pages/movestore')
    ),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.BO_GROUPS_INFOS,
      authRequired: true,
      ariane: () => [
        {
          title: tr('ariane.stores'),
          definition: null,
        },
        {
          title: tr('ariane.movestore'),
          definition: RoutingEnum.MOVE_STORE,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.BO_GROUPS_EDIT_STORES,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "SettingsRestaurantsListPage" */ '../pages/groups/edit/stores')
    ),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.BO_GROUPS_EDIT_STORES,
      authRequired: true,
      ariane: () => [
        {
          title: tr('ariane.stores'),
          definition: null,
        },
        {
          title: tr('ariane.stores.groups'),
          definition: RoutingEnum.BO_GROUPS_STORES,
        },
        {
          title: tr('ariane.stores.groups.edit.store_list'),
          definition: RoutingEnum.BO_GROUPS_EDIT_STORES,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.BO_GROUPS_STORES_CREATE,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "GruopsStoresCreate" */ '../pages/groups/stores/create')
    ),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.BO_GROUPS_STORES_CREATE,
      authRequired: true,
      ariane: () => [
        {
          title: tr('ariane.stores'),
          definition: null,
        },
        {
          title: tr('ariane.stores.groups'),
          definition: RoutingEnum.BO_GROUPS_STORES,
        },
        {
          title: tr('ariane.stores.groups.stores.create'),
          definition: null,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.BO_SETTINGS_STORES_VIEW_DETAILS,
    handler: createAsyncPage(() =>
      import(
        /*  webpackChunkName: "SettingsStoresViewDetailsPage" */ '../pages/groups/stores/view/details'
      )
    ),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.BO_GROUPS_STORES,
      authRequired: true,
      ariane: () => [
        {
          title: tr('ariane.stores'),
          definition: null,
        },
        {
          title: tr('ariane.stores.groups'),
          definition: RoutingEnum.BO_SETTINGS_RESTAURANTS_LIST,
        },
        {
          title: `{restaurantName} - ${tr('restaurant.view.titles.details')}`,
          definition: RoutingEnum.BO_SETTINGS_STORES_VIEW_DETAILS,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.BO_SETTINGS_STORES_VIEW_SETTINGS,
    handler: createAsyncPage(() =>
      import(
        /*  webpackChunkName: "SettingsStoresViewSettingsPage" */ '../pages/groups/stores/view/settings/list'
      )
    ),
    props: {
      withV2Design: true,
      permissions: [RestaurantSettingsPermissions.READ_CONFIGURATION_POS],
      menuReferer: RoutingEnum.BO_GROUPS_STORES,
      authRequired: true,
      ariane: () => [
        {
          title: tr('ariane.stores'),
          definition: null,
        },
        {
          title: tr('ariane.stores.groups'),
          definition: RoutingEnum.BO_SETTINGS_RESTAURANTS_LIST,
        },
        {
          title: `{restaurantName} - ${tr('restaurant.view.titles.settings')}`,
          definition: RoutingEnum.BO_SETTINGS_STORES_VIEW_SETTINGS,
        },
      ],
    },
  },
  {
    definition: RoutingEnum.STORE_THIRDPARTY_SETTINGS_DETAILS,
    handler: createAsyncPage(() =>
      import(
        /*  webpackChunkName: "StoreThirdPartySettingsDetailsPage2" */ '../pages/groups/stores/view/settings/details'
      )
    ),
    props: {
      withV2Design: true,
      permissions: [RestaurantSettingsPermissions.READ_CONFIGURATION_POS],
      menuReferer: RoutingEnum.BO_GROUPS_STORES,
      authRequired: true,
      ariane: () => [
        {
          title: tr('ariane.stores'),
          definition: null,
        },
        {
          title: tr('ariane.stores.groups'),
          definition: RoutingEnum.BO_SETTINGS_RESTAURANTS_LIST,
        },
        {
          title: `{restaurantName} - ${tr('restaurant.view.titles.settings')}`,
          definition: RoutingEnum.BO_SETTINGS_STORES_VIEW_SETTINGS, // back to list
        },
      ],
    },
  },

  {
    definition: RoutingEnum.STORE_THIRDPARTY_SETTINGS_CREATE,
    handler: createAsyncPage(() =>
      import(
        /*  webpackChunkName: "StoreThirdPartySettingsCreationPage" */ '../pages/groups/stores/view/settings/create'
      )
    ),
    props: {
      withV2Design: true,
      permissions: [RestaurantSettingsPermissions.EDIT_CONFIGURATION_POS],
      menuReferer: RoutingEnum.BO_GROUPS_STORES,
      authRequired: true,
      ariane: () => [
        {
          title: tr('ariane.stores'),
          definition: null,
        },
        {
          title: tr('ariane.stores.groups'),
          definition: RoutingEnum.BO_SETTINGS_RESTAURANTS_LIST,
        },
        {
          title: `{restaurantName} - ${tr('restaurant.view.titles.settings')}`,
          definition: RoutingEnum.BO_SETTINGS_STORES_VIEW_SETTINGS,
        },
        {
          title: tr('ariane.store_thirdparty_settings.create'),
          definition: RoutingEnum.STORE_THIRDPARTY_SETTINGS_CREATE,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.BO_SETTINGS_STORES_VIEW_API_CONSUMERS,
    handler: createAsyncPage(() =>
      import(
        /*  webpackChunkName: "SettingsStoresViewApiConsumersPage" */ '../pages/groups/stores/view/apiConsumers'
      )
    ),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.BO_GROUPS_STORES,
      permissions: [ApiConsumerPermissions.ACCESS],
      authRequired: true,
      ariane: () => [
        {
          title: tr('ariane.stores'),
          definition: null,
        },
        {
          title: tr('ariane.stores.groups'),
          definition: RoutingEnum.BO_SETTINGS_RESTAURANTS_LIST,
        },
        {
          title: `{restaurantName} - ${tr('restaurant.view.titles.apiConsumers')}`,
          definition: RoutingEnum.BO_SETTINGS_STORES_VIEW_API_CONSUMERS,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.BO_SETTINGS_STORES_VIEW_STORES_GROUPS,
    handler: createAsyncPage(() =>
      import(
        /*  webpackChunkName: "SettingsStoresViewGroupsPage" */ '../pages/groups/stores/view/groups'
      )
    ),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.BO_GROUPS_STORES,
      authRequired: true,
      permissions: [RestaurantsGroupPermissions.ACCESS],
      ariane: () => [
        {
          title: tr('ariane.stores'),
          definition: null,
        },
        {
          title: tr('ariane.stores.groups'),
          definition: RoutingEnum.BO_SETTINGS_RESTAURANTS_LIST,
        },
        {
          title: `{restaurantName} - ${tr('restaurant.view.titles.groups')}`,
          definition: RoutingEnum.BO_SETTINGS_STORES_VIEW_STORES_GROUPS,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.BO_RESTAURANTS_DOCUMENTS_STATUS,
    handler: createAsyncPage(() =>
      import(
        /*  webpackChunkName: "StoresDocumentsStatusPage" */ '../pages/groups/stores-documents-status'
      )
    ),
    props: {
      withV2Design: true,
      permissions: [RestaurantSettingsPermissions.READ_LEGAL],
      menuReferer: RoutingEnum.BO_SETTINGS_STORES_VIEW_STORES_GROUPS,
      authRequired: true,
      ariane: () => [
        {
          title: tr('ariane.stores'),
          definition: null,
        },
        {
          title: tr('ariane.stores.groups'),
          definition: RoutingEnum.BO_SETTINGS_RESTAURANTS_LIST,
        },
        {
          title: `Documents status`, // TODO: I18n
          definition: null,
        },
      ],
    },
  },

  //
  //
  //

  {
    definition: RoutingEnum.BO_CHECKUP_STORE,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "CheckupStorePage" */ '../pages/checkup/store')
    ),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.BO_SETTINGS_STORES_VIEW_STORES_GROUPS,
      authRequired: true,
      ariane: () => [
        {
          title: tr('ariane.stores'),
          definition: null,
        },
        {
          title: tr('ariane.stores.groups'),
          definition: RoutingEnum.BO_SETTINGS_RESTAURANTS_LIST,
        },
        {
          title: `{restaurantName} - ${tr('restaurant.view.titles.details')}`,
          definition: RoutingEnum.BO_SETTINGS_STORES_VIEW_DETAILS,
        },
        {
          title: tr('ariane.checkup.store'),
        },
      ],
    },
  },

  {
    definition: RoutingEnum.BO_CHECKUP_GROUP_STORES,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "CheckupGroupStoresPage" */ '../pages/checkup/group-stores')
    ),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.BO_SETTINGS_STORES_VIEW_STORES_GROUPS,
      authRequired: true,
      ariane: () => [
        {
          title: tr('ariane.stores'),
          definition: null,
        },
        {
          title: '{restaurantsGroupName}',
          definition: RoutingEnum.BO_GROUPS_INFOS,
        },
        {
          title: tr('ariane.checkup.group_stores'),
        },
      ],
    },
  },

  //
  // Entity history
  //

  {
    definition: RoutingEnum.BO_ENTITY_SELECT,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "EntityHistorySelect" */ '../pages/entityHistory/select')
    ),
    props: {
      withV2Design: true,
      permissions: [HistoryPermissions.ACCESS],
      menuReferer: RoutingEnum.BO_ENTITY_SELECT,
      authRequired: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.history'),
          definition: RoutingEnum.BO_ENTITY_SELECT,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.BO_ENTITY_HISTORY_LIST,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "EntityHistoryList" */ '../pages/entityHistory/list')
    ),
    props: {
      withV2Design: true,
      permissions: [HistoryPermissions.ACCESS],
      menuReferer: RoutingEnum.BO_ENTITY_SELECT,
      authRequired: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.history'),
          definition: RoutingEnum.BO_ENTITY_SELECT,
        },
        {
          title: '{entityType} - {entityId}',
        },
      ],
    },
  },

  {
    definition: RoutingEnum.BO_ENTITY_HISTORY_DETAIL,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "EntityHistoryDetailPage" */ '../pages/entityHistory/detail')
    ),
    props: {
      withV2Design: true,
      permissions: [HistoryPermissions.ACCESS],
      menuReferer: RoutingEnum.BO_ENTITY_SELECT,
      authRequired: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.history'),
          definition: RoutingEnum.BO_ENTITY_HISTORY_LIST,
        },
        {
          title: '{entityType} - {entityId}',
          definition: RoutingEnum.BO_ENTITY_HISTORY_LIST,
        },
        {
          title: tr('ariane.history.revision.detail'),
        },
      ],
    },
  },

  //
  // Feedback
  //

  {
    definition: RoutingEnum.BO_FEEDBACK_CAMPAIGN_LIST,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "FeedbackCampaignListPage" */ '../pages/feedback/campaign/list')
    ),
    props: {
      withV2Design: true,
      permissions: [FeedbackPermissions.ACCESS],
      isForGroupUserOnly: true,
      menuReferer: RoutingEnum.BO_FEEDBACK_CAMPAIGN_LIST,
      authRequired: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.feedback.campaigns'),
          definition: RoutingEnum.BO_FEEDBACK_CAMPAIGN_LIST,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.BO_FEEDBACK_CAMPAIGN,
    handler: createAsyncPage(() =>
      import(
        /*  webpackChunkName: "FeedbackCampaignDetailPage" */ '../pages/feedback/campaign/detail'
      )
    ),
    props: {
      withV2Design: true,
      permissions: [FeedbackPermissions.ACCESS_CAMPAIGNS],
      isForGroupUserOnly: true,
      menuReferer: RoutingEnum.BO_FEEDBACK_CAMPAIGN_LIST,
      authRequired: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.feedback.campaigns'),
          definition: RoutingEnum.BO_FEEDBACK_CAMPAIGN_LIST,
        },
        {
          title: tr('ariane.feedback.campaign'),
          definition: null,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.BO_FEEDBACK_CAMPAIGN_ANSWERS,
    handler: createAsyncPage(() =>
      import(
        /*  webpackChunkName: "FeedbackCampaignAnswersPage" */ '../pages/feedback/campaign/answers'
      )
    ),
    props: {
      withV2Design: true,
      permissions: [FeedbackPermissions.ACCESS_CAMPAIGNS],
      isForGroupUserOnly: true,
      menuReferer: RoutingEnum.BO_FEEDBACK_CAMPAIGN_LIST,
      authRequired: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.feedback.campaigns'),
          definition: RoutingEnum.BO_FEEDBACK_CAMPAIGN_LIST,
        },
        {
          title: '{campaignName}',
          definition: RoutingEnum.BO_FEEDBACK_CAMPAIGN,
        },
        {
          title: tr('ariane.feedback.campaign.answers'),
          definition: null,
        },
      ],
    },
  },

  //
  // Documentation
  //

  // all endpoints home page, for admins
  {
    definition: RoutingEnum.API_DOCUMENTATION_ALL_ENDPOINTS,
    handler: createAsyncPage(() =>
      import(
        /*  webpackChunkName: "ApiDocumentationPage" */ '../pages/documentation/openApiSections'
      )
    ),
    props: {
      withV2Design: true,
      permissions: [AdminPermissions.ACCESS],
      menuReferer: RoutingEnum.API_DOCUMENTATION_ALL_ENDPOINTS,
      authRequired: true,
      icon: null,
      ariane: () => [
        {
          title: tr('documentation.main_title'),
          definition: null,
        },
        {
          title: tr('documentation.all_endpoints.title'),
          definition: RoutingEnum.API_DOCUMENTATION_ALL_ENDPOINTS,
        },
      ],
    },
  },

  // endpoints for a version and an access (for admins)
  {
    definition: RoutingEnum.API_DOCUMENTATION_ENDPOINTS,
    handler: createAsyncPage(() =>
      import(
        /*  webpackChunkName: "ApiDocumentationApiDetailPage" */ '../pages/documentation/openApiDetail'
      )
    ),
    props: {
      withV2Design: true,
      permissions: [AdminPermissions.ACCESS],
      menuReferer: RoutingEnum.API_DOCUMENTATION_ALL_ENDPOINTS,
      authRequired: true,
      icon: null,
      ariane: () => [
        {
          title: tr('documentation.main_title'),
          definition: null,
        },
        {
          title: tr('documentation.all_endpoints.title'),
          definition: RoutingEnum.API_DOCUMENTATION_ALL_ENDPOINTS,
        },
        {
          title: `/{access}`,
          definition: null,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.API_DOCUMENTATION_POS_ENDPOINTS,
    handler: createAsyncPage(() =>
      import(
        /*  webpackChunkName: "ApiDocumentationApiDetailPage" */ '../pages/documentation/openApiDetail'
      )
    ),
    props: {
      withV2Design: true,
      permissions: [DocumentationPermissions.POS_API],
      menuReferer: RoutingEnum.API_DOCUMENTATION_ALL_ENDPOINTS,
      authRequired: true,
      icon: null,
      ariane: () => [
        {
          title: tr('documentation.main_title'),
          definition: null,
        },
        {
          title: tr('documentation.pos.title'),
          definition: RoutingEnum.API_DOCUMENTATION_ALL_ENDPOINTS,
        },
        {
          title: tr('documentation.endpoints.ariane.label'),
          definition: null,
        },
      ],
    },
  },

  //
  // Audit
  //

  {
    definition: RoutingEnum.AUDIT_SESSION,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "AuditSessionPage" */ '../pages/audit/session')
    ),
    props: {
      withV2Design: true,
      permissions: [AuditPermissions.ACCESS],
      menuReferer: RoutingEnum.AUDIT_SESSION,
      authRequired: true,
      icon: null,
      ariane: () => [
        // TODO:
      ],
    },
  },

  //
  // ORDER
  //

  {
    definition: RoutingEnum.ORDER_DETAIL,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "OrderDetailPage" */ '../pages/order/detail/OrderDetailPage')
    ),
    props: {
      withV2Design: true,
      permissions: [],
      isForGroupUserOnly: false,
      menuReferer: null,
      authRequired: true,
      icon: null,
      ariane: () => [
        // TODO:
      ],
    },
  },

  //
  // PAYMENT
  //

  {
    definition: RoutingEnum.PAYMENT_DETAIL,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "PaymentDetailPage" */ '../pages/payment/detail')
    ),
    props: {
      withV2Design: true,
      permissions: [],
      isForGroupUserOnly: false,
      menuReferer: null,
      authRequired: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.payments'),
          definition: null,
        },
        {
          title: tr('ariane.payment'),
          definition: null,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.PAYMENT_LIST,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "PaymentListPage" */ '../pages/payment/list/PaymentListPage')
    ),
    props: {
      withV2Design: true,
      permissions: [FinancePermissions.ACCESS],
      isForGroupUserOnly: false,
      menuReferer: RoutingEnum.PAYMENT_LIST,
      authRequired: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.payments'),
          definition: null,
        },
        {
          title: `{restaurantName}`,
          definition: RoutingEnum.BO_SETTINGS_STORES_VIEW_DETAILS,
        },
      ],
    },
  },

  //
  // DEPOSIT
  //

  {
    definition: RoutingEnum.DEPOSIT_DETAIL,
    handler: createAsyncPage(() =>
      import(
        /*  webpackChunkName: "DepositDetailPage" */ '../pages/deposit/detail/DepositDetailPage'
      )
    ),
    props: {
      withV2Design: true,
      permissions: [],
      isForGroupUserOnly: false,
      menuReferer: null,
      authRequired: true,
      icon: null,
      ariane: () => [
        // TODO:
      ],
    },
  },

  //
  // Pro notifications settings
  //
  {
    definition: RoutingEnum.PRO_NOTIFICATIONS_SETTINGS_LIST,
    handler: createAsyncPage(() =>
      import(
        /*  webpackChunkName: "ProNotificationsSettingsListPage" */ '../pages/proNotificationsSettings/list'
      )
    ),
    props: {
      withV2Design: true,
      permissions: [ProEventNotificationsPermissions.ACCESS],
      menuReferer: RoutingEnum.PRO_NOTIFICATIONS_SETTINGS_LIST,
      authRequired: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.pro.notifications.list'),
          definition: RoutingEnum.PRO_NOTIFICATIONS_SETTINGS_LIST,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.PRO_NOTIFICATIONS_SETTINGS_EDIT,
    handler: createAsyncPage(() =>
      import(
        /*  webpackChunkName: "ProNotificationsSettingsEditPage" */ '../pages/proNotificationsSettings/edit'
      )
    ),
    props: {
      withV2Design: true,
      permissions: [ProEventNotificationsPermissions.CAN_EDIT],
      menuReferer: RoutingEnum.PRO_NOTIFICATIONS_SETTINGS_LIST,
      authRequired: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.pro.notifications.list'),
          definition: RoutingEnum.PRO_NOTIFICATIONS_SETTINGS_LIST,
        },
        {
          title: tr('ariane.pro.notifications.edit'),
          definition: null,
        },
      ],
    },
  },

  //
  // Order
  //
  {
    definition: RoutingEnum.ORDER_LIST,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "OrderListPage" */ '../pages/order/list/OrderListPage')
    ),
    props: {
      withV2Design: true,
      permissions: [OrderPermissions.ACCESS],
      isForGroupUserOnly: false,
      menuReferer: RoutingEnum.ORDER_ON_GOING,
      authRequired: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.stores'),
          definition: null,
        },
        {
          title: tr('ariane.stores.groups'),
          definition: RoutingEnum.BO_GROUPS_INFOS,
        },
        {
          title: tr('ariane.stores.stores'),
          definition: RoutingEnum.BO_GROUPS_TREE,
        },
        {
          title: `{restaurantName}`,
          definition: RoutingEnum.BO_SETTINGS_STORES_VIEW_DETAILS,
        },
        {
          title: tr('ariane.statistics.order'),
          definition: null,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.ORDER_ON_GOING,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "OrderOnGoingPage" */ '../pages/order/on-going/OrderOnGoingPage')
    ),
    props: {
      withV2Design: true,
      permissions: [OrderPermissions.ACCESS],
      isForGroupUserOnly: false,
      menuReferer: RoutingEnum.ORDER_ON_GOING,
      authRequired: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.stores'),
          definition: null,
        },
        {
          title: tr('ariane.stores.groups'),
          definition: RoutingEnum.BO_GROUPS_INFOS,
        },
        {
          title: tr('ariane.stores.stores'),
          definition: RoutingEnum.BO_GROUPS_TREE,
        },
        {
          title: `{restaurantName}`,
          definition: RoutingEnum.BO_SETTINGS_STORES_VIEW_DETAILS,
        },
        {
          title: tr('ariane.statistics.order'),
          definition: null,
        },
      ],
    },
  },

  //
  // API CONSUMER
  //

  {
    definition: RoutingEnum.API_CONSUMER_LIST,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "ApiConsumersListPage" */ '../pages/apiConsumer/list')
    ),
    props: {
      withV2Design: true,
      permissions: [ApiConsumerPermissions.ACCESS],
      menuReferer: RoutingEnum.API_CONSUMER_LIST,
      authRequired: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.api_consumer.list'),
          definition: RoutingEnum.API_CONSUMER_LIST,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.API_CONSUMER_CREATE,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "ApiConsumerCreationPage" */ '../pages/apiConsumer/creation')
    ),
    props: {
      withV2Design: true,
      permissions: [ApiConsumerPermissions.CAN_CREATE],
      menuReferer: RoutingEnum.API_CONSUMER_LIST,
      authRequired: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.api_consumer.list'),
          definition: RoutingEnum.API_CONSUMER_LIST,
        },
        {
          title: tr('ariane.api_consumer.create'),
          definition: RoutingEnum.API_CONSUMER_CREATE,
        },
      ],
    },
  },
  {
    definition: RoutingEnum.API_CONSUMER_DETAIL,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "ApiConsumerDetailPage" */ '../pages/apiConsumer/detail')
    ),
    props: {
      withV2Design: true,
      permissions: [ApiConsumerPermissions.ACCESS],
      menuReferer: RoutingEnum.API_CONSUMER_LIST,
      authRequired: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.api_consumer.list'),
          definition: RoutingEnum.API_CONSUMER_LIST,
        },
        {
          title: '{name}',
          definition: RoutingEnum.API_CONSUMER_DETAIL,
        },
      ],
    },
  },

  //
  // APP INSTANCE
  //

  {
    definition: RoutingEnum.ZDPMID_CONFIGURATION_LIST,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "AppInstancesListPage" */ '../pages/appInstance/list')
    ),
    props: {
      withV2Design: true,
      permissions: [AppInstancePermissions.ACCESS],
      menuReferer: RoutingEnum.ZDPMID_CONFIGURATION_LIST,
      authRequired: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.app_instance.list'),
          definition: RoutingEnum.ZDPMID_CONFIGURATION_LIST,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.ZDPMID_CONFIGURATION_CREATE,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "AppInstanceCreationPage" */ '../pages/appInstance/creation')
    ),
    props: {
      withV2Design: true,
      permissions: [AppInstancePermissions.CAN_CREATE],
      menuReferer: RoutingEnum.ZDPMID_CONFIGURATION_LIST,
      authRequired: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.app_instance.list'),
          definition: RoutingEnum.ZDPMID_CONFIGURATION_LIST,
        },
        {
          title: tr('ariane.app_instance.create'),
          definition: RoutingEnum.ZDPMID_CONFIGURATION_CREATE,
        },
      ],
    },
  },
  {
    definition: RoutingEnum.ZDPMID_CONFIGURATION_DETAIL,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "AppInstanceDetailPage" */ '../pages/appInstance/detail')
    ),
    props: {
      withV2Design: true,
      permissions: [AppInstancePermissions.ACCESS],
      menuReferer: RoutingEnum.ZDPMID_CONFIGURATION_LIST,
      authRequired: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.app_instance.list'),
          definition: RoutingEnum.ZDPMID_CONFIGURATION_LIST,
        },
        {
          title: '{name}',
          definition: RoutingEnum.ZDPMID_CONFIGURATION_DETAIL,
        },
      ],
    },
  },

  //
  // MEDIA
  //

  {
    definition: RoutingEnum.BO_MEDIA_LIBRARY,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "MediaLibraryPage" */ '../pages/media/library')
    ),
    props: {
      withV2Design: true,
      permissions: [MediaPermissions.ACCESS],
      isForGroupUserOnly: true,
      menuReferer: RoutingEnum.BO_MEDIA_LIBRARY,
      authRequired: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.media.library'),
          definition: null,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.BO_MEDIA_UPLOAD,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "MediaUploadPage" */ '../pages/media/upload')
    ),
    props: {
      withV2Design: true,
      permissions: [MediaPermissions.CREATE],
      isForGroupUserOnly: true,
      menuReferer: RoutingEnum.BO_MEDIA_UPLOAD,
      authRequired: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.media.upload'),
          definition: null,
        },
      ],
    },
  },

  //
  // Custom text
  //

  {
    definition: RoutingEnum.BO_CUSTOM_TEXTS_RESTAURANT,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "CustomTextsRestaurantPage" */ '../pages/customTexts/restaurant')
    ),
    props: {
      withV2Design: true,
      menuReferer: RoutingEnum.BO_GROUPS_INFOS,
      authRequired: true,
      permissions: [RestaurantsGroupPermissions.TEXT_SETTINGS], //TODO we should use a permission on restaurant, not restaurantsGroup
      ariane: () => [
        {
          title: tr('ariane.stores'),
          definition: null,
        },
        {
          title: '{restaurantsGroupName}',
          definition: RoutingEnum.BO_GROUPS_INFOS,
        },
        {
          title: tr('ariane.stores.group.custom_texts.email_list'),
          definition: RoutingEnum.BO_GROUPS_CUSTOM_TEXTS_EMAIL_LIST,
        },
        {
          title: tr('ariane.stores.group.custom_texts.restaurants'),
        },
      ],
    },
  },

  //
  // Finance
  //
  {
    definition: RoutingEnum.PAYOUTS_REPORTS,
    handler: createAsyncPage(() =>
      import(
        /*  webpackChunkName: "FinancePayoutsReportsPage" */ '../pages/finance/payoutsReport/reports'
      )
    ),
    props: {
      withV2Design: true,
      permissions: [FinancePermissions.ACCESS],
      menuReferer: RoutingEnum.PAYOUTS,
      authRequired: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.finance'),
          definition: null,
        },
        {
          title: tr('ariane.finance.payouts.reports'),
          definition: RoutingEnum.PAYOUTS_REPORTS,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.PAYOUT_REPORT,
    handler: createAsyncPage(() =>
      import(
        /*  webpackChunkName: "FinancePayoutsReportPage" */ '../pages/finance/payoutsReport/report'
      )
    ),
    props: {
      withV2Design: true,
      permissions: [FinancePermissions.ACCESS],
      menuReferer: RoutingEnum.PAYOUTS,
      authRequired: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.finance'),
          definition: null,
        },
        {
          title: tr('ariane.finance.payouts.reports'),
          definition: RoutingEnum.PAYOUTS_REPORTS,
        },
        {
          title: tr('ariane.finance.payouts.report'),
          definition: RoutingEnum.PAYOUT_REPORT,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.PAYOUTS_ALL_ON_GOING_REPORTS,
    handler: createAsyncPage(() =>
      import(
        /*  webpackChunkName: "FinancePayoutsAllOnGoingReportsPage" */ '../pages/finance/payoutsReport/reportsAllOnGoing'
      )
    ),
    props: {
      withV2Design: true,
      permissions: [AdminPermissions.ACCESS],
      menuReferer: RoutingEnum.PAYOUTS_ALL_ON_GOING_REPORTS,
      authRequired: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.finance'),
          definition: null,
        },
        {
          title: tr('ariane.finance.payouts.allOnGoingReports'),
          definition: RoutingEnum.PAYOUTS_ALL_ON_GOING_REPORTS,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.PAYOUTS,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "FinancePayoutsPage" */ '../pages/finance/payouts/list')
    ),
    props: {
      withV2Design: true,
      permissions: [FinancePermissions.ACCESS],
      menuReferer: RoutingEnum.PAYOUTS,
      authRequired: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.finance'),
          definition: null,
        },
        {
          title: tr('ariane.finance.payouts'),
          definition: RoutingEnum.PAYOUTS,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.PAYOUTS_FAILED,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "PayoutsFailedPage" */ '../pages/finance/payouts/failed')
    ),
    props: {
      withV2Design: true,
      permissions: [AdminPermissions.ACCESS],
      menuReferer: RoutingEnum.PAYOUTS,
      authRequired: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.finance'),
          definition: null,
        },
        {
          title: tr('ariane.finance.payouts'),
          definition: RoutingEnum.PAYOUTS,
        },
        {
          title: tr('ariane.finance.payouts.all_failed'),
          definition: RoutingEnum.PAYOUTS,
        },
      ],
    },
  },

  {
    definition: RoutingEnum.PAYOUT,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "PayoutPage" */ '../pages/finance/payouts/detail')
    ),
    props: {
      withV2Design: true,
      permissions: [FinancePermissions.ACCESS],
      menuReferer: RoutingEnum.PAYOUTS,
      authRequired: true,
      icon: null,
      ariane: () => [
        {
          title: tr('ariane.finance'),
          definition: null,
        },
        {
          title: tr('ariane.finance.payouts'),
          definition: RoutingEnum.PAYOUTS,
        },
      ],
    },
  },

  //
  // Pim v2
  //

  ...pimV2Routes({
    createAsyncPage,
  }),

  //
  // 404
  //

  {
    definition: RoutingEnum.PAGE_NOT_FOUND,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "PageNotFound" */ '../pages/pageNotFound')
    ),
    props: {
      permissions: [AdminPermissions.ACCESS],
      menuReferer: null,
      authRequired: false,
      icon: null,
      ariane: null,
    },
  },
]

export default routes.map(route => {
  // handle props.query to set global queries
  if (!route.props.query) {
    route.props.query = getQueries([])
  }

  // check config is ok.
  invariant(!isNil(route.definition?.name), `Invalid definition`)
  invariant(
    !isNil(route.props.authRequired),
    `${route.definition.name}: props authRequired not set`
  )
  invariant(!isNil(route.handler), `${route.definition.name}: handler is required`)
  invariant(!isNil(route.definition), `${route}: definition is required`)
  invariant(
    !isUndefined(route.props.menuReferer),
    `${route.definition.name}: menuReferer is required`
  )
  invariant(
    isNil(route.props.ariane) || isFunction(route.props.ariane),
    `${route.definition.name}: ariane must be a function or null`
  )

  return route
})
