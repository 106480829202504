import { createApiCallAction, createApiEnumAction } from 'pmt-modules/api/utils'
import RestaurantApi from 'pmt-modules/api/calls/RestaurantApi'

export const FetchRestaurantDocumentsStatus = createApiEnumAction('RESTAURANT::DOCUMENTS::STATUS')

export const fetchRestaurantKycDocumentsStatus = (restaurantId, documents) =>
  createApiCallAction(
    FetchRestaurantDocumentsStatus,
    RestaurantApi.getKycDocumentsStatus(restaurantId, documents),
    {
      restaurantId,
      documents,
    }
  )
