import { tr } from 'pmt-modules/i18n'
import keys from 'lodash/keys'

/**
 * Define the identification method
 * e.g. it is used to know from which the Deposit / Order was made.
 */
export const IdentificationMethod = {
  // Couldn't find the identification method. Used for legacy data too.
  NONE: 'NONE',

  // UserAccount token (ROLE_RESTAURANT request)
  TOKEN: 'TOKEN',

  // badge id authentication (ROLE_RESTAURANT request)
  BADGE: 'BADGE',

  // search of a user (ROLE_RESTAURANT request)
  SEARCH: 'SEARCH',

  // user application (mobile, web, etc) (ROLE_USER request)
  USER: 'USER',

  // By using the backoffice
  // e.g. deposit with IMPORTED_INITIAL_BALANCE created by user import
  BACKOFFICE: 'BACKOFFICE',
}

export const getIdentificationMethodLabel = method => {
  switch (method) {
    case IdentificationMethod.NONE:
      return tr('global.identification_method.none')
    case IdentificationMethod.TOKEN:
      return tr('global.identification_method.token')
    case IdentificationMethod.BADGE:
      return tr('global.identification_method.badge')
    case IdentificationMethod.SEARCH:
      return tr('global.identification_method.search')
    case IdentificationMethod.USER:
      return tr('global.identification_method.user')
    case IdentificationMethod.BACKOFFICE:
      return tr('global.identification_method.backoffice')
    default:
      return method
  }
}

export const getIdentificationMethodLabelAsArray = () =>
  keys(IdentificationMethod).map(value => ({
    label: getIdentificationMethodLabel(value),
    value,
  }))
