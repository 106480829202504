import React from 'react'
import PropTypes from 'prop-types'

import clsx from 'clsx'
import isNil from 'lodash/isNil'
import isString from 'lodash/isString'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { Security, AuditPermissions } from 'pmt-modules/bo'
import { tr } from 'pmt-modules/i18n'
import { withStyles } from 'pmt-ui/styles'
import WarningOutline from 'pmt-ui/svg-icons/action/warning-outline'
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined'
import InfoOutline from 'pmt-ui/svg-icons/action/info-outline'
import ErrorOutline from 'pmt-ui/svg-icons/action/error-outline'
import MessageBlockSdk from 'pmt-ui/MessageBlock'
import { TypographyCustom } from 'pmt-ui/Typography'
import { lighten } from 'pmt-utils/theme/colorManipulator'
import Button from 'app/components/Button'
import JsonDebugModal from 'app/components/JsonDebugModal'
import InfoTable from 'app/components/InfoTable'

const generateStyle = color => ({
  color,
  border: `1px solid ${color}`,
  padding: '4px 2px',
  borderRadius: '4px',
  backgroundColor: lighten(color, 0.8),
})

const styles = theme => ({
  errorRoot: {
    ...generateStyle(theme.pmt.status.error),
  },
  warningRoot: {
    ...generateStyle(theme.pmt.status.warning),
  },
  infoRoot: {
    ...generateStyle(theme.pmt.status.info),
  },
  successRoot: {
    ...generateStyle(theme.pmt.status.valid),
  },
})

const Type = {
  INFO: 'TYPE::INFO',
  ERROR: 'TYPE::ERROR',
  WARNING: 'TYPE::WARNING',
  SUCCESS: 'TYPE::SUCCESS',
}

/**
 * error will be displayed with the color defined in theme: theme.pmt.status.error
 * be sure to set it properly in your config theme file
 */
const MessageBlock = ({ classes, type, text, ...otherProps }) => {
  switch (type) {
    case Type.ERROR:
      return (
        <MessageBlockSdk
          classes={{
            root: classes.errorRoot,
          }}
          icon={<ErrorOutline />}
          text={<TypographyCustom type="144">{text}</TypographyCustom>}
          {...otherProps}
        />
      )

    case Type.WARNING:
      return (
        <MessageBlockSdk
          classes={{
            root: classes.warningRoot,
          }}
          icon={<WarningOutline />}
          text={<TypographyCustom type="144">{text}</TypographyCustom>}
          {...otherProps}
        />
      )

    case Type.SUCCESS:
      return (
        <MessageBlockSdk
          classes={{
            root: classes.successRoot,
          }}
          icon={<CheckOutlinedIcon />}
          text={<TypographyCustom type="144">{text}</TypographyCustom>}
          {...otherProps}
        />
      )

    case Type.INFO:
    default:
      return (
        <MessageBlockSdk
          classes={{
            root: classes.infoRoot,
          }}
          icon={<InfoOutline />}
          text={<TypographyCustom type="144">{text}</TypographyCustom>}
          {...otherProps}
        />
      )
  }
}

MessageBlock.defaultProps = {
  type: Type.INFO,
}

MessageBlock.propTypes = {
  // type of message block to display
  type: PropTypes.string,

  // text to display
  text: PropTypes.any,

  variant: PropTypes.string,
}

const MessageBlockComponent = withStyles(styles)(MessageBlock)

MessageBlockComponent.Type = Type

const errorStyles = theme => ({
  errorRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  errorRootWithAudit: {
    paddingTop: theme.spacing(2),
  },
  errorAuditRoot: {
    marginTop: theme.spacing(2),
  },
  errorInfoTable: {
    marginTop: theme.spacing(2),
  },
})

MessageBlockComponent.Error = withStyles(errorStyles)(({ error, text, classes, ...otherProps }) => {
  if (!error && !text) {
    return null
  }

  error = error || text
  let finalMessage
  if (!isNil(error)) {
    if (!isString(error)) {
      finalMessage = isEmpty(get(error, 'localizedMessage'))
        ? get(error, 'error.message', null)
        : error.localizedMessage === error.message
        ? error.message
        : // handle legacy where we give the i18n key directly.
          tr(error.localizedMessage)
    } else {
      finalMessage = error || tr('global.unknown_error')
    }
  }

  const hasAuditPermissions = Security.assert(AuditPermissions.ACCESS)

  const displayErrorDetails = hasAuditPermissions && !isString(error)

  const finalText = (
    <div className={clsx(classes.errorRoot, { [classes.errorRootWithAudit]: displayErrorDetails })}>
      <span>{finalMessage}</span>

      {displayErrorDetails && (
        <div className={classes.errorAuditRoot}>
          <JsonDebugModal object={error}>
            {({ onOpen }) => (
              <Button onClick={onOpen} size="small">
                See json data
              </Button>
            )}
          </JsonDebugModal>
          <InfoTable
            className={classes.errorInfoTable}
            datas={[
              {
                label: 'Session id',
                value: error?.response?.headers?.get('p-session-id'),
              },
              !isEmpty(error?.response?.headers?.get('session-url')) && {
                label: 'Session url',
                value: (
                  <a href={error.response.headers.get('session-url')}>
                    {error.response.headers.get('session-url')}
                  </a>
                ),
              },
            ]}
          />
        </div>
      )}
    </div>
  )

  return (
    <MessageBlockComponent
      text={finalText}
      type={Type.ERROR}
      classes={{
        errorRoot: classes.errorRoot,
        warningRoot: classes.warningRoot,
        infoRoot: classes.infoRoot,
        successRoot: classes.successRoot,
      }}
      {...otherProps}
    />
  )
})

MessageBlockComponent.Info = ({ info, text, ...otherProps }) =>
  info || text ? (
    <MessageBlockComponent text={info || text} type={Type.INFO} {...otherProps} />
  ) : null

MessageBlockComponent.Warning = ({ warning, text, ...otherProps }) =>
  warning || text ? (
    <MessageBlockComponent text={warning || text} type={Type.WARNING} {...otherProps} />
  ) : null

MessageBlockComponent.Success = ({ success, text, ...otherProps }) =>
  success || text ? (
    <MessageBlockComponent text={success || text} type={Type.SUCCESS} {...otherProps} />
  ) : null

export default MessageBlockComponent
