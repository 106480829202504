export const EntityHistoryType = {
  PRO_ROLE: 'PRO_ROLE',
  PRO_ACCOUNT: 'PRO_ACCOUNT',
  SUBSIDY_RULE: 'SUBSIDY_RULE',
  BILLING_ENTITY: 'BILLING_ENTITY',
  PRICING_RULE: 'PRICING_RULE',
  ADMISSION_FEE_RULE: 'ADMISSION_FEE_RULE',
  COMPANY: 'COMPANY',
  RESTAURANTS_GROUP: 'RESTAURANTS_GROUP',
  RESTAURANT: 'RESTAURANT',
  RESTAURANT_LEGAL: 'RESTAURANT_LEGAL',
  ORDER_SETTINGS: 'ORDER_SETTINGS',
  CATERING_SETTINGS: 'CATERING_SETTINGS',
  RESTAURANT_MENU: 'RESTAURANT_MENU',
  CART_MODIFIER: 'CART_MODIFIER',
  API_CONSUMER: 'API_CONSUMER',
  API_CONSUMER_SETTINGS: 'API_CONSUMER_SETTINGS',
}

export const getEntityHistoryOptions = () => [
  { value: EntityHistoryType.API_CONSUMER, label: 'API_CONSUMER' },
  { value: EntityHistoryType.API_CONSUMER_SETTINGS, label: 'API_CONSUMER_SETTINGS' },
  { value: EntityHistoryType.ADMISSION_FEE_RULE, label: 'ADMISSION_FEE_RULE' },
  { value: EntityHistoryType.BILLING_ENTITY, label: 'BILLING_ENTITY' },
  { value: EntityHistoryType.CART_MODIFIER, label: 'CART_MODIFIER' },
  { value: EntityHistoryType.CATERING_SETTINGS, label: 'CATERING_SETTINGS' },
  { value: EntityHistoryType.COMPANY, label: 'COMPANY' },
  { value: EntityHistoryType.ORDER_SETTINGS, label: 'ORDER_SETTINGS' },
  { value: EntityHistoryType.PRICING_RULE, label: 'PRICING_RULE' },
  { value: EntityHistoryType.PRO_ACCOUNT, label: 'PRO_ACCOUNT' },
  { value: EntityHistoryType.PRO_ROLE, label: 'PRO_ROLE' },
  { value: EntityHistoryType.RESTAURANTS_GROUP, label: 'RESTAURANTS_GROUP' },
  { value: EntityHistoryType.RESTAURANT, label: 'RESTAURANT' },
  { value: EntityHistoryType.RESTAURANT_LEGAL, label: 'RESTAURANT_LEGAL' },
  { value: EntityHistoryType.RESTAURANT_MENU, label: 'RESTAURANT_MENU' },
  { value: EntityHistoryType.SUBSIDY_RULE, label: 'SUBSIDY_RULE' },
]
