const CrmPermissions = {
  ACCESS: 'crm',

  READ_USERS: 'crm.users',

  EXPORT: 'crm.users.canExport',

  CREATE_USER: 'crm.users.user.canCreate',

  EDIT_USER: 'crm.users.user.canEdit',

  DELETE_USER: 'crm.users.user.canDelete',
}

export default CrmPermissions
