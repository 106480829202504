import React from 'react'
import FlagIcon from 'pmt-utils/country/flag'

const getCountryForLocale = locale => {
  if (!locale) {
    return ''
  }
  if (locale.length === 5) {
    // handle 'fr-FR' format
    const country = locale.substring(3, 5)
    return country.toLowerCase()
  }

  // trick for locales like 'en', 'fr'
  locale = locale.substring(0, 2)
  switch (locale.toLowerCase()) {
    case 'sq':
      return 'al'
    case 'ar':
      return 'eg'
    case 'be':
      return 'by'
    case 'ca':
      return 'es'
    case 'zh':
      return 'cn'
    case 'cs':
      return 'cz'
    case 'da':
      return 'dk'
    case 'en':
      return 'us'
    case 'et':
      return 'ee'
    case 'el':
      return 'gr'
    case 'iw':
      return 'il'
    case 'hi':
      return 'in'
    case 'in':
      return 'id'
    case 'ga':
      return 'ie'
    case 'ja':
      return 'jp'
    case 'ko':
      return 'kr'
    case 'ms':
      return 'my'
    case 'pt':
      return 'br'
    case 'sl':
      return 'si'
    case 'sv':
      return 'se'
    case 'uk':
      return 'ua'
    case 'vi':
      return 'vn'

    default:
      return locale
  }
}

const LocaleFlag = ({ locale, ...otherProps }) => (
  <FlagIcon country={getCountryForLocale(locale)} {...otherProps} />
)

export default LocaleFlag
