import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { fetchUserList } from '../../actions'
import { getUserList, isFetchingUserList, makeGetUserListPagination } from '../../selectors'

/**
 * @specs N/A
 *
 * A HOC that give fetch the restaurant's news and pass it to the children
 *
 * Requirements:
 * - restaurantId
 *
 * see `withRestaurantNews`
 */
class UserListContainer extends React.Component {
  componentWillMount() {
    // This has been commented to update constantly the user list
    // because we can't update the user datas directly in that list when you submit your data changes from the user form update
    // if (isNull(this.props.userList) || size(this.props.userList) === 0) {
    // }
    this.loadUsersList(this.props)
  }

  componentWillReceiveProps(nextProps) {
    const props = this.props
    if (nextProps.rGroupId !== props.rGroupId) {
      this.loadUsersList(nextProps)
    }
  }

  loadUsersList(props) {
    if (!this.props.isFetchingUserList) {
      props.fetchUserList(props.rGroupId)
    }
  }

  loadMore = () => {
    if (!this.props.isFetchingUserList) {
      this.props.fetchUserList(this.props.rGroupId, this.props.pagination.cursors.after)
    }
  }

  render() {
    const {
      isFetchingUserList,
      rGroupId,
      userList,
      pagination,
      children,
      ...otherProps
    } = this.props

    return children({
      isFetchingUserList,
      rGroupId,
      userList,
      pagination,
      onLoadMore: () => {
        this.loadMore()
      },
      ...otherProps,
    })
  }
}

UserListContainer.propTypes = {
  fetchUserList: PropTypes.func.isRequired,
  isFetchingUserList: PropTypes.bool,
  rGroupId: PropTypes.number,
  userList: PropTypes.array,

  // children MUST be a function
  children: PropTypes.func.isRequired,
}

const mapStateToProps = (state, props) => {
  const getUserListPagination = makeGetUserListPagination()

  return {
    userList: getUserList(state),
    isFetchingUserList: isFetchingUserList(state),
    pagination: getUserListPagination(state, props),
  }
}

export default connect(mapStateToProps, {
  fetchUserList,
})(UserListContainer)
