import { createSimpleSelector } from 'pmt-modules/redux'
import { formatCustomTextsContainer } from './format'

//
//
//

const customTextsSelectors = createSimpleSelector(
  state => state.entities.template.customTextsContainer,
  formatCustomTextsContainer
)

export const isFetchingCustomTextsContainer = customTextsSelectors.isFetching
export const getCustomTextsContainer = customTextsSelectors.getData
export const getCustomTextsContainerError = customTextsSelectors.getError

//
//
//

const restaurantsGroupEmailTemplateDataSelectors = createSimpleSelector(
  state => state.entities.template.restaurantsGroupEmailTemplateData
)

export const isFetchingRestaurantsGroupEmailTemplateData =
  restaurantsGroupEmailTemplateDataSelectors.isFetching

export const getDataRestaurantsGroupEmailTemplateData =
  restaurantsGroupEmailTemplateDataSelectors.getData

export const getErrorRestaurantsGroupEmailTemplateData =
  restaurantsGroupEmailTemplateDataSelectors.getError

//
//
//

const createCustomTextsContainerSelectors = createSimpleSelector(
  state => state.entities.template.createCustomTextsContainer
)

export const isFetchingCustomTextsContainerCreation = createCustomTextsContainerSelectors.isFetching
export const getCreatedCustomTextsContainer = createCustomTextsContainerSelectors.getData
export const getCreatedCustomTextsContainerError = createCustomTextsContainerSelectors.getError
