import { createApiCallAction, createApiEnumAction } from 'pmt-modules/api/utils'
import { createAction } from 'pmt-modules/redux'

import FirebaseApi from 'pmt-modules/api/calls/FirebaseApi'

/**
 *
 */
export const PMTFirebaseAuth = createApiEnumAction('FIREBASE::PMT::AUTH')

export const postPmtFirebaseAuth = () =>
  createApiCallAction(PMTFirebaseAuth, FirebaseApi.auth(), {})

export const authUserOnFirebase = () => createAction('FIREBASE::PMT::AUTH_USER_ON_FIREBASE')
authUserOnFirebase.TYPE = 'FIREBASE::PMT::AUTH_USER_ON_FIREBASE'
