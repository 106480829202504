const OrderPermissions = {

  ACCESS: 'order',

  ORDER_SETTINGS_READ: 'order.settings',

  ORDER_SETTINGS_EDIT: 'order.settings.canEditSettings',
}

export default OrderPermissions
