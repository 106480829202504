import createForm from 'pmt-modules/form/createForm'

import FormType from 'pmt-modules/form/FormType'

// in DEV environment, prefill form with the following data
const devData = {}

const defaultData = {
  entityType: null,
  entityId: null,
}

const validationRules = {}

export default createForm(FormType.HISTORY_SELECT, validationRules, defaultData, devData, {})
