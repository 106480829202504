const PayoutsErrors = {
  LIST: {
    default: 'global.payouts.api_error.list.default',
  },
  REPORT_SEARCH: {
    default: 'global.payouts.api_error.report_search.default',
  },
  REPORT: {
    default: 'global.payouts.api_error.report.default',
  },
  REPORTS: {
    default: 'global.payouts.api_error.reports.default',
  },
}

export default PayoutsErrors
