import { getFromStorage, removeFromStorage, saveToStorage } from '../../storage'

import { StorageItem } from '../constants'

export const saveBasicToken = token => {
  saveToStorage(StorageItem.BASIC_TOKEN, token)
}

export const getDefaultBasicToken = () => getFromStorage(StorageItem.BASIC_TOKEN, null)

export const getAuthorization = () => {
  return getDefaultBasicToken()
}

export const removeBasicToken = () => removeFromStorage(StorageItem.BASIC_TOKEN)
