import ApiEndpoints from '../config/ApiEndpoints'

const ProDomainApi = {
  getMeDomainsList: () => ({
    endpoint: ApiEndpoints.PRO_ME_DOMAINS,
    type: 'GET',
    query: {
      loadProRole: true,
      loadRestaurantsGroup: true,
    },
  }),

  getMeDomain: (domainId, loadRestaurantsGroupArchived) => ({
    endpoint: ApiEndpoints.PRO_ME_DOMAIN,
    type: 'GET',
    params: {
      domainId,
    },
    query: {
      loadProRole: true,
      loadRestaurantsGroup: true,
      loadRestaurantsGroupChildren: true,
      loadRestaurantsGroupArchived: loadRestaurantsGroupArchived,
    },
  }),

  getDomainsList: (restaurantsGroupId, cursor, limit) => ({
    endpoint: ApiEndpoints.PRO_DOMAINS,
    type: 'GET',
    query: {
      restaurantsGroupId,
      loadProRole: true,
      loadProAccount: true,
      loadRestaurantsGroup: false, // we use the RestaurantsGroupContainer instead
      cursor: cursor || '',
      limit,
    },
  }),

  getRestaurantsGroups: restaurantsGroupId => ({
    endpoint: ApiEndpoints.RESTAURANTS_GROUP,
    type: 'GET',
    params: {
      restaurantsGroupId: restaurantsGroupId,
    },
    query: {
      loadChildren: true,
    },
  }),

  getDomain: (domainId, options) => ({
    endpoint: ApiEndpoints.PRO_DOMAIN,
    type: 'GET',
    params: {
      domainId,
    },
    query: {
      loadProAccount: options.withProAccount,
      loadProRole: options.withProRole,
    },
  }),
}

export default ProDomainApi
