import { createSimpleReducer } from '../redux'

import { ExportRestaurantsGroupAction, ExportRestaurantsGroupActions } from './actions'

export * from './selectors'
export * from './actions'

export const exportRestaurantsGroupReducer = createSimpleReducer(
  ExportRestaurantsGroupAction,
  (state, action) => {
    switch (action.type) {
      case ExportRestaurantsGroupActions.RESET:
        return state.merge({
          data: null,
          isFetching: false,
          error: null,
        })

      default:
        return state
    }
  }
)
