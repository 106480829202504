import ApiEndpoints from '../config/ApiEndpoints'
import MediaErrors from '../errors/MediaErrors'

const MediaApi = {
  getMedia: (rGroupId, mediaId) => ({
    type: 'GET',
    params: {
      rGroupId,
      mediaId,
    },
    endpoint: ApiEndpoints.MEDIA_DETAIL,
    errorHandler: MediaErrors.GET,
  }),

  getMediaList: (rGroupId, query) => ({
    type: 'GET',
    params: {
      rGroupId,
    },
    query,
    endpoint: ApiEndpoints.MEDIA,
    errorHandler: MediaErrors.LIST,
  }),

  post: (body, { rGroupId }) => ({
    type: 'POST',
    endpoint: ApiEndpoints.MEDIA,
    params: {
      rGroupId,
    },
    body,
    errorHandler: MediaErrors.POST,
  }),

  openMedia: (rGroupId, mediaId) => ({
    type: 'GET',
    endpoint: ApiEndpoints.MEDIA_OPEN,
    params: { rGroupId, mediaId },
  }),
}

export default MediaApi
