import { tr } from 'pmt-modules/i18n'
import React from 'react'

import isEmpty from 'lodash/isEmpty'
import { withStyles } from 'pmt-ui/styles'
import HibpPasswordRangeContainer from 'pmt-modules/hibp/container/HibpPasswordRangeContainer'
import MessageBlock from 'app/components/MessageBlock'

const styles = theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
})

const HipbPasswordView = ({
  password,
  pwnedText,
  displayIsSecureMessage = true,
  displayCheckSecure = true,
  minPasswordLength = 6, // TODO: change to 8?
  classes,
}) => (
  <HibpPasswordRangeContainer password={password}>
    {({ isPwned, count, isFetching }) => {
      if (isFetching) {
        if (displayCheckSecure) {
          return (
            <MessageBlock.Info className={classes.root} text={tr('security.hibp.pwned.checking')} />
          )
        }
        return null
      }

      if (isPwned) {
        return <MessageBlock.Warning className={classes.root} text={pwnedText} />
      }

      const isTooSmall = isEmpty(password) || password.length < minPasswordLength
      if (displayIsSecureMessage && !isTooSmall) {
        return (
          <MessageBlock.Info className={classes.root} text={tr('security.hibp.pwned.is_secure')} />
        )
      }
      return null
    }}
  </HibpPasswordRangeContainer>
)

export default withStyles(styles)(HipbPasswordView)
