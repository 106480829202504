import Immutable from 'immutable'
import isEmpty from 'lodash/isEmpty'
import { mergeArray } from 'pmt-utils/array'
import createReducer from '../createReducer'

const DEFAULT = Immutable.fromJS({
  // Note: we normalize the data
  // contains the whole list
  // we save the objets on it and reference it on the 'ids' array, to avoid duplicate objects on
  // different objects.
  list: [],

  // will contain:
  // key: objectId (see objectIdRetriever)
  // value:
  //  ids:
  //  error:
  //  pagination (if options.pagination === true)
  objects: {},
})

const handleUpdateForDomain = modifierFunc => (objectIdRetriever, options) => (state, action) => {
  const objectId = objectIdRetriever(action)

  const groupData = state.getIn(['objects', objectId])

  let groupState = groupData
  if (!groupData) {
    groupState = Immutable.fromJS({
      error: null,
      ids: null,
      isFetching: false,
    })
  }

  groupState = modifierFunc(groupState, action, options)

  return state.setIn(['objects', objectId], groupState)
}

const onRequest = handleUpdateForDomain((state, action, options) => {
  if (options.pagination) {
    // on pagination we don't want to erase ids
    return state.merge({
      error: null,
      isFetching: true,
    })
  }

  return state.merge({
    ids: null,
    error: null,
    isFetching: true,
  })
})

const onSuccess = (objectIdRetriever, options) => (state, action) => {
  // for now, when an api call is made, we receive the RestaurantsGroup and its children.
  // We want an array
  let retrievedList

  const currrentData = state.getIn(['objects', objectIdRetriever(action)]) || null

  const currentList = state.get('list').toJS()
  let ids = currrentData.get('ids') || []
  if (ids.toJS) {
    ids = ids.toJS()
  }

  if (options.pagination) {
    retrievedList = action.response.data
  } else {
    retrievedList = action.response
  }

  if (options.dataModifier) {
    // TODO: invariant dataModifier
    retrievedList = options.dataModifier(retrievedList)
  }

  if (options.pagination) {
    if (isEmpty(action.response.paging.cursors.before)) {
      // first page
      ids = retrievedList.map(obj => obj.id)
    } else {
      // merge ids array
      ids = mergeArray(retrievedList.map(obj => obj.id), ids || [], id => id, true)
    }
  } else {
    ids = retrievedList.map(obj => obj.id)
  }

  const updatedList = mergeArray(currentList, retrievedList, item => item.id)

  state = state.set('list', Immutable.fromJS(updatedList))

  let paging = null
  if (options.pagination) {
    paging = action.response.paging
  }

  return handleUpdateForDomain((objectState, action) => {
    return objectState.merge({
      // stock only the ids, while the data is merged in root 'list'
      ids,
      error: null,
      isFetching: false,
      paging,
    })
  })(objectIdRetriever, options)(state, action)
}

const onFailure = handleUpdateForDomain((state, action) => {
  return state.merge({
    ids: null,
    error: action.error,
    isFetching: false,
  })
})

/**
 *
 * @param {*} apiAction
 * @param {*} objectIdRetriever
 * @param {*} options
 *  - dataModifier
 */
const createSharedListReducer = (apiAction, objectIdRetriever, options = {}) =>
  createReducer(DEFAULT, {
    [apiAction.REQUEST]: onRequest(objectIdRetriever, options),
    [apiAction.SUCCESS]: onSuccess(objectIdRetriever, options),
    [apiAction.FAILURE]: onFailure(objectIdRetriever, options),
  })

export default createSharedListReducer
