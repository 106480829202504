/**
 * see https://chmln.github.io/flatpickr/localization/
 * @param flatpickr.init.prototype.l10n
 */
const localizeFlatpickr = locale => {
  // en is 'default'
  locale = locale === 'en' ? 'default' : locale === 'debug' ? 'default' : locale

  const flatpickrLang = require(`flatpickr/dist/l10n/${locale}.js`).default

  const flatpickr = require('flatpickr')
  flatpickr.localize(flatpickrLang[locale])
}

export default localizeFlatpickr
