import {
  createApiEnumAction,
} from '../api/utils'

import {
  createAction,
} from '../redux'

export const updateUserAction = createApiEnumAction('PRO_UPDATE_USER::UPDATE_USER')


export const updateUserFormActions = {
  RUN_FORM: 'UPDATE_USER_FORM::RUN_FORM',
}

export const runUpdateUserForm = (updateUserFormPart) => createAction(
  updateUserFormActions.RUN_FORM,
  {
    updateUserFormPart,
  }
)
