import React from 'react'
import PropTypes from 'prop-types'
import isNull from 'lodash/isNull'
import { connect } from 'react-redux'

import { makeGetDomain, makeIsFetchingDomain } from '../../selectors'
import { fetchDomain } from '../../actions'

/**
 * @specs N/A
 *
 * A HOC that give fetch the domainId and pass it to the children
 *
 * Requirements:
 * - domainId
 *
 */
class DomainContainer extends React.Component {
  constructor(props) {
    super(props)

    this.loadDomain(this.props, this.props.forceReload)
  }

  componentWillReceiveProps(nextProps) {
    const props = this.props
    if (
      nextProps.domainId !== props.domainId &&
      ((!props.domain || props.domain.id) && nextProps.domainId && !props.isFetchingDomain)
    ) {
      this.loadDomain(nextProps, false)
    }
  }

  loadDomain(props, forceReload = false) {
    if (!props.isFetchingDomain && (isNull(this.props.domain) || forceReload)) {
      props.fetchDomain(props.domainId, {
        withProAccount: true,
        withProRole: true, // to retrieve name
      })
    }
  }

  render() {
    const { domain, isFetchingDomain } = this.props

    return this.props.children({
      domain,
      isFetchingDomain,
    })
  }
}

DomainContainer.defaultProps = {
  domain: null,
}

DomainContainer.propTypes = {
  domain: PropTypes.object,
}

const makeMapStateToProps = () => {
  const getDomain = makeGetDomain()
  const isFetchingDomain = makeIsFetchingDomain()

  const mapStateToProps = (state, props) => {
    const selectorProps = {
      domainId: props.domainId,
    }

    return {
      domain: getDomain(state, selectorProps),
      isFetchingDomain: isFetchingDomain(state, selectorProps),
    }
  }
  return mapStateToProps
}

export default connect(
  makeMapStateToProps,
  {
    fetchDomain,
  }
)(DomainContainer)
