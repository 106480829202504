import {
  createApiCallAction,
  createApiEnumAction,
} from '../api/utils'

import UserApi from '../api/calls/UserApi'

export const GetUserCardAction = createApiEnumAction('USER::CARD::GET')

export const fetchUserCard = (rGroupId, userId) => createApiCallAction(
  GetUserCardAction,
  UserApi.getUser(rGroupId, userId),
  {
    rGroupId,
    userId,
  }
)
