const RestaurantsGroupPermissions = {
  ACCESS: 'proAccount.restaurantsGroup',
  CAN_EDIT: 'proAccount.restaurantsGroup.canEdit',
  CAN_DELETE: 'proAccount.restaurantsGroup.canDelete',
  ADD_RESTAURANTS: 'proAccount.restaurantsGroup.canAddRestaurants',
  CAN_EDIT_HIERARCHY: 'proAccount.restaurantsGroup.canEditHierarchy',
  CREATE_RESTAURANTS_GROUP: 'proAccount.restaurantsGroup.canCreateRestaurantsGroup',
  CREATE_TYPE_USER: 'proAccount.restaurantsGroup.canCreateTypeUser',
  ORDER_GENERAL_STATS: 'proAccount.restaurantsGroup.stats.order.generalStats',
  DEPOSIT_GENERAL_STATS: 'proAccount.restaurantsGroup.stats.deposit.generalStats',
  TEXT_SETTINGS: 'proAccount.restaurantsGroup.textSettings',
  ACCESS_USER_SETTINGS: 'proAccount.restaurantsGroup.userSettings',
  CAN_EDIT_USER_SETTINGS: 'proAccount.restaurantsGroup.userSettings.canEdit',
  ACCESS_FINANCE_SETTINGS: 'proAccount.restaurantsGroup.financeSettings',
  CAN_EDIT_FINANCE_SETTINGS: 'proAccount.restaurantsGroup.financeSettings.canEdit',
}

export default RestaurantsGroupPermissions
