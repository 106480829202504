import { combineReducers } from 'redux'

import { createSimpleReducer } from '../redux'

import { GetOrderDetailAction, PutOrderDetailAction } from './actions'

export const orderDetailReducer = combineReducers({
  get: createSimpleReducer(GetOrderDetailAction),
  put: createSimpleReducer(PutOrderDetailAction),
})
