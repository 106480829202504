import { createApiCallAction, createApiEnumAction } from 'pmt-modules/api/utils'

import PayoutsApi from 'pmt-modules/api/calls/PayoutsApi'

//
//
//

export const FetchPayoutAction = createApiEnumAction('FetchPayoutAction')

export const fetchPayout = payoutId =>
  createApiCallAction(FetchPayoutAction, PayoutsApi.getPayout(payoutId), {
    payoutId,
  })
