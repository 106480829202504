import { createFormatter, createListFormatter } from 'pmt-utils/format'
import { formatDate } from 'pmt-utils/date'

const formatDomains = proAccount => {
  // avoid having null domains
  proAccount.domains = proAccount.domains || []

  proAccount.allDomainsDisabled = proAccount.domains.every(domain => !domain.activated)
  return proAccount
}

const formatActivity = proAccount => {
  proAccount.lastActivityDateFormatted = formatDate(proAccount.lastActivityDate)
  return proAccount
}

export const formatProAccount = createFormatter(formatDomains, formatActivity)

export const formatProAccountsList = createListFormatter(formatProAccount)
