import hasPermissions from './utils/hasPermissions'

export * from './permissions'
export * from './actions'

class SecurityManager {
  constructor() {
    this.currentProRole = null
  }

  registerProRole(proRole) {
    this.currentProRole = proRole
  }

  // permissions can either be an array of permissions, or a permission string.
  assert(permissions = null, proRole = this.currentProRole) {
    return hasPermissions(permissions, proRole)
  }
}

export const Security = new SecurityManager()
